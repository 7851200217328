import { $t } from '@/i18n';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import HighlightOptionsEnum from '@/enums/HighlightOptionsEnum';
import ScrollingModsEnum from '@/enums/ScrollingModsEnum';

function getReadingThemes() {
  return [
    {
      name: 'default-theme-template',
      title: $t('ReadModeSettingsService.White')
    },
    {
      name: 'sepia-theme-template',
      title: $t('ReadModeSettingsService.Sepia')
    },
    {
      name: 'night-theme-template',
      title: $t('ReadModeSettingsService.Night')
    }
  ];
}

function getScrollingOptions() {
  return [
    {
      label: $t('ReadModeSettingsMenu.eachPageMode.label'),
      value: ScrollingModsEnum.EACH_PAGE
    },
    {
      label: $t('ReadModeSettingsMenu.eachLineMode.label'),
      value: ScrollingModsEnum.EACH_LINE
    }
  ];
}

function _createHighlightOptions() {
  const optionsTypes = [
    HighlightOptionsEnum.DARKEN,
    HighlightOptionsEnum.HIGHLIGHT,
    HighlightOptionsEnum.UNDERLINE
  ];
  return optionsTypes.map(type => {
    return _createOption(type);
  });
}

function _createOption(type) {
  switch (type) {
    case HighlightOptionsEnum.DARKEN:
      return new HighlightOption(
        $t('ReadModeSettingsMenu.DarkenMode.label'),
        HighlightOptionsEnum.DARKEN,
        'darken-mode'
      );
    case HighlightOptionsEnum.HIGHLIGHT:
      return new HighlightOption(
        $t('ReadModeSettingsMenu.HighlightMode.label'),
        HighlightOptionsEnum.HIGHLIGHT,
        'highlight-mode'
      );
    case HighlightOptionsEnum.UNDERLINE:
      return new HighlightOption(
        $t('ReadModeSettingsMenu.UnderlineMode.label'),
        HighlightOptionsEnum.UNDERLINE,
        'underline-mode'
      );
    default:
      break;
  }
}

class HighlightOption {
  constructor(label, value, customClass) {
    this.label = label;
    this.value = value;
    this.customClass = customClass;
  }
}
class ReadingSettings {
  constructor(settings) {
    this.readingFonts = settings.readingFonts || [];
    this.readingThemes = getReadingThemes();
    this.fontSize = settings.fontSize;
    this.font = settings.font;
    this.lineSpacing = settings.lineSpacing;
    this.themeName = settings.themeName;
    this.defaultAudioSpeed = settings.defaultAudioSpeed;
    this.expandedNotesMode = settings.expandedNotesMode;
    this.expandedFootnotesMode = settings.expandedFootnotesMode;
    this.autoPlayNotes = settings.autoPlayNotes;
    this.googleTranslate = settings.googleTranslate;
    this.dictionaryMode = settings.dictionaryMode;
    this.wikiMode = settings.wikiMode;
    this.dictionaryAutoOpen = settings.dictionaryAutoOpen;
    this.underlineMode = settings.underlineMode;
    this.audioSpeedStep =
      this.defaultAudioSpeed > 100
        ? AppConstantsUtil.LONG_AUDIO_SPEED_STEP
        : AppConstantsUtil.SHORT_AUDIO_SPEED_STEP;
    this.readingPositionTracking = settings.readingPositionTracking;
    const audioSpeedInEachSide =
      this.audioSpeedStep * AppConstantsUtil.AUDIO_STEPS_IN_EACH_SIDE;
    this.minAudioSpeed = this.defaultAudioSpeed - audioSpeedInEachSide;
    this.maxAudioSpeed = this.defaultAudioSpeed + audioSpeedInEachSide;

    if (settings.audioSpeed > this.maxAudioSpeed) {
      this.audioSpeed = this.maxAudioSpeed;
    } else if (settings.audioSpeed < this.minAudioSpeed) {
      this.audioSpeed = this.minAudioSpeed;
    } else {
      this.audioSpeed = settings.audioSpeed;
    }

    this.highlightOptions = _createHighlightOptions();
    this.highlightMode =
      settings.highlightMode || HighlightOptionsEnum.HIGHLIGHT;
    this.scrollingModes = getScrollingOptions();
    this.scrollingMode =
      settings.scrollingMode || AppConstantsUtil.DEFAULT_SCROLLING_MOD;
    this.isSettingsExist = true;
  }
}

class ReadingSettingsBuilder {
  setReadingFonts(readingFonts) {
    this.readingFonts = readingFonts;
    return this;
  }

  setFont(font) {
    this.font = font;
    return this;
  }

  setFontSize(fontSize) {
    this.fontSize = fontSize;
    return this;
  }

  setLineSpacing(lineSpacing) {
    this.lineSpacing = lineSpacing;
    return this;
  }

  setThemeName(themeName) {
    this.themeName = themeName;
    return this;
  }

  setDefaultAudioSpeed(defaultAudioSpeed) {
    this.defaultAudioSpeed = defaultAudioSpeed;
    return this;
  }

  setAudioSpeed(audioSpeed) {
    this.audioSpeed = audioSpeed;
    return this;
  }

  setExpandedNotesMode(expandedNotesMode) {
    this.expandedNotesMode = expandedNotesMode;
    return this;
  }

  setExpandedFootnotesMode(expandedFootnotesMode) {
    this.expandedFootnotesMode = expandedFootnotesMode;
    return this;
  }

  setAutoPlayNotes(autoPlayNotes) {
    this.autoPlayNotes = autoPlayNotes;
    return this;
  }

  setGoogleTranslate(val) {
    this.googleTranslate = val;
    return this;
  }

  setDictionaryMode(val) {
    this.dictionaryMode = val;
    return this;
  }

  setWikiMode(val) {
    this.wikiMode = val;
    return this;
  }

  setDictionaryAutoOpen(dictionaryAutoOpen) {
    this.dictionaryAutoOpen = dictionaryAutoOpen;
    return this;
  }

  setHighlightMode(highlightMode) {
    this.highlightMode = highlightMode;
    return this;
  }

  setScrollingMode(scrollingMode) {
    this.scrollingMode = scrollingMode;
    return this;
  }

  setReadingPositionTracking(readingPositionTracking) {
    this.readingPositionTracking = readingPositionTracking;
    return this;
  }

  build() {
    return new ReadingSettings(this);
  }
}

function createReadingSettingsFactory() {
  return new ReadingSettingsBuilder();
}

function isSettingsInstance(readingSettings) {
  return readingSettings instanceof ReadingSettings;
}

export default {
  createReadingSettingsFactory,
  isSettingsInstance
};
