import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('PublicationStore.js');

import AppStateEnum from '@/enums/AppStateEnum';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import ScrollStrategyEnum from '@/enums/ScrollStrategyEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';

import BookStore from '@/store/BookStore';
import CompilationsStore from '@/store/CompilationsStore';

import AssetsManager from '@/services/AssetsManager/AssetsManager';
import Locator from '@shared/publication/locator.mjs';
import LayoutUtils from '@shared/publication/dom-utils/layout-utils.mjs';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import Utils from '@/services/utils/Utils';
import PresentPublicationFactory from '@/classes/factories/PresentPublicationFactory';
import CompilationFactory from '@/classes/factories/CompilationFactory';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import CompilationsService from '@/services/CompilationsService';
import SessionStorageService from '@/services/SessionStorageService';
import AnnotationDomUtils from '@shared/publication/annotation/AnnotationDomUtils';

class UpdateResponse {
  constructor(updated, isContentDownloaded) {
    this.updated = updated;
    this.contentDownloaded = isContentDownloaded;
  }

  isUpdated() {
    return this.updated;
  }

  isContentDownloaded() {
    return this.contentDownloaded;
  }
}

const initState = () => ({
  scrollConstants: createScrollConstants(AppConstantsUtil.ON_HTML_SCROLL),
  storeName: BookStore.name,
  animateScroll: null,
  paraPlayButtonPressed: false,
  fabButtonPressed: false,
  metaBlockHeigth: 0,
  isFABDisabled: true,
  isShownTextSelection: false,
  scrollEvent: null,
  isAudioStartPlaying: false,
  skipCurrentLocatorChange: false,
  transitionOver: false,
  rotateScroll: false,
  wordsStableOffsets: {},
  isApplyAllParams: false,
  isScrollStabled: false,
  fractionalScrollRemainder: 0,
  scrubberPositionLineByLineMode: null
});

function _findStartLocatorIndexInAlignment(alignment, locator) {
  return _findLocatorIndexInAlignment('startLocator', alignment, locator);
}

function _findEndLocatorIndexInAlignment(alignment, locator) {
  return _findLocatorIndexInAlignment('endLocator', alignment, locator);
}

function _findLocatorIndexInAlignment(targetLocatorName, alignment, locator) {
  for (const [index, alignedLocator] of alignment.entries()) {
    const item = Locator.deserialize(alignedLocator);
    if (item[targetLocatorName].compareTo(locator) >= 0) {
      return index;
    }
  }
  return alignment.length - 1;
}

function _toggleUserSelectStyle(paraId) {
  const className = 'user-select-all';
  const paraElement = document.getElementById(paraId);
  if (!paraElement) {
    return;
  }
  if (paraElement.classList.contains(className)) {
    paraElement.classList.remove(className);
  } else {
    paraElement.classList.add(className);
  }
}

class ScrollConstants {
  constructor(
    targetElement,
    scrollClassName,
    strategy,
    needReposition,
    scrollAreaClassName
  ) {
    this.strategy = strategy;
    this.targetElementSelector = targetElement;
    this.scrollClassName = scrollClassName;
    this.needReposition = needReposition;
    this.scrollAreaClassName = scrollAreaClassName;
    this.isViewMode = false;
  }

  toJSON() {
    return JSON.stringify({
      strategy: this.strategy,
      targetElementSelector: this.targetElementSelector,
      scrollClassName: this.scrollClassName,
      needReposition: this.needReposition,
      scrollAreaClassName: this.scrollAreaClassName,
      isViewMode: this.isViewMode
    });
  }

  getScrollStrategy() {
    return this.strategy;
  }

  getScrollElement() {
    return window.document.querySelector(`.${this.scrollClassName}`);
  }

  getScrollElSelector() {
    return this.targetElementSelector;
  }

  getScrollClassName() {
    return this.scrollClassName;
  }

  getNeedReposition() {
    return this.needReposition;
  }

  getScrollAreaClassName() {
    return this.scrollAreaClassName;
  }

  setIsViewMode(val) {
    this.isViewMode = val;
  }

  isPointInViewPort(x, y) {
    const viewPortParams = this.calcInViewPortParams(x, y);

    const inViewPortY =
      viewPortParams.inViewPortBottom && viewPortParams.inViewPortTop;
    const inViewPortX =
      viewPortParams.inViewPortLeft && viewPortParams.inViewPortRight;

    return inViewPortY && inViewPortX;
  }
}

class ElementScrollConstants extends ScrollConstants {
  constructor(targetElement, scrollClassName, strategy, isViewMode) {
    const needReposition = true;
    super(
      targetElement,
      scrollClassName,
      strategy,
      needReposition,
      scrollClassName
    );
    this.isViewMode = isViewMode;
  }

  calcInViewPortParams(x, y) {
    const viewPort = this.getViewportRect();
    const scrollContainer = this.getScrollElement();
    const scrollTop = scrollContainer.scrollTop;

    const topBorder = this.isViewMode ? scrollTop + viewPort.top : scrollTop;
    const bottomBorder = this.isViewMode
      ? viewPort.height + scrollTop + viewPort.top
      : viewPort.height + scrollTop;

    return {
      inViewPortBottom: y < bottomBorder,
      inViewPortTop: y > topBorder,
      inViewPortLeft: x >= viewPort.x,
      inViewPortRight: x <= viewPort.x + viewPort.width
    };
  }

  getElementYPos(element) {
    return element.getBoundingClientRect().y;
  }

  getElementTopOffset(elementY) {
    const scrollContainer = this.getScrollElement();
    if (!scrollContainer) {
      return 0;
    }
    const scrollTop = scrollContainer.scrollTop;
    const listY = scrollContainer.getBoundingClientRect().y;
    return scrollTop + (elementY - listY);
  }

  getViewportRect() {
    const scrollContainer = this.getScrollElement();
    if (!scrollContainer) {
      return null;
    }
    const rect = scrollContainer.getBoundingClientRect();
    return rect;
  }
  getThirdScreenLocator(state) {
    const { x, y, height } = state.scrollConstants.getViewportRect();
    const scrollContainer = state.scrollConstants.getScrollElement();
    return LayoutUtils.getThirdScreenLocator(scrollContainer, x, y + height);
  }

  correctionForAbsPosition() {
    return 0;
  }

  getScrollListenerElement() {
    return this.getScrollElement();
  }
}
class HtmlScrollConstants extends ScrollConstants {
  constructor(targetElement, scrollClassName, strategy, scrollAreaClassName) {
    const needReposition = false;
    super(
      targetElement,
      scrollClassName,
      strategy,
      needReposition,
      scrollAreaClassName
    );
  }

  calcInViewPortParams(x, y) {
    const viewPort = this.getViewportRect();
    const scrollContainer = this.getScrollElement();
    const scrollTop = scrollContainer.scrollTop;
    const safeAreaTop = Utils.getSafeAreaInsetTop();
    const topBorder = this.isViewMode
      ? scrollTop
      : scrollTop + SelectionConstantUtils.TOOLBAR_HEIGHT + safeAreaTop;
    const bottomBorder = this.isViewMode
      ? viewPort.height + scrollTop
      : viewPort.height +
        scrollTop -
        SelectionConstantUtils.PROGRESS_TOOLBAR_HEIGHT;

    return {
      inViewPortBottom: y < bottomBorder,
      inViewPortTop: y > topBorder,
      inViewPortLeft: x >= viewPort.x,
      inViewPortRight: x <= viewPort.x + viewPort.width
    };
  }

  getElementYPos(element) {
    return (
      element.getBoundingClientRect().y - SelectionConstantUtils.TOOLBAR_HEIGHT
    );
  }

  getElementTopOffset(elementY) {
    const scrollContainer = this.getScrollElement();
    if (!scrollContainer) {
      return 0;
    }
    const listY = scrollContainer.getBoundingClientRect().y;
    return Math.round(elementY - listY);
  }
  getViewportRect() {
    const scrollContainer = this.getScrollElement();
    if (!scrollContainer) {
      return null;
    }
    const rect = scrollContainer.getBoundingClientRect();
    const safeAreaTop = Utils.getSafeAreaInsetTop();
    const top =
      Math.abs(rect.y) -
      scrollContainer.scrollTop +
      SelectionConstantUtils.TOOLBAR_HEIGHT +
      safeAreaTop;

    return new DOMRect(0, top, rect.width, window.innerHeight);
  }
  getThirdScreenLocator(state) {
    const { x, bottom } = state.scrollConstants.getViewportRect();
    const scrollContainer = state.scrollConstants.getScrollElement();
    return LayoutUtils.getThirdScreenLocator(scrollContainer, x, bottom);
  }
  correctionForAbsPosition() {
    const scrollContainer = this.getScrollElement();
    return scrollContainer.scrollTop;
  }

  getScrollListenerElement() {
    return window;
  }
}

function createScrollConstants(strategy, isViewMode) {
  switch (strategy) {
    case ScrollStrategyEnum.ON_ELEMENT_SCROLL:
      return new ElementScrollConstants(
        AppConstantsUtil.PUBLICATION_VIRTUAL_LIST_SCROLL_ELEMENT,
        AppConstantsUtil.PUBLICATION_VIRTUAL_LIST_SCROLL_CLASS,
        ScrollStrategyEnum.ON_ELEMENT_SCROLL,
        isViewMode
      );
    case ScrollStrategyEnum.ON_HTML_SCROLL: {
      return new HtmlScrollConstants(
        AppConstantsUtil.PUBLICATION_SCROLL_ELEMENT,
        AppConstantsUtil.PUBLICATION_SCROLL_CLASS,
        ScrollStrategyEnum.ON_HTML_SCROLL,
        AppConstantsUtil.PUBLICATION_VIRTUAL_LIST_SCROLL_CLASS
      );
    }
    default:
      break;
  }
}
// getters
const storeGetters = {
  geTotalContentItemCount: (state, getters) => {
    const allItems = getters.getParagraphItems;
    return allItems.length;
  },
  getParaWordsStableOffsets: state => (publicationId, paraId) => {
    const publicationOffsets = state.wordsStableOffsets[publicationId];
    return publicationOffsets ? publicationOffsets[paraId] : null;
  },
  isApplyAllParams(state) {
    return state.isApplyAllParams;
  },
  isTransitionOver(state) {
    return state.transitionOver;
  },
  getAlignmentPartFromLocator: (state, getters, rootState, rootGetters) => ({
    locator,
    bookId
  }) => {
    const paraId = locator.startLocator.prefixedParagraphId;
    const fallbackAlignment = [];
    const fullAlignment = rootGetters['BookStore/getParagraphAlignment'](
      bookId,
      paraId
    );
    if (!fullAlignment || !fullAlignment.length) {
      return fallbackAlignment;
    }
    const { startIndex, endIndex } = getters['findAlignmentIndexesByLocator'](
      fullAlignment,
      locator
    );
    const end = endIndex + 1;
    return [
      fullAlignment[0].slice(startIndex, end),
      CompilationsService.sliceAndRecalculateLocators(
        fullAlignment[1],
        startIndex,
        end
      )
    ];
  },
  isRotateScroll(state) {
    return state.rotateScroll;
  },
  getIsAudioStartPlaying(state) {
    return state.isAudioStartPlaying;
  },
  getSkipCurrentLocatorChange(state) {
    return state.skipCurrentLocatorChange;
  },
  getScrollEvent(state) {
    return state.scrollEvent;
  },
  getMetaBlockHeigth(state) {
    return state.metaBlockHeigth;
  },
  getParaPlayButtonPressed(state) {
    return state.paraPlayButtonPressed;
  },
  getFabButtonPressed(state) {
    return state.fabButtonPressed;
  },
  getIsFABDisabled(state) {
    return state.isFABDisabled;
  },
  getIsShownTextSelection(state) {
    return state.isShownTextSelection;
  },
  getAnimateScroll(state) {
    return state.animateScroll;
  },
  checkPointInViewPort: state => (x, y) => {
    return state.scrollConstants.isPointInViewPort(x, y);
  },
  getInViewPortParams: state => (x, y) => {
    return state.scrollConstants.calcInViewPortParams(x, y);
  },
  getScrollElement(state) {
    return state.scrollConstants.getScrollElement();
  },
  getScrollListenerElement(state) {
    return state.scrollConstants.getScrollListenerElement();
  },
  getScrollElementSelector(state) {
    return state.scrollConstants.getScrollElSelector();
  },
  getScrollClassName(state) {
    return state.scrollConstants.getScrollClassName();
  },
  getElementYPos: state => element => {
    return state.scrollConstants.getElementYPos(element);
  },
  getElementTopOffset: state => elementY => {
    return state.scrollConstants.getElementTopOffset(elementY);
  },
  isNeedReposition(state) {
    return state.scrollConstants.getNeedReposition();
  },
  getViewportRect: state => () => {
    return state.scrollConstants.getViewportRect();
  },
  getScrollTopAbsolutePos: state => () => {
    return state.scrollConstants.correctionForAbsPosition();
  },
  getThirdScreenLocator: state => () => {
    return state.scrollConstants.getThirdScreenLocator(state);
  },
  isHtmlStrategy(state) {
    const scrollStrategy = state.scrollConstants.getScrollStrategy();
    return scrollStrategy === ScrollStrategyEnum.ON_HTML_SCROLL;
  },
  getScrollContainer() {
    return window.document.querySelector(
      `.${AppConstantsUtil.SCROLL_BLOCK_CLASS}`
    );
  },
  getScrollAreaSelector(state) {
    return `.${state.scrollConstants.getScrollAreaClassName()}`;
  },
  isCompilationOpen(state) {
    return state.storeName === CompilationsStore.name;
  },
  getStoreName(state) {
    return state.storeName;
  },
  isCompactData: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/isCompactData`];
  },
  getBookIdByParaId: (state, getters, rootState, rootGetters) => paraId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getBookIdByParaId`](paraId);
  },
  getAudioParaId: (state, getters, rootState, rootGetters) => paraId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getAudioParaId`](paraId);
  },
  isCollectionOpen(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/isCollectionOpen`];
  },
  getPauseMsByParaId: (state, getters, rootState, rootGetters) => paraId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getPauseMsByParaId`](paraId);
  },
  hasPauseMap(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/hasPauseMap`];
  },
  getMiddleParaIdNum: (state, getters, rootState, rootGetters) => (
    startParaId,
    endParaId
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getMiddleParaIdNum`](
      startParaId,
      endParaId
    );
  },
  getBookItemPosition: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getBookItemPosition`](publicationId);
  },

  getStoreNameByPublicationType: () => type => {
    switch (type) {
      case PublicationsTypes.BOOK:
        return BookStore.name;
      case PublicationsTypes.COMPILATION:
        return CompilationsStore.name;
      default:
        logger.error(`Get store name for type ${type} failed`);
        break;
    }
  },
  getExcludeInitProps: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getExcludeInitProps`];
  },
  isElementVoiced: (state, getters, rootState, rootGetters) => (
    publicationId,
    paraId
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/isElementVoiced`](publicationId, paraId);
  },
  getNextVoicedParaId: (state, getters, rootState, rootGetters) => (
    publicationId,
    paraId
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getNextVoicedParaId`](
      publicationId,
      paraId
    );
  },
  getNextParaId: (state, getters, rootState, rootGetters) => (
    publicationId,
    paraId
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getNextParaId`](publicationId, paraId);
  },
  getParaIdByIndex: (state, getters, rootState, rootGetters) => (
    publicationId,
    paraIndex
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getParaIdByIndex`](
      publicationId,
      paraIndex
    );
  },
  getAudioLinks: (state, getters, rootState, rootGetters) => () => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getAudioLinks`]();
  },
  getParaIdFromStartToEnd: (state, getters, rootState, rootGetters) => (
    startParaId,
    endParaId
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getParaIdFromStartToEnd`](
      startParaId,
      endParaId
    );
  },
  getFirstAudioParagraph: (state, getters, rootState, rootGetters) => () => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getFirstAudioParagraph`]();
  },
  getFirstContentParagraph: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getFirstContentParagraph`](publicationId);
  },
  getLastContentParagraph: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getLastContentParagraph`](publicationId);
  },
  getParagraphsSummary: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getParagraphsSummary`](publicationId);
  },
  getTotalParagraphItems: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getTotalParagraphItems`](publicationId);
  },
  getParagraphItems(state, getters, rootState, rootGetters) {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getParagraphItems`];
  },
  createCoverPath: (state, getters, rootState, rootGetters) => (
    publicationId,
    size
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/createCoverPath`](publicationId, size);
  },
  getMeta: (state, getters, rootState, rootGetters) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getMeta`](publicationId);
  },
  getPublicationStyleClassName: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const brand = rootGetters['ContextStore/brand'];

    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getPublicationStyleClassName`](
      publicationId,
      brand
    );
  },
  getMetaView: (state, getters, rootState, rootGetters) => publicationId => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getMetaView`](publicationId);
  },
  getParagraphAlignment: (state, getters, rootState, rootGetters) => (
    publicationId,
    paraId
  ) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getParagraphAlignment`](
      publicationId,
      paraId
    );
  },
  getTimeByLocator: (state, getters) => (publicationId, startLocator) => {
    const paraAlignment = getters.getParagraphAlignment(
      publicationId,
      startLocator.prefixedParagraphId
    );
    if (paraAlignment) {
      const index = getters.findStartLocatorIndexInAlignment(
        paraAlignment[1],
        startLocator
      );
      return paraAlignment[0][index][0];
    } else {
      return null;
    }
  },
  getEndTimeByLocator: (state, getters) => (publicationId, locator) => {
    const paraAlignment = getters.getParagraphAlignment(
      publicationId,
      locator.prefixedParagraphId
    );
    if (paraAlignment) {
      const index = getters.findEndLocatorIndexInAlignment(
        paraAlignment[1],
        locator
      );
      return paraAlignment[0][index][1];
    } else {
      return null;
    }
  },
  findStartLocatorIndexInAlignment: () => (alignment, startLocator) => {
    return _findStartLocatorIndexInAlignment(alignment, startLocator);
  },
  findEndLocatorIndexInAlignment: () => (alignment, startLocator) => {
    return _findEndLocatorIndexInAlignment(alignment, startLocator);
  },
  getStartParaTime: (state, getters) => (publicationId, paraId) => {
    const paraAlignment = getters.getParagraphAlignment(publicationId, paraId);
    const firstAudioOffset = paraAlignment[0];
    return firstAudioOffset[0][0];
  },
  getLocatorByTime: (state, getters) => (publicationId, paraId, time) => {
    const alignment = getters.getParagraphAlignment(publicationId, paraId);
    if (!alignment) {
      return null;
    }

    let index = 0;
    while (index < alignment[0].length) {
      let range = alignment[0][index];
      if (time >= range[0] && time < range[1]) {
        return alignment[1][index];
      }
      if (time < range[0]) {
        const returnIndex = alignment[1][index - 1] === undefined ? 0 : index;
        return alignment[1][returnIndex];
      }
      index++;
    }
    return alignment[1][alignment[1].length - 1];
  },
  isLastAudioLocator: (state, getters, rootState, rootGetters) => locator => {
    const defaultResp = false;
    if (!locator || !locator.endLocator) {
      return defaultResp;
    }
    const currentEndLocator = locator.endLocator;

    const storeName = getters.getStoreName;
    const alignment = rootGetters[`${storeName}/getAlignment`];
    const paraAlignment = alignment[currentEndLocator.prefixedParagraphId];
    if (!paraAlignment) {
      return defaultResp;
    }
    const locators = paraAlignment[1];
    const lastLocator = Locator.deserialize(locators[locators.length - 1]);
    return lastLocator.endLocator.equals(currentEndLocator);
  },
  hasAlignmentMap: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    const alignmentIndexMap = rootGetters[`${storeName}/getAlignmentIndexMap`];
    return Object.keys(alignmentIndexMap || {}).length !== 0;
  },
  prevParaAlignment: (state, getters, rootState, rootGetters) => (
    publicationId,
    locator
  ) => {
    const paraId = locator.prefixedParagraphId;
    const storeName = getters.getStoreName;
    const alignment = rootGetters[`${storeName}/getAlignment`];
    const alParaIds = Object.keys(alignment).sort((paraA, paraB) => {
      return MarkerUtils.getParaNum(paraA) - MarkerUtils.getParaNum(paraB);
    });
    const paraIndex = alParaIds.findIndex(alParaId => {
      return alParaId === paraId;
    });
    if (paraIndex === -1) {
      return null;
    }
    const prevPara = alParaIds[paraIndex - 1];
    return getters.getParagraphAlignment(publicationId, prevPara);
  },
  getPreviousLocator: (state, getters) => (publicationId, locator) => {
    if (!locator) {
      return null;
    }
    const paraId = locator.prefixedParagraphId;
    const alignment = getters.getParagraphAlignment(publicationId, paraId);
    if (!alignment) {
      return null;
    }
    const locators = alignment[1];
    const index = locators.findIndex(serializedLocator => {
      const rangeLocator = Locator.deserialize(serializedLocator);
      return rangeLocator.startLocator.equals(locator);
    });

    if (index !== 0) {
      return locators[index - 1];
    }

    const prevParaAlignment = getters.prevParaAlignment(publicationId, locator);
    if (!prevParaAlignment) {
      return null;
    }
    const prevLocators = prevParaAlignment[1];
    return prevLocators[prevLocators.length - 1];
  },
  getClosestAlignmentLocator: (state, getters, rootState, rootGetters) => (
    publicationId,
    locator
  ) => {
    const storeName = getters.getStoreName;
    const paraId = locator.prefixedParagraphId;
    const paragraphAlignment = rootGetters[
      `${storeName}/getParagraphAlignment`
    ](publicationId, paraId);
    if (!paragraphAlignment) {
      return null;
    }
    const result = paragraphAlignment[1].find(serializedLocator => {
      const rangeLocator = Locator.deserialize(serializedLocator);
      return (
        rangeLocator.startLocator.precedes(locator) ||
        rangeLocator.startLocator.equals(locator)
      );
    });
    return result || paragraphAlignment[1][0];
  },
  isAudioPublication: (state, getters) => publicationId => {
    const publicationMeta = getters['getMeta'](publicationId);
    return Boolean(publicationMeta?.audio);
  },
  isLimitedAccess: (
    state,
    getters,
    rootState,
    rootGetters
  ) => publicationId => {
    const isGuest = rootGetters['UserStore/isGuestUser'];

    const isServicesReady = rootGetters['ContextStore/isServicesReady'];
    if (!rootGetters['ContextStore/isPurchaseEnabled'] || !isServicesReady) {
      return false;
    }

    const isViewMode = rootGetters['ContextStore/isViewMode'];
    const isContentAvailable = rootGetters['UserStore/isContentAvailable'];
    const isPublicationInSet = rootGetters['LibraryStore/isPublicationInSet'](
      publicationId
    );
    const publication = rootGetters['LibraryStore/getPublicationById'](
      publicationId
    );
    const isAudioBook = publication && publication.audio;
    return (
      !isViewMode &&
      isAudioBook &&
      (isGuest || (isPublicationInSet && !isContentAvailable))
    );
  },
  isParagraphDisabled: (state, getters, rootState, rootGetters) => paraId => {
    const isPurchaseAvailable = rootGetters['ContextStore/isPurchaseAvailable'];
    const isLimitedGuestAccess =
      rootGetters['ContextStore/isLimitedGuestAccess'];
    const isContentAvailable = rootGetters['UserStore/isContentAvailable'];
    const storeName = getters.getStoreName;
    return (
      (isLimitedGuestAccess || (isPurchaseAvailable && !isContentAvailable)) &&
      rootGetters[`${storeName}/isParagraphDisabled`](paraId)
    );
  },
  getSelectedTextMetaBySelectionText: (
    state,
    getters,
    rootState,
    rootGetters
  ) => (locator, bookId, selectionText) => {
    try {
      const userId = rootGetters['UserStore/getUserId'];
      const publicationMeta = getters['getMeta'](bookId);
      const clientUrl = rootGetters['ContextStore/getClientReaderUrl'];
      const identifier =
        rootGetters['LibraryStore/getPublicationSlugById'](bookId) || bookId;
      const brand = rootGetters['ContextStore/brand'];

      const params = {
        clientUrl,
        identifier,
        userId,
        locator,
        brand
      };
      const selectedTextLink = Utils.buildPublicationLink(params);

      const paraId = locator.startLocator.prefixedParagraphId;
      const paragraphNumber = rootGetters['BookStore/getParaNumByParaId'](
        bookId,
        paraId
      );
      const selectedTextMeta = PresentPublicationFactory.getSelectedTextMetaBuilder();
      selectedTextMeta
        .setText(selectionText)
        .setTextLink(selectedTextLink)
        .setParagraphNumber(paragraphNumber)
        .setPublicationAuthor(publicationMeta.author)
        .setPublicationTitle(publicationMeta.name)
        .build();
      return selectedTextMeta;
    } catch (error) {
      logger.error(`getSelectedTextMeta failed with ${error}`);
      return {};
    }
  },
  getSelectedTextMeta: (state, getters, rootState, rootGetters) => (
    locator,
    bookId
  ) => {
    const selectedText = rootGetters['TextCopyStore/getSelectedText']({
      locator,
      bookId
    });
    return getters.getSelectedTextMetaBySelectionText(
      locator,
      bookId,
      selectedText
    );
  },
  isContentReady: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/isContentReady`];
  },
  isAudioReady: (state, getters, rootState, rootGetters) => () => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/isAudioReady`]();
  },
  getBookLanguage: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getBookLanguage`];
  },
  findAlignmentIndexesByLocator: (state, getters) => (
    paragraphAlignment,
    rangeLocator
  ) => {
    const startIndex = getters['findStartLocatorIndexInAlignment'](
      paragraphAlignment[1],
      rangeLocator.startLocator
    );
    const endIndex = getters['findEndLocatorIndexInAlignment'](
      paragraphAlignment[1],
      rangeLocator.endLocator
    );
    return { startIndex, endIndex };
  },
  getBookInfoView: (state, getters, rootState, rootGetters) => () => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/getBookInfoView`]();
  },
  getPreviousRoute(state, getters, rootState, rootGetters) {
    const previousNotBookRoute =
      rootGetters['ContextStore/getPreviousNotBookRoute'];
    const previousRoute = previousNotBookRoute?.name
      ? previousNotBookRoute
      : SessionStorageService.get('previousRoute');
    if (previousRoute?.name === AppStateEnum.DEVELOP_LIBRARY_SET) {
      return { name: AppStateEnum.LIBRARY_SET };
    }

    if (previousRoute?.name) {
      return previousRoute;
    }

    const isCompilation = getters.isCompilationOpen;
    if (isCompilation) {
      return { name: AppStateEnum.MANAGE_COMPILATION };
    }

    const currentPublicationId =
      rootGetters['OpenParameterStore/getPublicationId'];
    let lang;
    if (currentPublicationId) {
      lang = getters.getMeta(currentPublicationId)?.language;
    }

    if (lang) {
      return {
        name: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
        params: { pathMatch: lang }
      };
    }

    return { name: AppStateEnum.MANAGE_PUBLICATION };
  },

  isPublicationHasRtl: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/isPublicationHasRtl`];
  },
  contentLength: (state, getters, rootState, rootGetters) => {
    const storeName = getters.getStoreName;
    return rootGetters[`${storeName}/contentLength`];
  },
  getLocatorFromStartToParaEnd: (state, getters, rootState, rootGetters) => (
    locator,
    publicationId
  ) => {
    const paraId = locator.startLocator.prefixedParagraphId;
    const paraLength = rootGetters['BookStore/getParaTextByParaId'](
      publicationId,
      paraId
    )?.length;
    let startOffset = locator.startLocator.logicalCharOffset;
    let start = new Locator.InTextLocator(paraId, startOffset);
    let end = new Locator.InTextLocator(paraId, paraLength);
    return new Locator.InTextRangeLocator(start, end);
  },
  getFractionalScrollRemainder: state => {
    return state.fractionalScrollRemainder;
  },
  getScrubberPositionLineByLineMode: state => {
    return state.scrubberPositionLineByLineMode;
  }
};
const actions = {
  transformToClientData({ dispatch, state }) {
    const { strategy, isViewMode } = state.scrollConstants;
    dispatch('setScrollStrategy', { strategy, isViewMode });
  },
  initBookAssets: async function initBookAssets(_, payload) {
    const { bookId, forced = false } = payload;
    return AssetsManager.initBookAssets(bookId, forced);
  },
  setBookPreviewScrollStrategy({ dispatch }) {
    dispatch('setScrollStrategy', {
      strategy: ScrollStrategyEnum.ON_ELEMENT_SCROLL,
      isViewMode: true
    });
  },
  setPublicationScrollStategy({ dispatch, rootGetters }) {
    const isIos12_4 = rootGetters['ContextStore/isIos12_4'];
    const strategy = isIos12_4
      ? ScrollStrategyEnum.ON_ELEMENT_SCROLL
      : ScrollStrategyEnum.ON_HTML_SCROLL;
    logger.info(`Set scroll strategy: ${strategy}`);
    dispatch('setScrollStrategy', {
      strategy
    });
  },
  setScrollStrategy({ commit }, payload) {
    const { strategy, isViewMode } = payload;
    const constants = createScrollConstants(strategy, isViewMode);
    commit('setScrollConstants', constants);
  },
  compactStore({ dispatch, getters }, compactOptions) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/compactStore`, compactOptions, {
      root: true
    });
  },
  destroyPublicationState(
    { dispatch, getters, commit },
    excludeInitProps = []
  ) {
    const storeName = getters.getStoreName;
    const exclude = ['scrollConstants'];
    commit('destroyState', [...excludeInitProps, ...exclude]);
    return dispatch(`${storeName}/destroyPublicationState`, excludeInitProps, {
      root: true
    });
  },
  setStoreNameByPubType({ commit }, publicationType) {
    let storeName = '';
    switch (publicationType) {
      case PublicationsTypes.BOOK:
        storeName = BookStore.name;
        break;
      case PublicationsTypes.COMPILATION:
        storeName = CompilationsStore.name;
        break;
      default:
        logger.error(`Get unsupported publicationType:${publicationType}`);
        break;
    }
    commit('setStoreName', storeName);
  },
  initPublicationAudio({ dispatch, getters }, initData) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/initPublicationAudio`, initData, {
      root: true
    });
  },
  initPublicationContent({ dispatch, getters }, initData) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/initPublicationContent`, initData, {
      root: true
    });
  },
  setPublicationContentDisabled({ dispatch, getters }, payload) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/setPublicationContentDisabled`, payload, {
      root: true
    });
  },
  setPublicationContentEnabled({ dispatch, getters }) {
    const storeName = getters.getStoreName;
    dispatch(`${storeName}/setPublicationContentEnabled`, null, {
      root: true
    });
  },
  getBookAlignmentByContentRequest({ dispatch, getters }, contentRequest) {
    const storeName = getters.getStoreName;
    return dispatch(
      `${storeName}/getBookAlignmentByContentRequest`,
      contentRequest,
      {
        root: true
      }
    );
  },
  async fillMeta({ dispatch, getters }, { publicationId }) {
    const meta = getters.getMeta(publicationId);
    if (
      Object.keys(meta || {}).length === 0 ||
      publicationId !== meta?.fileName ||
      (process.client && meta?.isServer)
    ) {
      await dispatch('readMeta', publicationId);
    }
  },
  async readMeta({ dispatch, getters }, publicationId) {
    const storeName = getters.getStoreName;
    try {
      const meta = await dispatch(`${storeName}/readMeta`, publicationId, {
        root: true
      });
      return meta;
    } catch (err) {
      err.type = CustomErrorEnum.NETWORK_ERROR;
      throw err;
    }
  },
  getContentByContentRequest({ dispatch, getters }, contentRequest) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/getContentByContentRequest`, contentRequest, {
      root: true
    });
  },
  async autoUpdateBook({ dispatch }, publicationId) {
    const trackingItemView = await AssetsManager.getPublicationTrackingItemView(
      publicationId
    );
    const needAutoUpdate =
      trackingItemView.outdatedData &&
      trackingItemView.isContentDownloaded &&
      !trackingItemView.isAudioDownloaded;

    try {
      await dispatch('actualizeBookMeta', { publicationId, needAutoUpdate });
    } catch (error) {
      logger.error(`Error while actualizing book meta: ${error}`);
    }

    if (!needAutoUpdate) {
      const updated = false;
      return Promise.resolve(
        new UpdateResponse(updated, trackingItemView.isContentDownloaded)
      );
    }
    const excludeAssetNames = await AssetsManager.getAssetNameAudioList();
    await AssetsManager.updatePublication(publicationId, excludeAssetNames);

    await dispatch('updatePublicationRelatedStores', publicationId);
    const updated = true;
    return new UpdateResponse(updated, trackingItemView.isContentDownloaded);
  },

  async actualizeBookMeta(
    { dispatch, getters, rootGetters },
    { publicationId, needAutoUpdate }
  ) {
    const publication = rootGetters['LibraryStore/getPublicationById'](
      publicationId
    );
    const publicationsStoreName = getters[`getStoreNameByPublicationType`](
      publication.type
    );
    const meta = await dispatch(
      `${publicationsStoreName}/readMeta`,
      publicationId,
      {
        root: true
      }
    );
    if (!needAutoUpdate && publication.version !== meta.version) {
      await dispatch('updatePublicationRelatedStores', publicationId);
    }
  },

  async needUpdate(_, publicationId) {
    const trackingItemView = await AssetsManager.getPublicationTrackingItemView(
      publicationId
    );
    const needUpdate =
      trackingItemView.outdatedData &&
      trackingItemView.isContentDownloaded &&
      trackingItemView.isAudioDownloaded;
    return needUpdate;
  },

  async updatePublicationRelatedStores(
    { dispatch, commit, getters, rootGetters },
    publicationId
  ) {
    const publication = rootGetters['LibraryStore/getPublicationById'](
      publicationId
    );
    const publicationsStoreName = getters[`getStoreNameByPublicationType`](
      publication.type
    );
    const meta = await dispatch(
      `${publicationsStoreName}/readMeta`,
      publicationId,
      {
        root: true
      }
    );
    await dispatch(
      'LibraryStore/actualizeBook',
      {
        publicationId: publicationId,
        meta
      },
      { root: true }
    );
    commit(
      'RecentBookStore/actualizeRecentBookItem',
      {
        publicationId: publicationId,
        meta
      },
      { root: true }
    );
    await dispatch(
      'LibraryStore/updatePublicationSource',
      {
        bookId: publicationId
      },
      { root: true }
    );
  },

  async saveMetaData({ getters, dispatch }, data) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/saveMetaData`, data, {
      root: true
    });
  },

  // use 'resetSelectionTrigger' to reset selection from components
  // not initiated inside PresentPublication
  resetSelectionTrigger() {},

  async buildCompilationData(
    { dispatch, rootGetters },
    { locator, bookId, selectedText }
  ) {
    const meta = rootGetters['BookStore/getMeta']();
    const paraId = locator.startLocator.prefixedParagraphId;
    const lang = rootGetters['BookStore/getParaLanguage'](paraId);
    const alignment = rootGetters['BookStore/isElementVoiced'](bookId, paraId)
      ? await dispatch('getAlignmentFromLocator', { locator, bookId })
      : [];
    const audio = meta?.audio
      ? rootGetters['BookStore/getAudioLink'](bookId, paraId)
      : '';
    const publicationParaInfo = rootGetters['BookStore/getPublicationParaInfo'](
      paraId
    );
    const htmlBlockId = MarkerUtils.getBlockIdByLocator(locator.startLocator);
    const storeBlockId = rootGetters['BookStore/getBlockIdByParaId'](paraId);
    const blockId = htmlBlockId || storeBlockId;
    const builder = CompilationFactory.getSelectionQueryBuilder();
    return builder
      .setLocator(locator)
      .setAudio(audio)
      .setType(CompilationSelectionTypes.SELECTION)
      .setContent(CompilationsService.convertTextToHtml(selectedText))
      .setWordsCount(CompilationsService.countWordsInText(selectedText))
      .setLanguage(lang)
      .setAlignment(alignment)
      .setLocation(locator.serialize())
      .setPublication(publicationParaInfo)
      .setBlockId(blockId)
      .build();
  },

  buildCompilationSelectionFromLocatorRange: async function(
    { dispatch, rootGetters },
    { locator, bookId }
  ) {
    const selectedLocators = rootGetters[
      'BookStore/getTextSelectionForParaRange'
    ](locator);
    const selectionsData = [];
    for (const selection of selectedLocators) {
      const isNoTextInSelection =
        !selection.startLocator.logicalCharOffset &&
        !selection.endLocator.logicalCharOffset;
      if (isNoTextInSelection) {
        continue;
      }
      const selectedText = await dispatch('getSelectedText', selection);
      if (!selectedText) {
        continue;
      }
      const selectionData = await dispatch('buildCompilationData', {
        locator: selection,
        bookId,
        selectedText
      });
      selectionsData.push(selectionData);
    }

    return selectionsData;
  },

  buildCompilationSelectionFromLocator: async function(
    { dispatch },
    { bookId, locator }
  ) {
    if (!locator) {
      return null;
    }

    if (
      locator.startLocator.prefixedParagraphId ===
      locator.endLocator.prefixedParagraphId
    ) {
      const selectedText = await dispatch('getSelectedText', locator);
      if (!selectedText) {
        return null;
      }
      return await dispatch('buildCompilationData', {
        locator,
        bookId,
        selectedText
      });
    }

    return await dispatch('buildCompilationSelectionFromLocatorRange', {
      locator,
      bookId
    });
  },

  getAlignmentFromLocator: async function(
    { dispatch, getters },
    { locator, bookId }
  ) {
    const fallbackAlignment = [];
    try {
      const paraId = locator.startLocator.prefixedParagraphId;
      await dispatch(
        'PlaybackStore/preloadAlignment',
        { bookId, paraId },
        { root: true }
      );
    } catch (error) {
      logger.error(`Error while preloading alignment: ${error}`);
      return fallbackAlignment;
    }

    return getters.getAlignmentPartFromLocator({ locator, bookId });
  },

  getSelectedText: function({ rootGetters }, locator) {
    const excludeSelectors = [
      `.${AppConstantsUtil.SEARCH_REQ_DECORATOR_CLASS}`,
      `.${AppConstantsUtil.SENTENCE_DECORATOR_CLASS}`,
      `.${AppConstantsUtil.SEARCH_REQ_DECORATOR_CLASS}:nth-child(odd)`,
      `.${AppConstantsUtil.SEARCH_REQ_DECORATOR_CLASS}:nth-child(even)`,
      `.${AppConstantsUtil.OPEN_ANCHOR_CLASS_NAME}`,
      `.${SelectionConstantUtils.SELECTIONS_CLASS}`,
      '[data-wi]',
      'a',
      '.intricate-word',
      '.bh-first-letter',
      '.ilm-first-letter',
      '[data-pg]',
      '[data-bookmark-id]',
      '[data-annotation-id]',
      ...AnnotationDomUtils.getAnnotationClasses().map(
        className => `.${className}`
      )
    ];
    const paraId = locator.startLocator.prefixedParagraphId;
    const isSafari = rootGetters['ContextStore/isSafari'];
    const isIos = rootGetters['ContextStore/isIos'];
    const shouldToggleSelectionClass = isSafari || isIos;

    if (shouldToggleSelectionClass) {
      _toggleUserSelectStyle(paraId);
    }

    const selectionHtml = rootGetters[
      'BookStore/getParagraphHtmlByInTextRangeLocator'
    ](locator, excludeSelectors);

    if (shouldToggleSelectionClass) {
      _toggleUserSelectStyle(paraId);
    }
    return selectionHtml;
  },
  getAudioSourceLink({ getters, dispatch }, payload) {
    const storeName = getters.getStoreName;
    return dispatch(`${storeName}/getAudioSourceLink`, payload, { root: true });
  }
};
const mutations = {
  setWordsStableOffsets(state, { publicationId, paraId, wordsStableOffsets }) {
    let publicationOffsets = state.wordsStableOffsets[publicationId];
    if (!publicationOffsets) {
      publicationOffsets = {};
    }
    publicationOffsets = {
      ...publicationOffsets,
      ...{ [paraId]: wordsStableOffsets }
    };
    state.wordsStableOffsets[publicationId] = {
      ...state.wordsStableOffsets[publicationId],
      ...publicationOffsets
    };
  },

  setIsApplyAllParams(state, val) {
    state.isApplyAllParams = val;
  },
  setTransitionOver(state, val) {
    state.transitionOver = val;
  },
  setIsScrollStabled(state, val) {
    state.isScrollStabled = val;
  },
  setRotateScroll(state, val) {
    state.rotateScroll = val;
  },
  destroyState(state, excludeInitProps) {
    const newSate = initState();
    Object.keys(state).forEach(key => {
      if (excludeInitProps.includes(key)) {
        return;
      }
      state[key] = newSate[key];
    });
  },
  setIsAudioStartPlaying(state, val) {
    state.isAudioStartPlaying = val;
  },
  setSkipCurrentLocatorChange(state, val) {
    state.skipCurrentLocatorChange = val;
  },
  setBookScrollEvent(state, scrollEvent) {
    state.scrollEvent = scrollEvent;
  },
  setMetaBlockHeigth(state, val) {
    state.metaBlockHeigth = val;
  },
  setParaPlayButtonPressed(state, val) {
    state.paraPlayButtonPressed = val;
  },
  setFabButtonPressed(state, val) {
    state.fabButtonPressed = val;
  },
  setIsFABDisabled(state, val) {
    state.isFABDisabled = val;
  },
  setIsShownTextSelection(state, val) {
    state.isShownTextSelection = val;
  },
  setAnimateScroll(state, animateScroll) {
    state.animateScroll = { ...state.animateScroll, ...animateScroll };
  },
  setStoreName(state, storeName) {
    state.storeName = storeName;
  },
  setScrollConstants(state, scrollConstants) {
    state.scrollConstants = scrollConstants;
  },
  setIsViewModeToScrollConstants(state, isViewMode) {
    state.scrollConstants.setIsViewMode(isViewMode);
  },
  updateFractionalScrollRemainder(state, remainder) {
    state.fractionalScrollRemainder = remainder;
  },
  updateScrubberPositionLineByLineMode(state, position) {
    state.scrubberPositionLineByLineMode = position;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
