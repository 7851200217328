import i18n from '@/i18n';

import LoginService from '@/services/LoginService';
import UserService from '@/services/UserService';
import RestService from '@/services/RestService';
import RestPaymentsService from '@/services/RestPaymentsService';
import AgentService from '@/services/Agent/AgentService';
import MessageBufferService from '@/services/MessageBufferService';
import LibraryStoreService from '@/services/LibraryStoreService';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import LocalStorageUtils from '@shared/utils/LocalStorageUtils.mjs';
import PublicationsLoaderRemoteService from '@/services/PublicationsLoader/PublicationsLoaderRemoteService';
import UserSegmentationDataService from '@/services/UserSegmentationDataService';
import CookieStoreService from '@/services/CookieStoreService';

import LoginActionsEnum from '@/enums/LoginActionsEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import UserStatusesEnum from '@/enums/UserStatusesEnum';
import AuthTypeEnum from '@/enums/AuthTypeEnum';
import ProfileEnum from '@/enums/ProfileEnum';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import LoginStatesEnum from '@/enums/LoginStatesEnum';
import DeleteParamsEnum from '@shared/enums/DeleteAccountParamsEnum';
import { GroupTypesEnum } from '@shared/enums/SubscriptionTypesEnum';

import UserFactory from '@/classes/factories/UserFactory';
import LoggerFactory from '@/services/utils/LoggerFactory';
import MaterialsUtils from '@/services/utils/MaterialsUtils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PwaService from '@/services/PwaService';

const logger = LoggerFactory.getLogger('UserStore');
const SESSION_SETTING = 'session';
const GUEST_GROUP = 'guest_session';
const TRACKING = 'tracking';
const LOCAL_FAKE_SESSION_ID = 'local-fake-session-id';
const ASSESSMENT_BANNER = 'assessment-banner';
const SEARCH_BANNER = 'search-banner';
// initial state

function setUserId(user) {
  const userId = user.id;
  if (!userId) {
    return;
  }
  LibraryStoreService.setUserId(userId);
}

const initState = () => ({
  user: {},
  sessionId: '',
  runId: '',
  tempUserData: {},
  subscription: null,
  intervalId: null,
  appleSignInState: '',
  isSuggestedBannerVisible: true,
  isSearchBannerVisible: true,
  userSegmentationData: {},
  isUserSegmentationFormValid: false,
  loggingOut: false
});

// getters
const storeGetters = {
  getUser: state => {
    return state.user;
  },
  getSessionId: state => {
    return state.sessionId;
  },
  getRunId: state => {
    return state.runId;
  },
  getUserId: state => {
    return state.user && state.user.id;
  },
  getUserEmail: state => {
    return state.user && state.user.email;
  },
  getAppleSignInState: state => {
    return state.appleSignInState;
  },
  isAuthenticated: state => {
    return !!state.sessionId.length;
  },
  isLocalFakeSession: state => {
    return state.sessionId === LOCAL_FAKE_SESSION_ID;
  },
  isGuestUser(state, getters) {
    return (
      getters.isAuthenticated && state.user.active === UserStatusesEnum.UNKNOWN
    );
  },
  isLoggedIn(state, getters) {
    return getters.isAuthenticated && !getters.isGuestUser;
  },
  getUserPhoto: () => fileId => {
    return UserService.getUserPhoto(fileId);
  },
  getGuestUserId: () => {
    return getGuestId();
  },
  isUserStudent(state) {
    return state.user.studentRole;
  },
  isUserEditor(state) {
    return state.user.editorRole;
  },
  isUserAdmin(state) {
    return state.user.adminRole;
  },
  isUserSalesPerson(state) {
    return state.user.salesPersonRole;
  },
  isUserAffiliate(state) {
    return state.user.affiliateRole;
  },
  getSubscription(state) {
    return state.subscription;
  },
  isSubscriptionActive(state, getters) {
    return getters.getSubscription && getters.getSubscription.active;
  },
  isFamilySubscriptionActive(state, getters) {
    return (
      getters.isSubscriptionActive &&
      getters.getSubscription.groupType === GroupTypesEnum.FAMILY
    );
  },
  isUserFamilyGroupOwner(state, getters) {
    return (
      getters.isFamilySubscriptionActive &&
      getters.getUserId === getters.getSubscription.userId
    );
  },
  isUserFamilyGroupMember(state, getters) {
    return (
      getters.isFamilySubscriptionActive &&
      getters.getUserId !== getters.getSubscription.userId
    );
  },
  isFreeUser(state) {
    return state.user.freeRole;
  },
  isContentAvailable(state, getters, rootState, rootGetters) {
    return (
      !rootGetters['ContextStore/isPurchaseEnabled'] ||
      getters.isSubscriptionActive ||
      getters.isFreeUser
    );
  },
  getInviterUid: () => () =>
    UnifiedSettingsService.getSetting(SESSION_SETTING, 'inviterUid'),
  isUserEmpty(state) {
    return !Object.keys(state.user).length;
  },
  getTempUserData(state) {
    return state.tempUserData;
  },
  getUserPhotoHash(state) {
    return state.user?.photo?.fileHash;
  },
  isSuggestedBannerVisible(state) {
    return state.isSuggestedBannerVisible;
  },
  isSearchBannerVisible(state) {
    return state.isSearchBannerVisible;
  },
  getUserSegmentationData(state) {
    return state.userSegmentationData;
  },
  isUserSegmentationFormValid(state) {
    return state.isUserSegmentationFormValid;
  },
  isLoggingOut(state) {
    return state.loggingOut;
  }
};

// actions
const actions = {
  async fillUserFromDb({ dispatch }) {
    const userInfo = {
      user: UnifiedSettingsService.getSetting(SESSION_SETTING, 'profile') || {},
      sessionId:
        UnifiedSettingsService.getSetting(SESSION_SETTING, 'SID') || '',
      runId: UnifiedSettingsService.getSetting(SESSION_SETTING, 'runId') || '',
      subscription:
        UnifiedSettingsService.getSetting(SESSION_SETTING, 'subscription') ||
        null
    };
    await dispatch('fillUserStore', userInfo);
  },
  fillUserStore({ commit }, userInfo) {
    commit('setUser', userInfo?.user);
    commit('setSession', userInfo?.sessionId);
    commit('setRunId', userInfo?.runId);
    commit('setSubscription', userInfo?.subscription);
    commit(
      'setAssessmentBannerVisibility',
      UnifiedSettingsService.getSetting(SESSION_SETTING, ASSESSMENT_BANNER) ??
        true
    );
    commit(
      'setSearchBannerVisibility',
      UnifiedSettingsService.getSetting(SESSION_SETTING, SEARCH_BANNER) ?? true
    );
    if (userInfo?.runId && userInfo?.user?.id) {
      CookieStoreService.setCookie('runId', userInfo.runId);
      CookieStoreService.setCookie('user', userInfo.user);
      CookieStoreService.setCookie('userId', userInfo.user.id);
      CookieStoreService.setCookie(
        'isGuest',
        userInfo.user.active === UserStatusesEnum.UNKNOWN
      );
    }
  },
  async fillServerUser({ dispatch }, authResult) {
    await dispatch('fillUserStore', authResult);
    UserService.onAuth(authResult.sessionId, authResult.user, authResult.runId);
  },
  async clearServerUser({ dispatch, getters }, serverUserSession) {
    const currentUserId = getters.getUserId;
    const nuxtUserId = serverUserSession?.user?.id;
    const isNuxtUser =
      nuxtUserId && currentUserId && nuxtUserId === currentUserId;
    if (isNuxtUser) {
      await dispatch('fillUserStore', { user: {} });
    }
  },
  validateUserSession() {
    return UserService.checkSessionByRunId();
  },
  guestAutoLoginIfNeed({ getters, rootGetters, dispatch }) {
    const needAutoLogin = rootGetters['ContextStore/isNeedAutoLogin'];
    const isAuthenticated = getters.isAuthenticated;
    if (needAutoLogin && !isAuthenticated) {
      return dispatch('skipLogin');
    }
    return Promise.resolve();
  },
  loginOauth({ rootGetters }, { provider }) {
    const appState = rootGetters['ContextStore/appState'];
    const pubType = rootGetters['OpenParameterStore/getPublicationType'];
    const readingArea = rootGetters['ProgressStore/getReadingArea'];

    const paraId = readingArea?.toJSON ? readingArea.toJSON() : 'para_1';
    const isInsideBook =
      appState === AppStateEnum.PRESENT_PUBLICATION &&
      pubType === PublicationsTypesEnum.BOOK;

    const url = new URL(window.location.href);
    url.searchParams.append('paraId', paraId);
    const returnUri = isInsideBook ? url.href : '';

    switch (provider) {
      case AuthTypeEnum.GOOGLE:
        LoginService.loginByGmail(returnUri);
        break;
      case AuthTypeEnum.FACEBOOK:
        LoginService.loginByFacebook(returnUri);
        break;
      default:
        logger.error(`Unknown login type - ${provider}`);
        break;
    }
  },
  async performOauthLogin({ dispatch, rootGetters }, { loginInfo }) {
    const authResult = await LoginService.performLogin(
      loginInfo,
      AuthTypeEnum.OAUTH,
      rootGetters['PaymentsStore/getPromoCode']?.code
    );
    if (!authResult) {
      return Promise.reject(`Get empty authResult: ${authResult}`);
    }
    await dispatch('fillUserStore', authResult);
    return {
      status: authResult.status,
      isRegistered: authResult.isRegistered,
      isNewUser: authResult.isNewUser
    };
  },
  async loginByEmail({ dispatch }, { loginInfo }) {
    const authResult = await LoginService.loginByEmail(loginInfo);
    if (!authResult) {
      return Promise.reject();
    }
    await dispatch('fillUserStore', authResult);
    return authResult.status;
  },
  register(context, { registrationInfo }) {
    return UserService.registration(registrationInfo);
  },
  confirmTask(context, { taskConfirmationHashCode, confirm }) {
    return UserService.confirmAuthorizedTask(taskConfirmationHashCode, confirm);
  },
  fillTempUserData({ commit, getters }) {
    const name = getters.getUser.name;
    const initUserData = {
      name,
      currentPassword: '',
      newPassword: '',
      photo: {}
    };
    commit('setTempUserData', initUserData);
  },
  async removeAvatar({ commit, getters, dispatch }) {
    try {
      const userPhoto = getters.getUserPhotoHash;
      if (userPhoto) {
        await dispatch('deleteAttachment', {
          fileHash: userPhoto
        });
        await dispatch('persistPersonalProfile', { photo: {} });
      }
      commit('setTempUserPhoto', '');
      commit('setUserPhoto', '');
    } catch (error) {
      logger.error(`Get error on remove avatar error: ${error}`);
      dispatch('ManagePopupStore/openSystemExceptionToaster', null, {
        root: true
      });
    }
  },
  async updateUserData(
    { commit, getters, dispatch },
    { currentPassword, newPassword }
  ) {
    try {
      const updatedUserData = newPassword
        ? { ...getters.getTempUserData, currentPassword, newPassword }
        : getters.getTempUserData;
      let userDataToUpdate = updatedUserData;

      const fileHash = updatedUserData.photo?.fileHash;
      const isAvatarUpdated = Boolean(fileHash);
      if (isAvatarUpdated) {
        const oldUserPhoto = getters.getUser.photo?.fileHash;
        await dispatch('deleteAttachment', {
          fileHash: oldUserPhoto
        });
      } else {
        delete userDataToUpdate.photo;
      }
      await dispatch('persistPersonalProfile', userDataToUpdate);
      if (isAvatarUpdated) {
        commit('setTempUserData', { ...updatedUserData, photo: {} });
      }
    } catch (error) {
      if (error && error.hasOwnProperty('isValidPassword')) {
        return error;
      }
      logger.error(`Get error on update user data error: ${error}`);
      dispatch('ManagePopupStore/openSystemExceptionToaster', null, {
        root: true
      });
    }
  },
  async loginByHashcode({ dispatch, getters }, { taskConfirmationHashCode }) {
    await AgentService.logout(getters.isGuestUser);
    const authResult = await UserService.authenticate(
      { taskConfirmationHashCode },
      AuthTypeEnum.HASHCODE
    );
    if (!authResult) {
      return Promise.reject();
    }
    await dispatch('fillUserStore', authResult);
    return authResult;
  },
  resetPassword(context, { password, taskConfirmationHashCode }) {
    return UserService.resetPassword(password, taskConfirmationHashCode);
  },
  async skipLogin({ dispatch }) {
    let authResult = await LoginService.skipLogin();
    const defaultResponse = {
      isRegistered: false,
      message: '',
      runId: '',
      sessionId: LOCAL_FAKE_SESSION_ID,
      status: LoginActionsEnum.LOGGED_IN,
      user: {
        id: '',
        active: UserStatusesEnum.UNKNOWN
      }
    };
    authResult = authResult || defaultResponse;
    await dispatch('fillUserStore', authResult);
    UnifiedSettingsService.setSetting(
      GUEST_GROUP,
      'guest_userId',
      authResult.user.id
    );
    return authResult.status;
  },
  async logout({ commit, dispatch, rootGetters, getters }) {
    commit('setLoggingOut', true);
    let initialUserInfo = {
      user: {},
      sessionId: '',
      runId: '',
      subscription: null
    };
    const isOnline = rootGetters['ContextStore/isOnline'];
    const isDevice = rootGetters['ContextStore/isDevice'];
    const isGuest = getters.isGuestUser;
    await UserService.logout(isOnline, isGuest);
    UnifiedSettingsService.clearGroup(SESSION_SETTING);
    PwaService.removeCacheStorageByCacheName('couchdb-user');
    await dispatch('fillUserStore', initialUserInfo);
    if (!isOnline && !isDevice) {
      dispatch(
        'ManagePopupStore/openErrorToaster',
        {
          text: i18n.localize('App.Login.logoutNetwork.error')
        },
        { root: true }
      );
      return;
    }
    try {
      await dispatch('UserStore/guestAutoLoginIfNeed', null, { root: true });
    } catch (error) {
      dispatch(
        'ManagePopupStore/openErrorToaster',
        { text: i18n.localize('App.Login.logoutDb.error') },
        { root: true }
      );
      throw error;
    }
    await PublicationsLoaderRemoteService.clearCacheData();
    commit('ProgressStore/resetStore', null, { root: true });
    commit('SubscriptionStore/resetStore', null, { root: true });
    commit('RecentBookStore/resetRecentBooks', null, { root: true });
    dispatch('ReadingSettingsStore/resetReadingSettings', null, { root: true });
    commit('PaymentsStore/setPromoCode', null, { root: true });
    dispatch('OpenManagePublicationsStore/reset', null, { root: true });
    UnifiedSettingsService.clearGroup('ManagePublicationsSettings');
    UnifiedSettingsService.clearGroup('AudioSettings');
    UnifiedSettingsService.clearGroup('ReaderSettings');
    UnifiedSettingsService.clearGroup('ExpandedNotesSettings');
    UnifiedSettingsService.clearGroup('ReadingProgress');

    LocalStorageUtils.removeExcept({
      CLIENT_NODE_ID: true,
      _pouch_check_localstorage: true,
      loglevel: true,
      guest_session_guest_session: true,
      'loglevel:webpack-dev-server': true
    });

    dispatch(
      'LibraryStore/destroyStates',
      [ManagePublicationsStates.LIBRARY, ManagePublicationsStates.COMPILATIONS],
      { root: true }
    );
    commit('setLoggingOut', false);
  },
  async deleteUserAccount({ dispatch }, delay) {
    await UserService.deleteAccount(delay);
    if (delay === DeleteParamsEnum.DELETE_NOW) {
      dispatch('logout');
    }
  },
  cancelDeleteAccount() {
    return UserService.cancelDeleteAccount();
  },
  persistPersonalProfile({ state, commit }, userData) {
    const user = Object.assign({}, state.user, userData);

    user.passwordPersistingModeEnum = userData.newPassword
      ? ProfileEnum.PASSWORD_SET_NEW
      : ProfileEnum.PASSWORD_WITHOUT_CHANGES;

    const profile = UserFactory.create(user);
    const profilePersistingInfo = UserFactory.createProfilePersistingInfo(user)
      .setNewPassword(userData.newPassword)
      .setPasswordConfirmation(userData.currentPassword)
      .setPasswordPersistingMode(user.passwordPersistingModeEnum)
      .setChangeEmail(true);

    return UserService.persistUserProfile({
      profile,
      profilePersistingInfo
    }).then(response => {
      if (
        response.data &&
        response.data.status === 'ERROR' &&
        response.data.text === 'Password confirmation failed.'
      ) {
        return Promise.reject({
          isValidPassword: false
        });
      }
      commit('setUser', user);
      CookieStoreService.setCookie('user', user);

      return Promise.resolve();
    });
  },
  processAffiliateQuery({ getters }, query) {
    if (query.uid && !getters.getInviterUid()) {
      UnifiedSettingsService.setSetting(
        SESSION_SETTING,
        'inviterUid',
        query.uid
      );
      UnifiedSettingsService.setSetting(TRACKING, 'inviterUid', query.uid);
    }
    if (query.promocode) {
      UnifiedSettingsService.setSetting(
        TRACKING,
        'trackedPromoCode',
        query.promocode
      );
    }
  },
  sendContactUsMessage({ rootGetters }, payload) {
    const params = rootGetters['ContextStore/aboutContext'];
    payload.params = params;
    return RestService.restRequest('post', 'Contact', 'sendMessage', payload);
  },
  async getUserProfile({ state, commit, dispatch, rootGetters, getters }) {
    try {
      if (!rootGetters['ContextStore/isOnline'] || getters.isGuestUser) {
        return;
      }
      const userData = await UserService.getUserProfileById(state.user.userId);
      const user = userData.userProfileInfo;
      user.hasPassword = userData.userProfileStatus.hasPassword;
      commit(
        'setSubscription',
        userData.userSubscription ? userData.userSubscription : null
      );
      const promoCode = userData.userPromoCode?.promoCode;
      if (promoCode) {
        await dispatch('PaymentsStore/getPromoCodeInfo', promoCode, {
          root: true
        });
      }
      commit('setUser', Object.assign({}, state.user, user));
      dispatch(
        'PaymentsStore/openPaymentActionPopupIfNeed',
        state.subscription,
        {
          root: true
        }
      );
    } catch (error) {
      logger.error(error);
    }
  },

  uploadAttachment({ dispatch }, { blob, fileHash }) {
    dispatch('deleteAttachment', { fileHash });

    return MaterialsUtils.uploadAttachment({ blob })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        logger.error(`Upload attachment failed with error: ${error}`);
      });
  },
  deleteAttachment(context, { fileHash }) {
    return MaterialsUtils.deleteAttachment({ fileHash }).catch(error => {
      logger.error(`Delete attachment failed with error: ${error}`);
    });
  },
  async startActualizeUser({ commit, dispatch, getters }) {
    if (
      !getters.isLocalFakeSession &&
      (!getters.isAuthenticated || getters.isGuestUser)
    ) {
      return;
    }
    await dispatch('actualizeUser');

    dispatch('stopActualizeUser');
    const updateInterval = AppConstantsUtil.UPDATE_USER_INTERVAL;
    let intervalId = setTimeout(function actualize() {
      dispatch('actualizeUser');
      intervalId = setTimeout(actualize, updateInterval);
    }, updateInterval);
    commit('setIntervalId', intervalId);
  },
  stopActualizeUser({ commit, state }) {
    clearTimeout(state.intervalId);
    commit('setIntervalId', null);
  },
  actualizeUser({ dispatch, getters }) {
    if (getters.isLocalFakeSession) {
      return dispatch('skipLogin');
    }
    return dispatch('getUserProfile');
  },
  async changeUserPhoto({ commit, getters, dispatch }, { blob }) {
    const response = await dispatch('uploadAttachment', { blob });
    if (!(response.status === 'ERROR')) {
      const fileHash = response.fileHash;
      const tempUserData = { ...getters.getTempUserData, photo: { fileHash } };
      commit('setTempUserData', tempUserData);
    }
  },
  openLimitedAccessPopup({ dispatch, rootGetters }) {
    const isGuest = rootGetters['UserStore/isGuestUser'];
    const isLimitedGuestAccess =
      rootGetters['ContextStore/isLimitedGuestAccess'];
    if (isGuest && isLimitedGuestAccess) {
      dispatch(
        'ManagePopupStore/openPopup',
        {
          name: PopupNamesEnum.CONNECT,
          popupContext: { initState: LoginStatesEnum.REGISTRATION }
        },
        { root: true }
      );
    } else {
      dispatch('PaymentsStore/openSubscribePopup', null, { root: true });
    }
  },
  async fetchUserSegmentationData({ commit, dispatch, rootGetters }) {
    try {
      const isOnline = rootGetters['ContextStore/isOnline'];
      if (!isOnline) {
        return;
      }

      const {
        data
      } = await UserSegmentationDataService.getUserSegmentationData();
      commit('setUserSegmentationData', data);
    } catch (error) {
      logger.error(`Get error on fetching user segmentation data: ${error}`);
      dispatch('ManagePopupStore/openSystemExceptionToaster', null, {
        root: true
      });
    }
  },
  setUserSegmentationFormValid({ commit }, isValid) {
    commit('setUserSegmentationFormValid', isValid);
  }
};

function getGuestId() {
  return UnifiedSettingsService.getSetting(SESSION_SETTING, 'guest_userId');
}

function saveSubscription(subscription) {
  UnifiedSettingsService.setSetting(
    SESSION_SETTING,
    'subscription',
    subscription
  );
}

// mutations
const mutations = {
  setUser(state, user) {
    const sessionGroup = UnifiedSettingsService.getGroup(SESSION_SETTING);
    const localUserData = sessionGroup.get('profile');
    sessionGroup.set('profile', Object.assign({}, localUserData, user));
    const isAllowAdd = [
      UserStatusesEnum.APPROVED,
      UserStatusesEnum.UNKNOWN
    ].includes(user?.active);

    if (!isAllowAdd) {
      return;
    }
    const preparedUser = UserFactory.create(user);
    state.user = preparedUser;
    setUserId(state.user);
  },
  setSession(state, sessionId) {
    state.sessionId = sessionId || '';
  },
  setRunId(state, runId) {
    const _runId = runId || '';
    RestService.setRunId(_runId);
    RestPaymentsService.setRunId(_runId);
    AssetsManager.setRunId(_runId);
    MessageBufferService.setRunId(_runId);
    state.runId = _runId;
  },
  setSubscription(state, subscription) {
    saveSubscription(subscription);
    state.subscription = subscription;
  },
  setAppleSignInState(state, appleSignInState) {
    state.appleSignInState = appleSignInState;
  },
  deactivateSubscription(state) {
    state.subscription = { ...state.subscription, active: false };
    saveSubscription(state.subscription);
  },
  setIntervalId(state, intervalId) {
    state.intervalId = intervalId;
  },
  setTempUserData(state, fileHash) {
    state.tempUserData = fileHash;
  },
  setTempUserPhoto(state, fileHash) {
    state.tempUserData = {
      ...state.tempUserData,
      ...{ photo: {} }
    };
    const photo = { ...state.tempUserData.photo, ...{ photo: { fileHash } } };
    state.tempUserData = {
      ...state.tempUserData,
      ...photo
    };
  },
  setUserPhoto(state, fileHash) {
    state.user = {
      ...state.user,
      ...{ photo: {} }
    };
    const photo = { ...state.user.photo, ...{ photo: { fileHash } } };

    state.user = {
      ...state.user,
      ...photo
    };
  },
  setAssessmentBannerVisibility(state, isVisible) {
    UnifiedSettingsService.setSetting(
      SESSION_SETTING,
      ASSESSMENT_BANNER,
      isVisible
    );
    state.isSuggestedBannerVisible = isVisible;
  },
  setSearchBannerVisibility(state, isVisible) {
    UnifiedSettingsService.setSetting(
      SESSION_SETTING,
      SEARCH_BANNER,
      isVisible
    );
    state.isSearchBannerVisible = isVisible;
  },
  setUserSegmentationData(state, data) {
    state.userSegmentationData = data || {};
  },
  setUserSegmentationFormValid(state, isValid) {
    state.isUserSegmentationFormValid = isValid;
  },
  setLoggingOut(state, loggingOut) {
    state.loggingOut = loggingOut;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
