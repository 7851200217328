import BookInfoTabViewFactory from '@/classes/factories/ExtrasPopup/BookInfoTabViewFactory';

import AppConstants from '@/services/utils/AppConstantsUtil';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import Utils from '@/services/utils/Utils';
import TextUtils from '@shared/publication/dom-utils/text-utils.mjs';
import last from 'lodash/last';
import get from 'lodash/get';
import findLast from 'lodash/findLast';
import RestService from '@/services/RestService';
import Locator from '@shared/publication/locator.mjs';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';

import isEmpty from 'lodash/isEmpty';
import BookFactory from '@/classes/factories/BookFactory';
import SearchNavigationFactory from '@/classes/factories/SearchNavigationFactory';
import ContentRequestFactory from '@/classes/factories/ContentRequestFactory';
import BookScrollEnum from '@/enums/BookScrollEnum';
import DataStateEnum from '@/enums/DataStateEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import publicationUtils from '@/services/utils/publicationUtils';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookStore.js');

const initState = _createBookItem;

function _createBookItem() {
  return {
    dataState: DataStateEnum.NONE,
    bookId: '',
    openParams: {},
    meta: {},
    contentIndex: {},
    content: [],
    alignmentIndexMap: {},
    alignmentIndex: [],
    alignment: {},
    pauseMap: {},
    audioLinks: {},
    searchNavigation: SearchNavigationFactory.createEmptySearchNavigation()
  };
}

// getters
const storeGetters = {
  getBlockIdByParaId: (state, gertters) => paraId => {
    const contentIndex = gertters.getParagraphsSummary();
    if (!contentIndex || !paraId) {
      return null;
    }
    return contentIndex[paraId]?.blockId || null;
  },
  getParaIdFromStartToEnd: state => (startParaId, endParaId) => {
    if (!startParaId || !endParaId) {
      return [];
    }
    const bookItem = state;
    const lastParaId = last(bookItem.content).id;
    const { index: startParaIndex } = _getClosestParaIndex(
      bookItem.contentIndex,
      startParaId,
      lastParaId
    );
    const { index: endParaIndex } = _getClosestParaIndex(
      bookItem.contentIndex,
      endParaId,
      lastParaId
    );
    if (startParaIndex > endParaIndex) {
      return [];
    }
    const correctionIndex = 1;
    const paraRange = bookItem.content.slice(
      startParaIndex,
      endParaIndex + correctionIndex
    );
    return paraRange.map(paraObj => paraObj.id);
  },
  getParaIdByBlockId: (state, gertters) => blockId => {
    const content = gertters.getParagraphItems;
    if (!content || !blockId) {
      return null;
    }
    const contentItem = content.find(item => {
      return item.blockId === blockId;
    });
    return contentItem?.id || null;
  },
  getBookVersion: state => {
    return state.meta?.version || '';
  },
  isCompactData: state => {
    return state.dataState === DataStateEnum.COMPACT;
  },
  isParaOutEndContent: state => paraId => {
    const bookItem = state;
    let lastContentParaId = '';
    for (let i = bookItem.content.length - 1; i >= 0; i--) {
      const contentItem = bookItem.content[i];
      const hasContent = contentItem.words !== 0;
      if (hasContent) {
        lastContentParaId = contentItem.id;
        break;
      }
    }
    return (
      MarkerUtils.getParaNum(lastContentParaId) <=
      MarkerUtils.getParaNum(paraId)
    );
  },
  getBookIdByParaId: (state, getters, rootState, rootGetters) => () => {
    return rootGetters['OpenParameterStore/getPublicationId'];
  },
  getPauseMsByParaId: state => paraId => {
    return get(state, `pauseMap[${paraId}].pause`, 0) * 1000;
  },
  hasPauseMap(state) {
    return Object.keys(state.pauseMap || {}).length;
  },
  getAudioParaId: () => paraId => {
    return paraId;
  },
  isCollectionOpen(state) {
    return Boolean(state.meta.collection);
  },
  getMiddleParaIdNum: state => (startLocator, endLocator) => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    const startParaId = startLocator.prefixedParagraphId;
    const endParaId = endLocator.prefixedParagraphId;

    const startIndex = bookItem.contentIndex[startParaId].index;
    const endIndex = bookItem.contentIndex[endParaId].index;
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);
    const middleParaId = get(
      bookItem,
      `content[${middleIndex}].id`,
      startParaId
    );
    const paraNum = middleParaId.replace('para_', '');
    return parseInt(paraNum);
  },
  getExcludeInitProps() {
    return ['searchNavigation', 'dataState'];
  },
  getParagraphItems(state) {
    return state.content;
  },
  getAudioLinks: state => () => {
    return state.audioLinks;
  },
  getAlignmentIndexMap: state => {
    return state.alignmentIndexMap;
  },
  getAlignment: state => {
    return state.alignment;
  },
  // eslint-disable-next-line
  getBookInfoView: (state, getters) => () => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    return BookInfoTabViewFactory.create(bookItem.meta);
  },
  getBookLanguage: state => {
    return state.meta && state.meta.language;
  },
  getBookCategory: state => {
    return state.meta?.category;
  },
  isIlm: state => () => {
    //need apply book styles file instead classes
    const bookItem = state;
    if (!bookItem) {
      return null;
    }

    return Boolean(bookItem.meta.ilmId);
  },
  getFullIlmUrl: (state, getters, rootState, rootGetters) => (
    bookId,
    locators
  ) => {
    const para = MarkerUtils.getParaByRangeLocator(locators);
    const blockId = para.getAttribute(AppConstants.ILM_ID_ATTRIBUTE);
    if (!bookId) {
      return '';
    }
    const host = rootGetters['ContextStore/getIlmUrl'];
    let url = `${host}/books/${bookId}/`;
    if (blockId) {
      url += `display/${blockId}`;
    } else {
      url += 'edit';
    }
    return url;
  },
  getBookItemPosition: (state, getters, rootState, rootGetters) => bookId => {
    const defaultResp = BookFactory.createBookItemPosition();
    const bookItem = state;
    if (!bookItem) {
      return defaultResp;
    }
    const collectionId = bookItem.meta.collection;
    const collection = rootGetters['LibraryStore/getPublicationById'](
      collectionId
    );
    if (!collection) {
      return defaultResp;
    }
    const sortStrategy = rootGetters['ContextStore/librarySortStrategy'];
    const sortedCollectionItems = publicationUtils.sortPublication(
      [...collection.items] || [],
      sortStrategy
    );
    return BookFactory.createBookItemPosition(sortedCollectionItems, bookId);
  },
  getOpenParams: state => () => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    return bookItem.openParams;
  },
  getSearchNavigation: state => () => {
    return state.searchNavigation;
  },
  getNavigationIndex: state => {
    return state.searchNavigation.navigateIndex;
  },
  getMeta: state => () => {
    const bookItem = state;
    if (!bookItem || isEmpty(bookItem.meta)) {
      return null;
    }
    return bookItem.meta;
  },
  getMetaView: state => () => {
    const bookItem = state;
    if (!bookItem || isEmpty(bookItem.meta)) {
      return null;
    }
    return BookFactory.createBookMetaView(bookItem.meta);
  },
  getPublicationParaMeta: state => paraId => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    return bookItem.contentIndex[paraId] || null;
  },
  getParaLanguage: (state, getters) => paraId => {
    const paraMeta = getters.getPublicationParaMeta(paraId);
    return paraMeta.language ? paraMeta.language : state.meta.language;
  },
  getParaDirection: (state, getters) => paraId => {
    const paraLang = getters.getParaLanguage(paraId);
    return Utils.getDirection(paraLang);
  },
  getPublicationParaInfo: (
    state,
    getters,
    rootState,
    rootGetters
  ) => paraId => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    const paraMeta = getters.getPublicationParaMeta(paraId);
    const paraNum = paraMeta.paraNum;
    const slug = rootGetters['OpenParameterStore/getSlug'];
    return BookFactory.createPublicationParaInfo(
      paraId,
      slug,
      bookItem.meta,
      paraNum
    );
  },
  createCoverPath: () => (bookId, size) =>
    AssetsManager.getCoverPath(bookId, size),
  getParaNumByParaId: state => (bookId, paraId) => {
    return get(state, `contentIndex[${paraId}].paraNum`, '');
  },
  getParagraphsRange: state => (startParaId, endParaId) => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    const startIndex = bookItem.contentIndex[startParaId].index;
    const endIndex = bookItem.contentIndex[endParaId].index + 1;
    return bookItem.content.slice(startIndex, endIndex);
  },
  getParaIdByIndex: (state, getters) => (bookId, paraIndex) => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    const contentItem = bookItem.content[paraIndex];
    return contentItem ? contentItem.id : getters['getLastContentParagraph']();
  },
  getParaTextByParaId: state => (bookId, paraId) => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    const paraContent = bookItem.contentIndex[paraId].paragraph;
    const element = window.document.createElement('div');
    element.position = 'absolute';
    element.opacity = '0';
    element.innerHTML = paraContent;
    return element.innerText.replace(/\n+\s+/g, '');
  },
  getChapterNameByParaId: state => (bookId, paraId) => {
    const bookItem = state;
    const toc = bookItem.meta.toc;
    const id = MarkerUtils.getParaNum(paraId);
    const chapter =
      toc.length > 1
        ? findLast(toc, chapt => {
            const chapterId = MarkerUtils.getParaNum(chapt.id);
            return id >= chapterId;
          })
        : toc[0];
    return chapter ? chapter.text : '';
  },
  isChapter: state => paraId => {
    const bookItem = state;
    const toc = bookItem.meta.toc;
    if (!toc || !toc.length) {
      return false;
    }
    const chapterIndex = toc.findIndex(chapter => chapter.id === paraId);
    const isChapter = chapterIndex !== -1;
    return isChapter;
  },
  getTotalParagraphItems: state => () => {
    const bookItem = state;
    return Object.keys(bookItem.contentIndex).length;
  },
  getFirstAudioParagraph: state => () => {
    const bookItem = state;
    const firstIndex = bookItem.alignmentIndex[0];
    if (!firstIndex) {
      return '';
    }
    return firstIndex.id;
  },
  getFirstContentParagraph: state => () => {
    const firstParaId = state?.content?.[0]?.id || 'para_1';
    return firstParaId;
  },
  getLastContentParagraph: state => () => {
    const bookItem = state;
    if (!bookItem) {
      return null;
    }
    const lastIndex = bookItem.content[bookItem.content.length - 1];
    return lastIndex.id;
  },
  getParagraphsSummary: state => () => {
    return state ? state.contentIndex : {};
  },
  getPublicationStyleClassName: (state, getters) => (bookId, brand) => {
    const isIlm = getters['isIlm'](bookId);

    let bookStyleClass = isIlm
      ? [`global-${brand}`, 'ilm-book-styles']
      : [`${brand}-book-styles`];
    return bookStyleClass;
  },
  getParagraphAlignment: state => (bookId, paraId) => {
    let bookItem = state;
    return bookItem && bookItem.alignment[paraId];
  },
  getParagraphAlignmentIndex: state => (bookId, paraId) => {
    let bookItem = state;
    return bookItem && bookItem.alignmentIndexMap[paraId];
  },
  getNextVoicedParaId: (state, getters) => (bookId, paraId) => {
    let bookItem = state;
    // if (getters['isElementVoiced'](bookId, paraId)) {
    //   return paraId;
    // }
    const paraObj = bookItem.contentIndex[paraId];
    const startIndex = paraObj?.index || -1;
    if (startIndex === -1) {
      return null;
    }
    for (let i = startIndex + 1; i <= bookItem.content.length; i++) {
      const paragraphId = bookItem.content[i]?.id;
      if (paragraphId && getters['isElementVoiced'](bookId, paragraphId)) {
        return paragraphId;
      }
    }
    return null;
  },
  getNextParaId: state => paraId => {
    const bookItem = state;
    const defaultVal = '';
    const contentIndex = bookItem.contentIndex[paraId];
    if (!contentIndex) {
      return defaultVal;
    }
    const nextIndex = contentIndex.index + 1;
    const contentItem = bookItem.content[nextIndex];
    if (!contentItem) {
      return defaultVal;
    }

    return contentItem.id;
  },
  isElementVoiced: state => (bookId, paraId) => {
    const bookItem = state;
    return bookItem && bookItem.alignmentIndexMap.hasOwnProperty(paraId);
  },
  getAudioLink: state => (bookId, paraId) => {
    let bookItem = state;
    return bookItem && bookItem.audioLinks[paraId];
  },
  getParaElementByLocator: state => locator => {
    const paraId = locator.startLocator.prefixedParagraphId;
    const index = state.contentIndex[paraId].index;
    const paraElement = document.createElement('div');
    paraElement.innerHTML = state.content[index].paragraph;
    return paraElement;
  },
  getTextSelectionForParaRange: (
    state,
    getters,
    rootState,
    rootGetters
  ) => locator => {
    const paraIdsRange = MarkerUtils.getParagraphIdsInRange(locator);
    const bookId = getters.getBookIdByParaId(paraIdsRange[0]);
    const lastIndex = paraIdsRange.length - 1;
    const paraLocators = [];
    paraIdsRange.forEach((paraId, index) => {
      let startOffset, endOffset;
      const paraLength = rootGetters['BookStore/getParaTextByParaId'](
        bookId,
        paraId
      ).length;
      if (index === 0) {
        startOffset = locator.startLocator.logicalCharOffset;
        endOffset = paraLength;
      } else if (index === lastIndex) {
        startOffset = 0;
        endOffset = locator.endLocator.logicalCharOffset;
      } else {
        startOffset = 0;
        endOffset = paraLength;
      }

      const start = new Locator.InTextLocator(paraId, startOffset);
      const end = new Locator.InTextLocator(paraId, endOffset);
      const selectedLocator = new Locator.InTextRangeLocator(start, end);
      paraLocators.push(selectedLocator);
    });
    return paraLocators;
  },
  getParagraphHtmlByInTextRangeLocator: () => (
    locator,
    excludeSelectors = []
  ) => {
    const startParaId = get(locator, 'startLocator.prefixedParagraphId', null);
    const endParaId = get(locator, 'endLocator.prefixedParagraphId', null);
    if (!endParaId || !startParaId || startParaId !== endParaId) {
      logger.warn(`Try get html structure by locator ${locator}`);
      return '';
    }

    return TextUtils.getParagraphHtmlByInTextRangeLocator(
      locator,
      excludeSelectors
    );
  },
  getTextByRangeLocator: (state, getters) => locator => {
    const dummy = getters.getParaElementByLocator(locator);
    const text = dummy.textContent;
    const startIndex = TextUtils.recoverRealOffset(
      locator.startLocator.logicalCharOffset,
      text
    );
    const endIndex = TextUtils.recoverRealOffset(
      locator.endLocator.logicalCharOffset,
      text,
      true
    );
    const isEmptySelection = !startIndex && !endIndex;
    return isEmptySelection ? '' : text.slice(startIndex, endIndex);
  },
  getParaMetaByLocator: (state, getters) => locator => {
    const paraElement = getters.getParaElementByLocator(locator);
    const paraNum = MarkerUtils.getParaNumFromPara(paraElement);
    return {
      paraNum
    };
  },
  getTextWithContextByLocators: (store, getters) => locators => {
    const text = getters.getTextByRangeLocator(locators);
    const charsCount =
      locators.endLocator.logicalCharOffset -
      locators.startLocator.logicalCharOffset;
    if (
      locators.endLocator.paragraphId !== locators.startLocator.paragraphId ||
      charsCount > AppConstants.TYPO_MAX_SYMBOLS_FOR_NEARBY
    ) {
      return {
        text
      };
    }
    const prevLocator = new Locator.InTextLocator(
      locators.startLocator.paragraphId,
      Math.max(
        0,
        locators.startLocator.logicalCharOffset -
          AppConstants.TYPO_NEARBY_SYMBOLS
      )
    );
    const nextLocator = new Locator.InTextLocator(
      locators.endLocator.paragraphId,
      locators.endLocator.logicalCharOffset + AppConstants.TYPO_NEARBY_SYMBOLS
    );
    const prevText = _getContext(
      getters,
      prevLocator,
      locators.startLocator,
      true
    );
    const nextText = _getContext(getters, locators.endLocator, nextLocator);
    return {
      prevText,
      text,
      nextText
    };
  },
  isParagraphDisabled: state => paraId => {
    const bookItem = state;
    return get(bookItem, `contentIndex[${paraId}].disabled`, false);
  },

  getBookId: state => {
    return state.bookId;
  },

  isContentReady: state => {
    return !!get(state, 'content', []).length;
  },
  isAudioReady: (state, getters) => () => {
    const audioLinks = getters.getAudioLinks();
    return Boolean(getters.isContentReady && !isEmpty(audioLinks));
  },
  /*getLibraryData: state => state.library,
  getBooks: state => {
    return Object.values(state.library)
      .filter(pub => pub.meta.type === 'Book')
      .reduce((obj, data) => {
        obj[data.meta.id] = data;
        return obj;
      }, {});
  }*/

  isPublicationHasRtl: state => {
    const bookItems = state.content;
    return bookItems.some(bookItem => bookItem.direction === 'rtl');
  },
  contentLength: state => {
    return state.content.length;
  }
};

function _getContext(getters, startLocator, endLocator, before = false) {
  const contextRang = new Locator.InTextRangeLocator(startLocator, endLocator);
  const sliceArgs = before
    ? [-AppConstants.TYPO_NEARBY_WORDS]
    : [0, AppConstants.TYPO_NEARBY_WORDS];
  return getters
    .getTextByRangeLocator(contextRang)
    .split(' ')
    .slice(...sliceArgs)
    .join(' ');
}

async function _initBookAudio(ctx, payload) {
  const { publicationId } = payload;
  await _initAlignmentIndex(ctx, publicationId);
  await _initAudioLinks(ctx, publicationId);
  await _initPauseMap(ctx, publicationId);
}

async function _initAlignmentIndex({ commit, rootGetters }, bookId) {
  try {
    const rawAlignmentIndex = await AssetsManager.readAlignmentIndex(bookId);
    const paraRange = rootGetters['OpenParameterStore/getParagraphRange'];
    commit('setAlignmentIndex', { bookId, rawAlignmentIndex, paraRange });
  } catch (error) {
    logger.error(`Get error on load alignment error: ${error}`);
    commit('setAlignmentIndex', { bookId, rawAlignmentIndex: [] });
  }
}

function _initAudioLinks({ commit, state }, bookId) {
  let audioLinks = {};
  let promises = state.alignmentIndex.map(alignmentItem =>
    AssetsManager.getAudioSourcePath(bookId, alignmentItem.id).then(
      link => (audioLinks[alignmentItem.id] = link)
    )
  );
  return Promise.all(promises)
    .then(() => commit('addPublicationAudioLinks', { bookId, audioLinks }))
    .catch(() => {
      commit('addPublicationAudioLinks', { bookId, audioLinks: {} });
    });
}

async function _initPauseMap({ commit }, bookId) {
  try {
    const pauseMap = await AssetsManager.readerPauseMapFile(bookId);
    commit('setPauseMap', { bookId, pauseMap });
  } catch (error) {
    if (error?.type === CustomErrorEnum.EMPTY_FILE_SOURCE) {
      logger.warn(error);
    } else {
      logger.error(`Get error on load pause map error: ${error}`);
    }
    commit('setPauseMap', { bookId, pauseMap: {} });
  }
}

async function _initBookContent({ commit, rootGetters }, { publicationId }) {
  const contentIndex = await AssetsManager.readContentIndexes(publicationId);
  const paraRange = rootGetters['OpenParameterStore/getParagraphRange'];
  commit('setPublicationContentIndexById', {
    publicationId,
    contentIndex,
    paraRange
  });
}

// actions
const actions = {
  destroyPublicationState({ commit }, excludeInitProps) {
    commit('reinitBookItem', excludeInitProps);
  },
  compactStore({ commit }, compactOptions) {
    commit('compactStoreData', compactOptions);
    commit('setDataState', DataStateEnum.COMPACT);
  },
  initSearchNavigation({ commit }, payload) {
    commit('setSearchNavigation', payload);
  },
  updateSearchNavigation({ commit }, payload) {
    commit('updateSearchNavigation', payload);
  },
  resetSearchNavigation({ commit }, payload) {
    commit('resetSearchNavigation', payload);
  },
  async readMeta({ commit, getters }, bookId) {
    commit('cleanMetaState');
    const meta = await AssetsManager.readBookMetaFile(bookId);
    commit('setMetaById', {
      bookId,
      meta
    });
    return getters.getMeta(bookId);
  },
  async initPublicationAudio(ctx, { publicationId }) {
    const { commit } = ctx;
    await AssetsManager.initBookAssets(publicationId);
    commit('cleanAudioState');
    await _initBookAudio(ctx, { publicationId });
  },
  async initPublicationContent(ctx, { publicationId }) {
    const { commit } = ctx;
    await AssetsManager.initBookAssets(publicationId);

    commit('cleanContentState');
    await _initBookContent(ctx, { publicationId });
    commit('setDataState', DataStateEnum.FULL);
  },
  async saveStudyGuide(_, studyGuideData) {
    await RestService.restRequest('get', 'Publications', 'savestudyguide', {
      publicationData: JSON.stringify(studyGuideData)
    });
  },
  changeStudyGuideData({ commit }, studyGuideData) {
    commit('changeStudyGuide', studyGuideData);
  },
  getBookAlignmentByContentRequest(ctx, contentRequest) {
    return _getBookAlignmentByContentRequest(ctx, contentRequest);
  },
  async getContentByContentRequest(ctx, contentRequest) {
    const contentIndexes = await _getBookContentByContentRequest(
      ctx,
      contentRequest
    );
    return contentIndexes.map(cont => {
      return BookFactory.createContentObject(cont);
    });
  },
  async getContentByParagraphId({ dispatch, state }, { bookId, paraId }) {
    const bookItem = state;
    if (bookItem.contentIndex[paraId].paragraph === undefined) {
      const PARA_NUM = 1;
      const includeParaId = true;
      let contentRequest = ContentRequestFactory.topBottomContentRequest(
        bookId,
        paraId,
        PARA_NUM,
        includeParaId
      );

      await dispatch('getContentByContentRequest', contentRequest);
    }
    return true;
  },
  async getImagePathByBookId(_, { publicationId, src, assetType }) {
    assetType = assetType || 'image';
    await AssetsManager.initBookAssets(publicationId);
    return AssetsManager.getFileSourcePath(publicationId, src, assetType);
  },
  saveMetaData: function() {
    return Promise.resolve();
  },
  setPublicationContentDisabled: function({ commit, rootGetters }, payload) {
    const isOpenFromLink = rootGetters['OpenParameterStore/isOpenFromLink'];
    commit('setPublicationContentDisabled', { ...payload, isOpenFromLink });
  },
  setPublicationContentEnabled: function({ commit }) {
    commit('setPublicationContentEnabled');
  },
  async getAudioSourceLink({ rootGetters }, payload) {
    const { publicationId, paraId } = payload;
    const isIos15_4 = rootGetters['ContextStore/isIos15_4'];
    const isSafariVersion15_4 = rootGetters['ContextStore/isSafariVersion15_4'];
    let sourceLink = '';
    if (isIos15_4 || isSafariVersion15_4) {
      sourceLink = await AssetsManager.getAudioSourcePath(
        publicationId,
        paraId
      );
    } else {
      const blob = await AssetsManager.readAudioFile(publicationId, paraId);
      sourceLink = window.URL.createObjectURL(blob);
    }
    return sourceLink;
  }
};

function _getBookAlignmentByContentRequest({ commit, state }, contentRequest) {
  const { bookId, paraId, directionRange } = contentRequest;

  const bookItem = state;
  const paraIndex = bookItem.alignmentIndexMap[paraId];

  const correctionIndex = 1;
  const range = {
    form: Math.max(paraIndex.index + directionRange.from, 0),
    to: paraIndex.index + directionRange.to + correctionIndex
  };

  const alignmentIndexes = bookItem.alignmentIndex.slice(range.form, range.to);
  const chunkOffset = _createChunkOffset(alignmentIndexes);

  return AssetsManager.bookAlignmentByChunk(bookId, chunkOffset).then(
    function normalizeChunk(alignmentChunk) {
      commit('addPublicationAlignmentFromChunk', {
        bookId,
        alignmentChunk
      });
      return bookItem.alignment;
    }
  );
}

function _getBookContentByContentRequest({ commit, state }, contentRequest) {
  const { bookId, paraId, directionRange } = contentRequest;
  const reqParaId = MarkerUtils.prefixedParaIdToLocator(paraId);

  const InTextRangeLocator = Locator.deserialize(reqParaId);

  const bookItem = state;
  const lastParaId = last(bookItem.content).id;

  const paraIndex = _getClosestParaIndex(
    bookItem.contentIndex,
    InTextRangeLocator.startLocator.prefixedParagraphId,
    lastParaId
  );

  const contentRange = {
    form: Math.max(paraIndex.index + directionRange.from, 0),
    to: paraIndex.index + directionRange.to
  };

  let contentIndexes = bookItem.content.slice(
    contentRange.form,
    contentRange.to
  );

  const emptyContentParts = [];
  let emptyContentIndexes = [];
  contentIndexes.forEach(contentIndex => {
    if (!contentIndex.hasContent) {
      emptyContentIndexes.push(contentIndex);
    } else if (emptyContentIndexes.length) {
      emptyContentParts.push(emptyContentIndexes);
      emptyContentIndexes = [];
    }
  });
  if (emptyContentIndexes.length) {
    emptyContentParts.push(emptyContentIndexes);
  }

  if (emptyContentParts.length === 0) {
    return Promise.resolve([]);
  }
  const loadContentPromises = emptyContentParts.map(emptyContentPart => {
    const range = {
      form: emptyContentPart[0].index,
      to: emptyContentPart[emptyContentPart.length - 1].index + 1
    };

    const chunkOffset = _createChunkOffset(emptyContentPart);

    return AssetsManager.bookContentByChunk(bookId, chunkOffset).then(
      function normalizeChunk(contentChunk) {
        commit('addPublicationContentFromChunk', {
          bookId,
          contentRange: range,
          contentChunk
        });
        return emptyContentPart;
      }
    );
  });

  return Promise.all(loadContentPromises);
}

function _getClosestParaIndex(contentIndex, paraId, lastParaId) {
  const closestParaIndex = contentIndex[paraId];
  if (closestParaIndex) {
    return closestParaIndex;
  }

  let currentParaNum = MarkerUtils.getParaNum(paraId);
  const lastParaNum = MarkerUtils.getParaNum(lastParaId);
  if (currentParaNum > lastParaNum) {
    return contentIndex[lastParaId];
  }

  // eslint-disable-next-line no-constant-condition
  while (true) {
    currentParaNum += 1;
    const nextParaId = 'para_' + currentParaNum;
    const nextContentIndex = contentIndex[nextParaId];
    if (nextContentIndex) {
      return nextContentIndex;
    }
  }
}

function _createChunkOffset(fileIndexes) {
  const start = fileIndexes[0].start;
  let end = start;
  fileIndexes.forEach(function(fileIndex) {
    end += fileIndex.offset;
  });

  const chunkOffset = {
    start,
    end
  };
  return chunkOffset;
}

function _convertToContentIndexFormat(alignmentIndex, itemIndex) {
  return {
    id: alignmentIndex.id,
    index: itemIndex,
    offset: alignmentIndex.offset[1],
    paraNum: null,
    start: alignmentIndex.offset[0],
    words: null
  };
}

// function _addExtraFields(content, meta) {
//   let previousChapterName = meta.toc.length === 1 ? meta.toc[0].text : '';
//   let wordsBefore = 0;
//   const paragraphIds = Object.keys(content);
//   let processedParagraphs = {};
//   paragraphIds.map(paraId => {
//     const para = content[paraId];
//     let chapterName, chapter, isChapter, position;
//     chapter = find(meta.toc, { id: paraId });
//     isChapter = meta.toc.length === 1 ? false : Boolean(chapter);
//     chapterName = isChapter ? chapter.text : previousChapterName;
//     position = Math.round(((wordsBefore * 100) / meta.wordsCount) * 100) / 100;
//     Object.assign(para, {
//       chapterName,
//       isChapter,
//       position
//     });
//     previousChapterName = chapterName;
//     wordsBefore += para.words;
//     processedParagraphs[paraId] = para;
//   });
//   return processedParagraphs;
// }

function _applyNewStateByKeys(state, newState, reinitKeys) {
  Object.keys(reinitKeys).forEach(key => {
    if (!newState[key]) {
      return;
    }
    state[key] = newState[key];
  });
}

// mutations
const mutations = {
  compactStoreData(state, { contentStatIndex, contentEndIndex }) {
    for (let i = state.content.length - 1; i >= 0; i--) {
      const contentItem = state.content[i];
      const paraId = contentItem.id;
      const alignmentItem = state.alignmentIndexMap[paraId];

      const isInsideAllowedRange = i > contentStatIndex && i < contentEndIndex;

      if (!isInsideAllowedRange) {
        state.content.splice(i, 1);
        delete state.contentIndex[paraId];
      }
      if (!isInsideAllowedRange && alignmentItem) {
        state.alignmentIndex.splice(alignmentItem.index, 1);
        delete state.alignmentIndexMap[paraId];
        delete state.alignment[paraId];
        delete state.audioLinks[paraId];
      }
    }
  },
  setDataState(state, dataState) {
    state.dataState = dataState;
  },
  setBookId(state, bookId) {
    state.bookId = bookId;
  },
  reinitBookItem(state, excludeInitProps = []) {
    const newSate = _createBookItem();
    Object.keys(state).forEach(key => {
      if (excludeInitProps.includes(key)) {
        return;
      }
      state[key] = newSate[key];
    });
  },
  cleanAudioState(state) {
    const newState = _createBookItem();
    const reinitKeys = [
      'alignmentIndexMap',
      'alignmentIndex',
      'alignment',
      'audioLinks'
    ];
    _applyNewStateByKeys(state, newState, reinitKeys);
  },
  cleanContentState(state) {
    const newState = _createBookItem();
    const reinitKeys = ['contentIndex', 'content'];
    _applyNewStateByKeys(state, newState, reinitKeys);
  },
  cleanMetaState(state) {
    const newState = _createBookItem();
    const reinitKeys = ['meta'];
    _applyNewStateByKeys(state, newState, reinitKeys);
  },
  setOpenParams(state, payload) {
    const { openParams } = payload;
    state.openParams = openParams;
  },
  setSearchNavigation(state, payload) {
    const { bookId, searchNavigation } = payload;

    state.searchNavigation = SearchNavigationFactory.createSearchNavigation(
      searchNavigation
    ).setBookId(bookId);
  },
  updateSearchNavigation(state, payload) {
    const { searchNavigation } = payload;

    state.searchNavigation.setNavigateIndex(searchNavigation.navigateIndex);
    state.searchNavigation.setDocId(searchNavigation.docId);
  },
  resetSearchNavigation(state) {
    state.searchNavigation = SearchNavigationFactory.createEmptySearchNavigation();
  },
  setMetaById(state, payload) {
    const { meta } = payload;
    state.meta = BookFactory.createMeta(meta);
  },

  setAlignmentIndex(state, { rawAlignmentIndex, paraRange }) {
    const alignmentIndexMap = {};

    const startParaNum = MarkerUtils.getParaNum(paraRange?.start || '') || 0;
    const endParaNum = MarkerUtils.getParaNum(paraRange?.end || '') || Infinity;

    let itemIndex = 0;
    rawAlignmentIndex.forEach(function(alignmentIndex) {
      const paraId = alignmentIndex.id;
      const paraNum = MarkerUtils.getParaNum(paraId) || 0;
      const insideRange = paraNum >= startParaNum && paraNum < endParaNum;
      if (!insideRange) {
        return;
      }
      alignmentIndexMap[paraId] = _convertToContentIndexFormat(
        alignmentIndex,
        itemIndex
      );
      itemIndex += 1;
    });
    state.alignmentIndexMap = alignmentIndexMap;
    state.alignmentIndex = Object.values(alignmentIndexMap);
  },

  setPauseMap(state, { pauseMap }) {
    state.pauseMap = pauseMap;
  },

  setPublicationContentIndexById(state, payload) {
    let { contentIndex, paraRange } = payload;
    const content = Object.values(contentIndex);

    const startIndex = contentIndex[paraRange?.start]?.index || 0;
    const endIndex = contentIndex[paraRange?.end]?.index || content.length;

    const endPart = content.splice(endIndex);
    const startPart = content.splice(0, startIndex);
    if (endPart.length !== 0 || startPart.length !== 0) {
      contentIndex = {};
      content.forEach((indexItem, index) => {
        indexItem.index = index;
        contentIndex[indexItem.id] = indexItem;
      });
    }

    // for very large books this object can be really huge, so we need to tell Vue to not make this property reactive
    state.contentIndex = process.client
      ? Object.freeze(contentIndex)
      : contentIndex;
    state.content = content;
  },

  addPublicationContentFromChunk(state, payload) {
    const { contentRange, contentChunk } = payload;
    const bookItem = state;

    const contentIndexes = bookItem.content.slice(
      contentRange.form,
      contentRange.to
    );
    const indexStart = contentIndexes[0].start;
    const defaultDirection = Utils.getDirection(bookItem.meta.language);
    const lastContentIndex = bookItem.content.length - 1;
    contentIndexes.forEach(function(contentIndex) {
      const start = contentIndex.start - indexStart;
      const end = start + contentIndex.offset;
      const paraChunk = contentChunk.slice(start, end);
      const paragraph = Utils.utf8ArrayToStr(paraChunk);
      const direction = contentIndex.language
        ? Utils.getDirection(contentIndex.language)
        : defaultDirection;
      contentIndex.direction = direction;
      contentIndex.paragraph = paragraph;
      contentIndex.hasContent = Boolean(paragraph);
      if (contentIndex.paragraph.length === 0 && end - start > 0) {
        const error = new Error(
          `Get unexpected empty paragraph ${contentIndex.id}, in book ${state.bookId} read by range [${contentIndex.start},${contentIndex.offset}]`
        );
        error.type = BookScrollEnum.ERROR_EMPTY_CONTENT;
        throw error;
      }
      contentIndex.isFirst = bookItem.content[0].id === contentIndex.id;
      contentIndex.isLast =
        bookItem.content[lastContentIndex].id === contentIndex.id;
    });
  },

  changeStudyGuide(state, studyGuideData) {
    Object.keys(studyGuideData).forEach(key => {
      state.meta[key] = studyGuideData[key];
    });
    RestService.restRequest('get', 'Publications', 'savestudyguide', {
      publicationData: JSON.stringify(studyGuideData)
    });
  },

  addPublicationAlignmentFromChunk(state, payload) {
    const { alignmentChunk } = payload;
    const alignment = Utils.parseRawIndex(alignmentChunk);
    state.alignment = { ...state.alignment, ...alignment };

    // Object.assign(state.alignment, alignment);
    // const newState = Object.assign({}, state);
    // Vue.set(state, newState);
  },

  addPublicationAudioLinks(state, payload) {
    const { audioLinks } = payload;
    state.audioLinks = audioLinks;
    // state = Object.assign({}, state);
  },

  setPublicationContentDisabled(state, payload) {
    const { startIndex, isOpenFromLink } = payload;

    if (isOpenFromLink) {
      let enabledParaCountAfter = AppConstants.DEFAULT_ENABLED_PARAGRAPHS_STEP;
      for (let i = startIndex; i < state.content.length; i++) {
        let item = state.content[i];
        let isIllustration =
          item.paragraph && item.paragraph.includes('ilm-illustration');
        if (isIllustration) {
          enabledParaCountAfter++;
          continue;
        }
        item.disabled = item.index > startIndex + enabledParaCountAfter;
      }

      let enabledParaCountBefore = AppConstants.DEFAULT_ENABLED_PARAGRAPHS_STEP;
      for (let i = startIndex - 1; i >= 0; i--) {
        let item = state.content[i];
        let isIllustration =
          item.paragraph && item.paragraph.includes('ilm-illustration');
        if (isIllustration) {
          enabledParaCountBefore++;
          continue;
        }
        item.disabled = item.index < startIndex - enabledParaCountBefore;
      }
    } else {
      let enabledParaCount = AppConstants.DEFAULT_ENABLED_PARAGRAPHS;
      state.content.forEach(item => {
        let isIllustration =
          item.paragraph && item.paragraph.includes('ilm-illustration');
        if (isIllustration) {
          enabledParaCount++;
          return;
        }
        item.disabled = item.index >= enabledParaCount;
      });
    }
  },

  setPublicationContentEnabled(state) {
    state.content.forEach(item => (item.disabled = false));
  }
  /*  setLibraryData(state, storeData) {
    state.library = storeData;
  }*/
};

export default {
  name: 'BookStore',
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
