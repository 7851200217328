import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('UserService.js');

import UnifiedSettingsService from './UnifiedSettingsService';
import RestService from './RestService';
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import ApplicationContext from './../context/ApplicationContext';
import AgentService from '@/services/Agent/AgentService';
import ClientNodeContext from '@/context/ClientNodeContext';
import UserStatusesEnum from '@/enums/UserStatusesEnum';

import has from 'lodash/has';
import Utils from './utils/Utils';
import AuthTypeEnum from '@/enums/AuthTypeEnum';

let Context = {};
// let user = {};

const TRACKING = 'tracking';
const GUEST_GROUP = 'guest_session';

function init(context) {
  Context = context;
}

function initAgentUser() {
  try {
    const sessionGroup = UnifiedSettingsService.getGroup('session');
    if (!sessionGroup || !sessionGroup.get('SID')) {
      return;
    }
    const user = sessionGroup.get('profile');
    return AgentService.setCredentials(user);
  } catch (err) {
    logger.fatal(`Error while initializing User service. Error: ${err}`);
  }
}

function registration(registrationInfo) {
  const guestId = UnifiedSettingsService.getSetting(
    GUEST_GROUP,
    'guest_userId'
  );
  if (guestId && guestId.length) {
    registrationInfo.userId = guestId;
  }
  return RestService.restRequest(
    'post',
    'Users',
    'userregistration',
    registrationInfo
  ).then(
    function(result) {
      if (result.data.status === 'OK') {
        UnifiedSettingsService.setSetting(GUEST_GROUP, 'guest_userId', null);
        const userEmail = registrationInfo.email;
        const taskType = 'RegisterUserProfile';
        let password;
        return registerEmailAuthorizedTask(userEmail, taskType, password);
      } else {
        return Promise.reject(result);
      }
    },
    function(reason) {
      return Promise.reject(reason);
    }
  );
}

function registerEmailAuthorizedTask(userEmail, taskType, password, uid) {
  const applicationUrl = ApplicationContext.applicationReaderUrl;
  const registerParams = {
    userEmail,
    taskType,
    applicationUrl,
    uid,
    password,
    isDevice: false
  };
  return RestService.restRequest(
    'post',
    'Users',
    'registeremail',
    registerParams
  );
}

function resetPasswordTask(email) {
  return registerEmailAuthorizedTask(email, 'ResetPassword');
}

/**
 *
 * @param {*} provider
 */
function getOAuthURI(provider, returnUri) {
  const serverUrl = Context.serverUrl,
    oauthPath = Context.parameters.EpubConfig.oauthPath;

  returnUri = returnUri || window.location.href;

  return (
    serverUrl +
    oauthPath +
    (oauthPath.indexOf('?') > -1 ? '&' : '?') +
    ['provider=' + provider, 'returnURI=' + encodeURIComponent(returnUri)].join(
      '&'
    )
  );
}

/**
 *
 * @param {*} userInfo
 * @param {*} authType
 * @returns {Promise}
 */
function authenticate(userInfo, authType, promoCode) {
  const configInfo = Context.parameters;
  let authResult = { status: '', message: '' };

  userInfo.configInfo = {
    promoCode,
    environmentSuffix: configInfo.environmentSuffix || '',
    environmentPrefix: configInfo.environmentPrefix || '',
    buildID: configInfo.buildID || '',
    revision: configInfo.revision || '',
    processorCommit: configInfo.rrmProcessor
      ? configInfo.rrmProcessor.commitID
      : '',
    sourcesCommit: configInfo.rrmOcean ? configInfo.rrmOcean.commitID : '',
    appCommit: configInfo.reader ? configInfo.reader.commitID : '',
    isDevice: ClientNodeContext.isDevice
  };
  return RestService.restRequest('post', 'Session', authType, userInfo)
    .then(function(response) {
      if (response && response.data && response.data.sessionId) {
        const user = response.data.user;
        authResult.status =
          user.active === UserStatusesEnum.APPROVED ||
          (Context.parameters.userManagementSettings &&
            Context.parameters.userManagementSettings.accessNotAutorizedUser &&
            user.active === UserStatusesEnum.UNKNOWN)
            ? 'loggedIn'
            : 'pendingLogIn';
        authResult.user = user;
        authResult.runId = response.data.runId;
        authResult.sessionId = response.data.sessionId;
        authResult.isRegistered = response.data.isRegistered;
        authResult.isNewUser = response.data.isNewUser;
        return onAuth(response.data.sessionId, user, response.data.runId).then(
          function() {
            if (authType === 'skiplogin') {
              return authResult;
            }

            const isAfterRegistrationStep =
              response.data.isNewUser || authType === AuthTypeEnum.HASHCODE;

            let action = isAfterRegistrationStep ? 'SignUp' : 'Login';
            let provider = userInfo.state || 'email';
            provider = provider[0].toUpperCase() + provider.slice(1);
            GoogleAnalyticsUtils.trackEvent(
              action,
              'auth',
              `${provider}_${action}`
            );

            return authResult;
          }
        );
      } else {
        authResult.status = 'error';
        authResult.message = response.text;
      }

      Promise.resolve(authResult);
    })
    .catch(function() {
      logger.error('Error authenticating user');
      authResult.status = 'error';

      Promise.resolve(authResult);
    });
}

function skipLogin() {
  let userId = UnifiedSettingsService.getSetting(GUEST_GROUP, 'guest_userId');
  const inviterUid = UnifiedSettingsService.getSetting(TRACKING, 'inviterUid');
  const promoCode = UnifiedSettingsService.getSetting(
    TRACKING,
    'trackedPromoCode'
  );

  if (inviterUid) {
    UnifiedSettingsService.setSetting(TRACKING, 'inviterUid', null);
  }
  if (promoCode) {
    UnifiedSettingsService.setSetting(TRACKING, 'trackedPromoCode', null);
  }

  if (!userId) {
    userId = Utils.uuid();
    UnifiedSettingsService.setSetting(GUEST_GROUP, 'guest_userId', userId);
  }

  GoogleAnalyticsUtils.trackCampaign();
  return authenticate({ userId, inviterUid }, 'skiplogin', promoCode);
}

async function onAuth(_sid, _user, runId) {
  if (!_sid || !_user || !runId || !has(_user, 'userId')) {
    return;
  }
  const user = _user;

  const sessionGroup = UnifiedSettingsService.getGroup('session');
  sessionGroup.set('profile', user);
  sessionGroup.set('SID', _sid);
  sessionGroup.set('runId', runId);
  sessionGroup.save();

  if (user.sync) {
    return AgentService.setCredentials(user, { startWithInitData: true });
  }
}

function confirmAuthorizedTask(taskConfirmationHashCode, confirm) {
  const confirmParams = {
    taskConfirmationHashCode: taskConfirmationHashCode,
    confirm: confirm
  };
  return RestService.restRequest('post', 'Users', 'confirm', confirmParams);
}

function resetPassword(password, taskConfirmationHashCode) {
  const resetParams = {
    password: password,
    taskConfirmationHashCode: taskConfirmationHashCode
  };
  return RestService.restRequest('post', 'Users', 'resetpassword', resetParams);
}

function deleteSession(sid) {
  return RestService.restRequest('delete', 'Session', { SID: sid });
}

async function logout(isOnline = true, isGuest) {
  const sessionGroup = UnifiedSettingsService.getGroup('session');
  const sid = sessionGroup.get('SID');
  try {
    await deleteSession(sid);
  } catch (error) {
    if (isOnline) {
      logger.error(error);
      throw error;
    }
    logger.warn(error);
  }
  sessionGroup.set('SID', null);
  sessionGroup.set('profile', null);
  sessionGroup.set('runId', null);
  sessionGroup.set('subscription', null);
  sessionGroup.save();
  return AgentService.logout(isGuest);
}

function getUserPhoto(fileId) {
  let url = RestService.getUrlString('Users', 'getFile').split('?RunId=')[0];
  url += '?fileId=' + fileId;
  return url;
}

function persistUserProfile(userData) {
  userData.applicationUrl = `${ApplicationContext.applicationReaderUrl}#/`;
  return RestService.restRequest(
    'post',
    'Users',
    'persistuserprofile',
    userData
  );
}

async function searchUsers(params) {
  const response = await RestService.restRequest(
    'get',
    'Users',
    'searchWithFilters',
    params
  );
  const data = response.data || {};
  return data?.users ? data : { totalUsers: 0, users: [] };
}

async function collectUsersStatistics(filters) {
  const response = await RestService.restRequest(
    'get',
    'Users',
    'collectUsersStatistics',
    filters
  );
  return response.data;
}

function getUserProfileById(userId) {
  return RestService.restRequest('get', 'Users', 'profile', {
    id: userId
  }).then(response => {
    return response.data || {};
  });
}

function checkSessionByRunId() {
  return RestService.restRequest('get', 'Session', 'checkSession', null, {
    timeout: 30 * 1000
  });
}

async function deleteAccount(delay) {
  const result = await RestService.restRequest(
    'delete',
    'Users',
    'deleteAccount',
    { delay }
  );
  const errorMessage = result?.data?.message;
  if (errorMessage) {
    throw new Error(errorMessage);
  }
  return result;
}

function cancelDeleteAccount() {
  return RestService.restRequest('post', 'Users', 'cancelDeleteAccount');
}

export default {
  init,
  initAgentUser,
  registration,
  getOAuthURI,
  authenticate,
  confirmAuthorizedTask,
  resetPasswordTask,
  resetPassword,
  logout,
  onAuth,
  skipLogin,
  getUserPhoto,
  collectUsersStatistics,
  persistUserProfile,
  searchUsers,
  getUserProfileById,
  checkSessionByRunId,
  deleteAccount,
  cancelDeleteAccount
};
