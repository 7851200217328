<template>
  <div class="base-multi-select">
    <div class="select-title mb-1">
      <label>
        <span> {{ $t('NoteHashtags.label') }}</span>
      </label>
      <div class="counter">
        {{ selectedItems.length }}/{{ maxSelectedItems }}
      </div>
    </div>
    <v-combobox
      ref="multiselect"
      v-model="selectedItems"
      :items="selectOptions"
      item-text="name"
      class="multi-select"
      :label="placeholder"
      hide-details
      chips
      multiple
      hide-selected
      no-filter
      :search-input.sync="search"
      :menu-props="{
        top: true,
        bottom: false,
        offsetY: true,
        contentClass: 'base-multi-select-dropdown'
      }"
    >
      <template #selection="{ item }">
        <v-chip label small>
          <span class="item-name">
            {{ item.name }}
          </span>
          <div class="icon-container" @click="removeItemFromSelected(item)">
            <BaseSpriteIcon icon-name="ico-close" />
          </div>
        </v-chip>
      </template>

      <template #item="{ item }">
        <div :class="item.class">
          <BaseSpriteIcon v-if="item.icon" :icon-name="item.icon" />
          <span class="item-name">
            {{ item.name }}
            <span v-if="item.hasSuffix">{{ newTagSuffix }}</span>
          </span>
        </div>
      </template>

      <template #no-data>
        <v-list-item
          v-if="selectOptions.length === 0"
          :class="notActiveOptionClass"
        >
          <div class="info-container">
            <BaseSpriteIcon icon-name="ico-info" />
            <span>{{ getNoOptionsMessage }}</span>
          </div>
        </v-list-item>
        <template v-else>
          <v-list-item
            v-for="item in selectOptions"
            :key="item.id"
            :class="item.class"
            @click="addItemToSelected(item)"
          >
            <BaseSpriteIcon v-if="item.icon" :icon-name="item.icon" />
            <span>{{ item.name }} </span>
          </v-list-item>
        </template>
      </template>
    </v-combobox>
  </div>
</template>
<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'BaseMultiSelect',
  components: {
    BaseSpriteIcon
  },
  props: {
    inputMaxLength: {
      type: Number,
      default: 0
    },
    maxSelectedItems: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedItems: [],
      selectOptions: [...this.options],
      search: null,
      newTagSuffix: this.$t(`NoteHashtags.multiselect.newtag.suffix`),
      notActiveOptionClass: 'not-active-option'
    };
  },
  computed: {
    getNoOptionsMessage() {
      return this.firstTimeTagCreation
        ? this.$t(`NoteHashtags.multiselect.info.firstTime`)
        : this.$t(`NoteHashtags.multiselect.info.new`);
    },
    getEditingAnnotationShowTagIcon() {
      return this.$store.getters[
        'AnnotationsStore/getEditingAnnotationShowTagIcon'
      ];
    },
    firstTimeTagCreation() {
      if (this.selectedItems.length) {
        return false;
      }
      const isItemsExist = this.options.length > 0;
      const isInputValueExist = !!this.search;
      return !isItemsExist && !isInputValueExist;
    }
  },
  watch: {
    selectedItems(val) {
      const selectedItemsLength = val.length;
      this.changeEditingAnnotationShowTagIcon(!!selectedItemsLength);
      const lastAddedEl = val[selectedItemsLength - 1];

      if (selectedItemsLength) {
        if (typeof lastAddedEl === 'string') {
          val[selectedItemsLength - 1] = {
            name: lastAddedEl.trim()
          };
        } else if (lastAddedEl.hasOwnProperty('class')) {
          if (lastAddedEl.class.includes(this.notActiveOptionClass)) {
            val.length = this.maxSelectedItems;
            this.search = null;
            return;
          }
          val[selectedItemsLength - 1].name = this.$_getStringWithoutSuffix(
            lastAddedEl.name
          );
        }

        const isDuplicate = val
          .slice(0, -1)
          .some(item => item.name === val[selectedItemsLength - 1].name.trim());
        if (isDuplicate) {
          val.pop();
        }
      }

      const isMaxItemsSelected = selectedItemsLength === this.maxSelectedItems;
      this.setInputMaxlength(isMaxItemsSelected ? 0 : this.inputMaxLength);

      if (isMaxItemsSelected) {
        this.displayInOptionsLimitInfoMessage();
        return;
      }

      this.$_filterOptionsToRemoveSelected(lastAddedEl);
      this.search = null;
    },
    search(inputVal) {
      if (inputVal === null) {
        return;
      }

      const selectedItemNames = new Set(
        this.selectedItems.map(item => item.name)
      );
      this.selectOptions = this.options.filter(
        item =>
          this.filterByStartsWithInputVal(item, inputVal) &&
          !selectedItemNames.has(item.name)
      );

      if (inputVal === '') {
        return;
      }

      const newItemData = this.$_getNewTagObject();
      this.selectOptions.push(newItemData);
    }
  },
  mounted() {
    this.selectedItems = [...this.items];
    this.setInputMaxlength(this.inputMaxLength);
  },
  methods: {
    $_getNewTagObject(isNoOptions) {
      let className = `new-hashtag ${this.$_getClassIfNoOptions(isNoOptions)}`;
      const name = this.search.trim();
      const hasSuffix = true;
      return { name, class: className, hasSuffix };
    },
    $_getClassIfNoOptions(isNoOptions) {
      return isNoOptions || !this.selectOptions?.length ? 'no-options' : '';
    },
    $_filterOptionsToRemoveSelected(addedEl) {
      if (!addedEl) {
        return;
      }
      this.selectOptions = this.options.filter(
        option =>
          !this.selectedItems.some(selected => selected.name === option.name)
      );
    },
    setInputMaxlength(maxLength) {
      const comboboxElement = this.$refs.multiselect.$el;
      const inputElement = comboboxElement.querySelector('input');
      if (inputElement) {
        inputElement.setAttribute('maxlength', `${maxLength}`);
      }
    },
    filterByStartsWithInputVal(item, inputVal) {
      if (!item?.name || inputVal === null) {
        return false;
      }
      const itemTextLower = item.name.toLowerCase();
      const inputValLower = inputVal.toLowerCase();
      return itemTextLower.startsWith(inputValLower);
    },
    removeItemFromSelected(item) {
      this.selectedItems = this.selectedItems.filter(
        selectedItem => selectedItem.name !== item.name
      );

      this.$_addItemToOptionsWithSavingOrder(item);
    },
    $_addItemToOptionsWithSavingOrder(newItem) {
      const insertIndex = this.selectOptions.findIndex(
        option => option.name.localeCompare(newItem.name) > 0
      );

      if (insertIndex === -1) {
        this.selectOptions.push(newItem);
      } else {
        this.selectOptions.splice(insertIndex, 0, newItem);
      }
    },
    changeEditingAnnotationShowTagIcon(showIcon) {
      if (this.getEditingAnnotationShowTagIcon === showIcon) {
        return;
      }
      this.$store.dispatch(
        'AnnotationsStore/changeEditingAnnotationShowTagIcon',
        showIcon
      );
    },
    addItemToSelected(item) {
      if (item?.class?.includes(this.notActiveOptionClass)) {
        return;
      }
      this.selectedItems.push(item);
    },
    $_getStringWithoutSuffix(string) {
      return string.trim();
    },
    displayInOptionsLimitInfoMessage() {
      this.selectOptions = [
        {
          name: this.$t(`NoteHashtags.multiselect.limit`, {
            maxSelectedItems: this.maxSelectedItems
          }),
          icon: 'ico-info',
          class: `${this.notActiveOptionClass} info-container`
        }
      ];
    }
  }
};
</script>
<style lang="less" src="./BaseMultiSelect.less"></style>
