import Vue from 'vue';

import ReadingSettingsService from '@/services/ReadingSettingsService';
import ReadingSettingsEnum from '@/enums/ReadingSettingsEnum';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';
import GoogleAnalyticsUtils from '@/services/utils/GoogleAnalyticsUtils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import Utils from '@/services/utils/Utils';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ReadingSettingsStore.js');

const initState = () => ({
  readingSettingsView: {},
  allFonts: {},
  canShowMarginNotes: true,
  displayBlockRules: {
    fonts: true,
    fontSize: true,
    lineSpacing: true,
    themes: true,
    audioSpeed: true,
    expandedMarginNotes: true,
    expandedFootnotes: true,
    autoPlayNotes: true,
    underlineMode: true,
    googleTranslate: true,
    dictionaryMode: true,
    wikiMode: true,
    dictionaryAutoOpen: true,
    readingPositionTracking: true,
    fixedPageMode: true,
    floatingPageMode: true
  },
  updatingSettings: []
});

const storeGetters = {
  getIsSettingsIsUpdating: state => settingsName => {
    return state.updatingSettings.includes(settingsName);
  },
  getScrollingMode: state => {
    return state.readingSettingsView.scrollingMode;
  },
  getIsPageInDefaultScrollingMode: state => {
    return (
      state.readingSettingsView.scrollingMode ===
      AppConstantsUtil.DEFAULT_SCROLLING_MOD
    );
  },
  getReadingSettingsView: state => {
    return state.readingSettingsView;
  },
  getHighlightMode: state => {
    return state.readingSettingsView.highlightMode;
  },
  getReadingPositionTracking: state => {
    return state.readingSettingsView.readingPositionTracking;
  },
  getExpandedNotesMode: state => {
    return (
      state.readingSettingsView.expandedNotesMode && state.canShowMarginNotes
    );
  },
  getFontName: state => {
    return state.readingSettingsView.font.name;
  },
  getFontSize: state => {
    return state.readingSettingsView.fontSize;
  },
  getIsMaxFontSize: state => {
    return (
      state.readingSettingsView.fontSize === AppConstantsUtil.MAX_FONT_SIZE
    );
  },
  getIsMinFontSize: state => {
    return (
      state.readingSettingsView.fontSize === AppConstantsUtil.MIN_FONT_SIZE
    );
  },
  getAudioSpeedStep: state => {
    return state.readingSettingsView.audioSpeedStep;
  },
  getIsMinAudioSpeed: state => {
    return (
      state.readingSettingsView.audioSpeed ===
      state.readingSettingsView.minAudioSpeed
    );
  },
  getIsMaxAudioSpeed: state => {
    return (
      state.readingSettingsView.audioSpeed ===
      state.readingSettingsView.maxAudioSpeed
    );
  },
  getLineSpacing: state => {
    return state.readingSettingsView.lineSpacing;
  },
  getIsMinLineSpacing: (state, getters, rootState, rootGetters) => {
    const lang = rootGetters['PublicationStore/getBookLanguage'];
    const hasRtl = rootGetters['PublicationStore/isPublicationHasRtl'];
    let minLineSpacing = AppConstantsUtil.MIN_LINE_SPACING;
    if (Utils.isRtl(lang) || hasRtl) {
      minLineSpacing += AppConstantsUtil.RTL_SHIFT_LINE_SPACING;
    }
    return state.readingSettingsView.lineSpacing === minLineSpacing;
  },
  getIsMaxLineSpacing: (state, getters, rootState, rootGetters) => {
    const lang = rootGetters['PublicationStore/getBookLanguage'];
    const hasRtl = rootGetters['PublicationStore/isPublicationHasRtl'];
    let maxLineSpacing = AppConstantsUtil.MAX_LINE_SPACING;
    if (Utils.isRtl(lang) || hasRtl) {
      maxLineSpacing += AppConstantsUtil.RTL_SHIFT_LINE_SPACING;
    }
    return state.readingSettingsView.lineSpacing === maxLineSpacing;
  },
  getDisplayRules: state => {
    return state.displayBlockRules;
  },
  getPlaybackRate: state => {
    const audioSpeed = state.readingSettingsView.audioSpeed;
    const defaultSpeed = state.readingSettingsView.defaultAudioSpeed;
    return ReadingSettingsService.getPlaybackRate(audioSpeed, defaultSpeed);
  },
  getWrappingReadingSettingsStyles: state => {
    const settings = state.readingSettingsView;
    if (!settings.font) {
      return [];
    }
    return [settings.font.styles];
  },
  getReadingSettingsStyles: state => {
    const settings = state.readingSettingsView;
    if (!settings.font) {
      return {};
    }
    return {
      'line-height': settings.lineSpacing + '%',
      'font-size': settings.fontSize + '%',
      'font-family': settings.font.name
    };
  },
  getThemeName: state => {
    return state.readingSettingsView.themeName;
  },
  getLocalStoredThemeName: state => {
    return ReadingSettingsService.getLocalStoredThemeName(state);
  },
  isNightTheme: state => {
    return state.readingSettingsView.themeName === ThemeClassNamesEnum.NIGHT;
  }
};

const actions = {
  initDefaultPublicationSettings(
    { dispatch, rootGetters },
    { publicationId, scrollingMode = AppConstantsUtil.DEFAULT_SCROLLING_MOD }
  ) {
    dispatch('setBookFonts');
    const pubId =
      publicationId || rootGetters['OpenParameterStore/getPublicationId'];
    if (!pubId) {
      logger.error(
        `Did not find publication id to set default reading settings`
      );
      return;
    }
    const audioSpeed = AppConstantsUtil.DEFAULT_AUDIO_SPEED;
    const highlightMode = AppConstantsUtil.DEFAULT_HIGHLIGH_MOD;
    const lang = 'en';
    dispatch('initReadingSettings', {
      bookId: pubId,
      audioSpeed,
      highlightMode,
      scrollingMode,
      lang
    });
  },
  setBookFonts({ dispatch, rootGetters }) {
    const Context = rootGetters['ContextStore/contextGetter'];
    dispatch('setFonts', Context.parameters.fonts);
  },
  resetReadingSettings({ commit, state }) {
    try {
      if (
        state.readingSettingsView.hasOwnProperty('isSettingsExist') &&
        ReadingSettingsService.isSettingsInstance(state.readingSettingsView)
      ) {
        commit('setExpandedNotesMode', false);
      }
    } catch (error) {
      logger.error(`Get error on reset reading settings error: ${error}`);
    }
  },
  initReadingSettings(
    { commit, state, rootGetters },
    { lang, audioSpeed, highlightMode, scrollingMode, bookId }
  ) {
    const hasRtl = rootGetters['PublicationStore/isPublicationHasRtl'];
    const bookCategory = rootGetters['BookStore/getBookCategory'];
    const categoriesSettings =
      rootGetters['ContextStore/getCategoriesSettings'];
    const currentCategorySetting = categoriesSettings[bookCategory] || null;

    const payload = {
      bookId,
      lang,
      defaultSpeed: audioSpeed,
      defaultHighlightMode: highlightMode,
      defaultScrollingMode: scrollingMode,
      allFonts: state.allFonts.customs,
      hasRtl,
      currentCategorySetting
    };
    const settingsFromLocalStorage = ReadingSettingsService.getReadingSettings(
      payload
    );
    commit('setReadingSettings', settingsFromLocalStorage);
  },
  clearSelection() {},
  clearReadingSettings({ commit }) {
    commit('clearReadingSettings');
  },
  setFonts({ commit }, fonts) {
    commit('setFonts', fonts);
  },
  async setFont({ commit }, font) {
    try {
      commit('setFont', font);
      GoogleAnalyticsUtils.trackEvent('readerSettings', 'setFont', font);
      await ReadingSettingsService.setFontName(font.name);
    } catch (err) {
      logger.error(`Error while setting font: ${err}`);
      commit('changeDisplayBlockRule', 'fonts', false);
    }
  },
  async setReadingThemeName({ commit, dispatch }, themeName) {
    try {
      commit('setReadingThemeName', themeName);
      GoogleAnalyticsUtils.trackEvent(
        'readerSettings',
        'setReaderTheme',
        themeName
      );
      await ReadingSettingsService.setReadingThemeName(themeName);
      const isDarkMode = themeName === ThemeClassNamesEnum.NIGHT;
      dispatch('ContextStore/setVuetifyDarkMode', isDarkMode, { root: true });
      Vue.prototype.$nuxt.$vuetify.theme.dark = isDarkMode;
    } catch (err) {
      logger.error(`Error while setting theme: ${err}`);
      commit('changeDisplayBlockRule', 'themes', false);
    }
  },
  async setAudioSpeed({ commit }, { bookId, audioSpeed }) {
    try {
      commit('setAudioSpeed', { audioSpeed });
      GoogleAnalyticsUtils.trackEvent(
        'readerSettings',
        'setAudioSpeed',
        audioSpeed
      );
      await ReadingSettingsService.setAudioSpeedByBookId(bookId, audioSpeed);
    } catch (err) {
      logger.error(`Error while setting audio speed: ${err}`);
      commit('changeDisplayBlockRule', 'audioSpeed', false);
    }
  },
  async setExpandedNotesMode({ commit }, { bookId, expandedNotesMode }) {
    try {
      commit('setExpandedNotesMode', expandedNotesMode);
      GoogleAnalyticsUtils.trackEvent(
        'readerSettings',
        'toggleMarginNotes',
        expandedNotesMode ? 'On' : 'Off'
      );
      await ReadingSettingsService.setExpandedNotesModeByBookId(
        bookId,
        expandedNotesMode
      );
    } catch (err) {
      logger.error(`Error while setting expanded notes mode: ${err}`);
      commit('changeDisplayBlockRule', 'expandedMarginNotes', false);
    }
  },
  async setExpandedFootnotesMode({ commit }, { setting, skipSync }) {
    try {
      commit('setExpandedFootnotesMode', setting);
      await ReadingSettingsService.setExpandedFootnotesMode(setting, skipSync);
    } catch (err) {
      logger.error(`Error while setting expanded footnotes mode: ${err}`);
      commit('changeDisplayBlockRule', 'expandedFootnotes', false);
    }
  },
  async setAutoPlayNotes({ commit }, { setting, skipSync }) {
    try {
      commit('setAutoPlayNotes', setting);
      await ReadingSettingsService.setAutoPlayNotes(setting, skipSync);
    } catch (err) {
      logger.error(`Error while setting auto play notes mode: ${err}`);
      commit('changeDisplayBlockRule', 'autoPlayNotes', false);
    }
  },
  async setGoogleTranslateMode({ commit }, { setting, skipSync }) {
    try {
      commit('addUpdatingSettings', ReadingSettingsEnum.GOOGLE_TRANSLATE);
      commit('setGoogleTranslateMode', setting);
      await ReadingSettingsService.setGoogleTranslateMode(setting, skipSync);
      commit('removeUpdatingSettings', ReadingSettingsEnum.GOOGLE_TRANSLATE);
    } catch (err) {
      logger.error(`Error while setting google translate mode: ${err}`);
      commit(
        'changeDisplayBlockRule',
        ReadingSettingsEnum.GOOGLE_TRANSLATE,
        false
      );
    }
  },
  async setDictionaryMode({ commit }, { setting, skipSync }) {
    try {
      commit('addUpdatingSettings', ReadingSettingsEnum.DICTIONARY_MODE);
      commit('setDictionaryMode', setting);
      await ReadingSettingsService.setDictionaryMode(setting, skipSync);
      commit('removeUpdatingSettings', ReadingSettingsEnum.DICTIONARY_MODE);
    } catch (err) {
      logger.error(`Error while setting dictionaryMode mode: ${err}`);
      commit(
        'changeDisplayBlockRule',
        ReadingSettingsEnum.DICTIONARY_MODE,
        false
      );
    }
  },
  async setWikiMode({ commit }, { setting, skipSync }) {
    try {
      commit('addUpdatingSettings', ReadingSettingsEnum.WIKI_MODE);
      commit('setWikiMode', setting);
      await ReadingSettingsService.setWikiMode(setting, skipSync);
      commit('removeUpdatingSettings', ReadingSettingsEnum.WIKI_MODE);
    } catch (err) {
      logger.error(`Error while setting wikiMode mode: ${err}`);
      commit('changeDisplayBlockRule', ReadingSettingsEnum.WIKI_MODE, false);
    }
  },
  async setDictionaryAutoOpen({ commit }, { setting, skipSync }) {
    try {
      commit('addUpdatingSettings', ReadingSettingsEnum.DICTIONARY_AUTO_OPEN);
      commit('setDictionaryAutoOpen', setting);
      await ReadingSettingsService.setDictionaryAutoOpen(setting, skipSync);
      commit(
        'removeUpdatingSettings',
        ReadingSettingsEnum.DICTIONARY_AUTO_OPEN
      );
    } catch (err) {
      logger.error(`Error while setting dictionaryAutoOpen mode: ${err}`);
      commit(
        'changeDisplayBlockRule',
        ReadingSettingsEnum.DICTIONARY_AUTO_OPEN,
        false
      );
    }
  },
  async setHighlightMode({ commit }, { setting, skipSync }) {
    try {
      commit('addUpdatingSettings', ReadingSettingsEnum.HIGHLIGHT_MODE);
      commit('setHighlightMode', setting);
      await ReadingSettingsService.setHighlightMode(setting, skipSync);
      commit('removeUpdatingSettings', ReadingSettingsEnum.HIGHLIGHT_MODE);
    } catch (err) {
      const defaultMode = AppConstantsUtil.DEFAULT_HIGHLIGH_MOD;
      logger.error(
        `Error while setting highligh mode: ${err} set default ${defaultMode}`
      );
      commit('setHighlightMode', defaultMode);
    }
  },
  async setScrollingMode({ commit }, { setting, skipSync }) {
    try {
      commit('addUpdatingSettings', ReadingSettingsEnum.SCROLLING_MODE);
      commit('setScrollingMode', setting);
      await ReadingSettingsService.setScrollingMode(setting, skipSync);
      commit('removeUpdatingSettings', ReadingSettingsEnum.SCROLLING_MODE);
    } catch (err) {
      const defaultMode = AppConstantsUtil.DEFAULT_SCROLLING_MOD;
      logger.error(
        `Error while setting scrolling mode: ${err} set default ${defaultMode}`
      );
      commit('setScrollingMode', defaultMode);
    }
  },
  async setReadingPositionTracking({ commit }, readingPositionTracking) {
    try {
      commit('setReadingPositionTracking', readingPositionTracking);
      await ReadingSettingsService.setReadingPositionTracking(
        readingPositionTracking
      );
    } catch (err) {
      logger.error(
        `Error while setting reading position tracking mode: ${err}`
      );
      commit('changeDisplayBlockRule', 'readingPositionTracking', false);
    }
  },
  async decreaseFontSize({ commit, getters, state }) {
    if (getters.getIsMinFontSize) {
      return;
    }
    try {
      GoogleAnalyticsUtils.trackEvent('readerSettings', 'decreaseFontSize');
      commit('decreaseFontSize');
      await ReadingSettingsService.setFontSize(
        state.readingSettingsView.fontSize
      );
    } catch (err) {
      logger.error(`Error while setting font size: ${err}`);
      commit('changeDisplayBlockRule', 'fontSize', false);
    }
  },
  async increaseFontSize({ commit, getters, state }) {
    if (getters.getIsMaxFontSize) {
      return;
    }
    try {
      GoogleAnalyticsUtils.trackEvent('readerSettings', 'increaseFontSize');
      commit('increaseFontSize');
      await ReadingSettingsService.setFontSize(
        state.readingSettingsView.fontSize
      );
    } catch (err) {
      logger.error(`Error while setting font size: ${err}`);
      commit('changeDisplayBlockRule', 'fontSize', false);
    }
  },
  async increaseLineSpacing({ commit, getters, state }) {
    if (getters.getIsMaxLineSpacing) {
      return;
    }
    try {
      commit('increaseLineSpacing');
      await ReadingSettingsService.setLineSpacing(
        state.readingSettingsView.lineSpacing
      );
    } catch (err) {
      logger.error(`Error while setting line spacing: ${err}`);
      commit('changeDisplayBlockRule', 'fontSize', false);
    }
  },
  async decreaseLineSpacing({ commit, getters, state }) {
    if (getters.getIsMinLineSpacing) {
      return;
    }
    try {
      commit('decreaseLineSpacing');
      await ReadingSettingsService.setLineSpacing(
        state.readingSettingsView.lineSpacing
      );
    } catch (err) {
      logger.error(`Error while setting line spacing: ${err}`);
      commit('changeDisplayBlockRule', 'lineSpacing', false);
    }
  },
  updateUserSettingsStorage({ dispatch }, { userSettings }) {
    for (const key in userSettings) {
      if (key === 'TranslatorSettings') {
        dispatch(
          'WikiStore/setTranslatorLanguage',
          {
            selectedLanguage:
              userSettings.TranslatorSettings?.translationLanguage,
            skipSettingSync: true
          },
          { root: true }
        );
      } else if (key === 'ReaderSettings') {
        dispatch('updateLocalReadingSettings', userSettings.ReaderSettings);
      }
    }
  },
  updateLocalReadingSettings({ getters, dispatch }, readingSettings) {
    if (Object.keys(getters.getReadingSettingsView).length === 0) {
      return;
    }
    if (
      readingSettings.hasOwnProperty(ReadingSettingsEnum.DICTIONARY_AUTO_OPEN)
    ) {
      dispatch('setDictionaryAutoOpen', {
        setting: readingSettings.dictionaryAutoOpen,
        skipSync: true
      });
    }
    if (readingSettings.hasOwnProperty(ReadingSettingsEnum.GOOGLE_TRANSLATE)) {
      dispatch('setGoogleTranslateMode', {
        setting: readingSettings.googleTranslate,
        skipSync: true
      });
    }
    if (readingSettings.hasOwnProperty(ReadingSettingsEnum.DICTIONARY_MODE)) {
      dispatch('setDictionaryMode', {
        setting: readingSettings.dictionaryMode,
        skipSync: true
      });
    }
    if (readingSettings.hasOwnProperty(ReadingSettingsEnum.WIKI_MODE)) {
      dispatch('setWikiMode', {
        setting: readingSettings.wikiMode,
        skipSync: true
      });
    }
    if (readingSettings.hasOwnProperty(ReadingSettingsEnum.SCROLLING_MODE)) {
      dispatch('setScrollingMode', {
        setting: readingSettings.scrollingMode,
        skipSync: true
      });
    }
    if (readingSettings.hasOwnProperty(ReadingSettingsEnum.HIGHLIGHT_MODE)) {
      dispatch('setHighlightMode', {
        setting: readingSettings.highlightMode,
        skipSync: true
      });
    }
  }
};

const mutations = {
  setReadingSettings(state, readingSettings) {
    state.readingSettingsView = readingSettings;
  },
  clearReadingSettings(state) {
    state.readingSettings = {};
    Object.keys(state.displayBlockRules).forEach(block => {
      state.displayBlockRules[block] = true;
    });
  },
  recalculateCanShowMarginNotes(state) {
    const windowWidth = window.innerWidth;
    state.canShowMarginNotes =
      windowWidth >= AppConstantsUtil.MIN_EXPANDED_MARGIN_NOTES_WIDTH;
  },
  changeDisplayBlockRule(state, block, value) {
    state.displayBlockRules[block] = value;
  },
  addUpdatingSettings(state, name) {
    state.updatingSettings = [...state.updatingSettings, name];
  },
  removeUpdatingSettings(state, name) {
    state.updatingSettings = state.updatingSettings.filter(
      settingName => settingName !== name
    );
  },
  setFonts(state, fonts = []) {
    state.allFonts = fonts;
  },
  setFont(state, font) {
    state.readingSettingsView.font = font;
  },
  setReadingThemeName(state, themeName) {
    state.readingSettingsView.themeName = themeName;
  },
  setAudioSpeed(state, { audioSpeed }) {
    state.readingSettingsView.audioSpeed = audioSpeed;
  },
  setExpandedNotesMode(state, expandedNotesMode) {
    state.readingSettingsView.expandedNotesMode = expandedNotesMode;
  },
  setExpandedFootnotesMode(state, setting) {
    state.readingSettingsView.expandedFootnotesMode = setting;
  },
  setAutoPlayNotes(state, setting) {
    state.readingSettingsView.autoPlayNotes = setting;
  },
  setGoogleTranslateMode(state, setting) {
    state.readingSettingsView.googleTranslate = setting;
  },
  setDictionaryMode(state, setting) {
    state.readingSettingsView.dictionaryMode = setting;
  },
  setWikiMode(state, setting) {
    state.readingSettingsView.wikiMode = setting;
  },
  setDictionaryAutoOpen(state, setting) {
    state.readingSettingsView.dictionaryAutoOpen = setting;
  },
  setHighlightMode(state, setting) {
    state.readingSettingsView.highlightMode = setting;
  },
  setScrollingMode(state, setting) {
    state.readingSettingsView.scrollingMode = setting;
  },
  setReadingPositionTracking(state, readingPositionTracking) {
    state.readingSettingsView.readingPositionTracking = readingPositionTracking;
  },
  decreaseFontSize(state) {
    state.readingSettingsView.fontSize -= AppConstantsUtil.FONT_SIZE_STEP;
  },
  increaseFontSize(state) {
    state.readingSettingsView.fontSize += AppConstantsUtil.FONT_SIZE_STEP;
  },
  decreaseLineSpacing(state) {
    state.readingSettingsView.lineSpacing -= AppConstantsUtil.LINE_SPACING_STEP;
  },
  increaseLineSpacing(state) {
    state.readingSettingsView.lineSpacing += AppConstantsUtil.LINE_SPACING_STEP;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
