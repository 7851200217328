import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import FileAccessTypeEnum from '@/enums/FileAccessTypeEnum';
import EncodingEnum from '@/enums/EncodingEnum';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import ApplicationCacheService from '@/services/AssetsManager/ApplicationCacheService';

import OfflineModeUtils from '@/services/utils/OfflineModeUtils';
import Http from '@/services/utils/Http';
import responseParser from '@/services/AssetsManager/FileTransport/responseParser';

import { FilesHandlingInterface } from './FilesHandlingInterface';

class FilesHandlingRemote extends FilesHandlingInterface {
  constructor() {
    super(AssetResourcesEnum.REMOTE);
    this.remoteTransportContext = {
      serverUrl: '',
      runid: ''
    };
  }
  init(Context, runid) {
    Object.assign(this.remoteTransportContext, {
      serverUrl: Context.downloadUrl,
      runid
    });
  }

  setRunId(runid) {
    this.remoteTransportContext.runid = runid;
  }

  isAvailable() {
    return (
      OfflineModeUtils.checkIsOnline() || ApplicationCacheService.isReady()
    );
  }

  getUnavailableError() {
    const err = new Error(`FileAssetDriver: ${this.type} is not available`);
    err.type = CustomErrorEnum.NETWORK_ERROR;
    return err;
  }

  createCacheFilePath(fileSourcePath, accessType) {
    const replaceQueryParamRe = /\/?\?.*$/;
    const suffix = this._getAccessTypeSuffix(accessType);
    return fileSourcePath
      .replace(this.remoteTransportContext.serverUrl + suffix + '/', '')
      .replace(replaceQueryParamRe, '');
  }

  createFileSourcePath(fileSourcePath, accessType) {
    const suffix = this._getAccessTypeSuffix(accessType);
    let fullFileSourcePath =
      this.remoteTransportContext.serverUrl +
      [suffix, fileSourcePath].join('/');
    const sourceUrl = new URL(fullFileSourcePath);

    if (
      accessType !== FileAccessTypeEnum.CHUNKED &&
      this.remoteTransportContext.runid
    ) {
      const isImage = /\.(webp|png)(\?|$)/.test(fullFileSourcePath);
      if (!isImage && this.remoteTransportContext.runid) {
        sourceUrl.searchParams.append(
          'RunId',
          this.remoteTransportContext.runid
        );
      }
    }
    return sourceUrl.href;
  }

  _getAccessTypeSuffix(accessType) {
    switch (accessType) {
      case FileAccessTypeEnum.FULL:
        return 'files';
      case FileAccessTypeEnum.CHUNKED:
        return 'chunked';
      default:
        throw new Error(
          `Get unsupported accessType: ${accessType} in _getAccessTypeSuffix`
        );
    }
  }

  readFile(filePath, readOptions) {
    const self = this;
    return Promise.resolve().then(function() {
      const fileSourcePath = self.createFileSourcePath(
        filePath,
        readOptions.accessType
      );
      switch (readOptions.accessType) {
        case FileAccessTypeEnum.FULL:
          return self._readFileByUrl(fileSourcePath, readOptions);
        case FileAccessTypeEnum.CHUNKED:
          return self._readFileByChunk(fileSourcePath, readOptions);
        default:
          throw new Error(
            `Get unsupported accessType: ${readOptions.accessType} in readFile`
          );
      }
    });
  }

  async _readFileFromCache(fileSourcePath, readOptions) {
    const cacheFilePath = this.createCacheFilePath(
      fileSourcePath,
      readOptions.accessType
    );
    const hasInCache =
      readOptions.useCache &&
      ApplicationCacheService.isFileInCache(cacheFilePath);
    const cacheReady = ApplicationCacheService.isReady();
    if (hasInCache && cacheReady) {
      const data = await ApplicationCacheService.readFile(
        cacheFilePath,
        readOptions
      );
      return data;
    }
    return null;
  }

  async _writeFileToCache(fileSourcePath, readOptions, response) {
    const cacheFilePath = this.createCacheFilePath(
      fileSourcePath,
      readOptions.accessType
    );
    const cacheReady = ApplicationCacheService.isReady();
    const needAddToCache = this._isNeedAddToCache(fileSourcePath, readOptions);

    if (needAddToCache && cacheReady) {
      await ApplicationCacheService.saveFile(cacheFilePath, response.data);
    }
  }

  _isNeedAddToCache(fileSourcePath, readOptions) {
    const cacheFilePath = this.createCacheFilePath(
      fileSourcePath,
      readOptions.accessType
    );
    return (
      readOptions.useCache &&
      !ApplicationCacheService.isFileInCache(cacheFilePath)
    );
  }

  async _readFileByUrl(fileSourcePath, readOptions) {
    const options = {};
    if (readOptions && readOptions.encoding === EncodingEnum.BLOB) {
      options.responseType = 'blob';
      options.maxTimeout = readOptions.maxTimeout;
    }
    try {
      const data = await this._readFileFromCache(fileSourcePath, readOptions);
      if (data) {
        return data;
      }

      const response = await Http.get(fileSourcePath, options);
      const resp = responseParser.parse(response.data, readOptions);

      await this._writeFileToCache(fileSourcePath, readOptions, response);
      return resp;
    } catch (error) {
      if (error.type === CustomErrorEnum.PARSED_JSON) {
        error.message += ' filePath ' + fileSourcePath;
      }
      throw error;
    }
  }

  async _readFileByChunk(fileSourcePath, readOptions) {
    const data = await this._readFileFromCache(fileSourcePath, readOptions);
    if (data) {
      return data;
    }
    const cacheReady = ApplicationCacheService.isReady();
    const needAddToCache = this._isNeedAddToCache(fileSourcePath, readOptions);
    if (needAddToCache && cacheReady) {
      const cacheFilePath = this.createCacheFilePath(
        fileSourcePath,
        readOptions.accessType
      );
      await ApplicationCacheService.downloadFileToCache(
        cacheFilePath,
        readOptions
      );
      const resp = await this._readFileFromCache(fileSourcePath, readOptions);
      if (resp) {
        return resp;
      }
    }
    return this._readRemoteFileByChunk(fileSourcePath, readOptions);
  }

  async _readRemoteFileByChunk(fileSourcePath, readOptions) {
    const { chunkOffset } = readOptions;
    const rangeParameter = `bytes=${chunkOffset.start}-${chunkOffset.end - 1}`;
    const headers = {
      Range: rangeParameter,
      'Cache-Control': 'no-cache'
    };

    const options = {
      headers: headers,
      responseType: 'arraybuffer'
    };
    const response = await Http.get(fileSourcePath, options);
    const res = responseParser.parse(response.data, readOptions);
    return res;
  }
}

export { FilesHandlingRemote };
