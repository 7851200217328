import HighlightOptionsEnum from '../../enums/HighlightOptionsEnum';
import ScrollingModsEnum from '../../enums/ScrollingModsEnum';

export default {
  MAX_TIMEOUT: 10_000,
  AUDIO_FILE_MAX_TIMEOUT: 60_000,
  DAY_IN_MS: 24 * 60 * 60 * 1000,
  DEFAULT_FONT_SIZE: 100,
  MIN_FONT_SIZE: 50,
  MAX_FONT_SIZE: 400,
  DEFAULT_AUDIO_SPEED: 140,
  TEXT_SCROLL_SPEED: 2000,
  NOT_AUDIO_CONTENT_SCROLL_SPEED: 4000,
  DEFAULT_LINE_SPACING: 145,
  MIN_LINE_SPACING: 125,
  MAX_LINE_SPACING: 165,
  RTL_SHIFT_LINE_SPACING: 20,
  DEFAULT_HIGHLIGH_MOD: HighlightOptionsEnum.DARKEN,
  DEFAULT_SCROLLING_MOD: ScrollingModsEnum.EACH_PAGE,
  LIBRARY_TOOLBAR_HEIGHT: 60,
  PROGRESS_TOOLBAR_CLASS_NAME: 'progress-toolbar-wrapper',
  FONT_SIZE_STEP: 10,
  LINE_SPACING_STEP: 10,
  SHORT_AUDIO_SPEED_STEP: 5,
  LONG_AUDIO_SPEED_STEP: 10,
  AUDIO_STEPS_IN_EACH_SIDE: 5,
  MAX_COMPILATION_TITLE_LENGTH: 80,
  MARGIN_NOTES_CHIP_MARGIN: 8,
  MIN_EXPANDED_MARGIN_NOTES_WIDTH: 800,
  MAX_COMPILATION_DESCRIPTION_LENGTH: 500,
  MAX_COMPILATION_SECTION_NAME_LENGTH: 50,
  MAX_NOTE_LEN: 5000,
  MAX_NOTE_HASHTAGS: 5,
  MAX_NOTE_HASHTAG_LENGTH: 30,
  COMPLETED_PERCENT_BORDER: 98,
  START_PERCENT_BORDER: 1,
  ANN_INFO_POPUP_BASE_WIDTH: 480,
  ANN_INFO_POPUP_NARROW_WIDTH: 328,
  CREATE_ANNOTATION_POPUP_WIDTH: 792,
  ANNOTATION_SIDEBAR_WIDTH: 32,
  DICTIONARY_POPUP_MIN_HEIGHT: 250,
  DICTIONARY_POPUP_MAX_HEIGHT: 380,
  DICTIONARY_POPUP_SAFE_SPACE: 20,
  SMALL_POPUP_NARROW_WIDTH: 328,
  MAX_SELECTION_PARAS_NUMBER: 20,
  OPEN_ANCHOR_CLASS_NAME: 'open-anchor',
  SEARCH_REQ_DECORATOR_CLASS: 'search-req',
  SENTENCE_DECORATOR_CLASS: 'search-sentence',
  NOTE_FILTER_HIGHLIGHT_CLASS: 'search-req',
  LAST_PARA: 'is-last',
  LAST_PARA_WITH_SUGGESTED: 'last-para-with-suggested',
  FIRST_PARA: 'is-first',
  WIKI_CATEGORY_NAME: 'Wiki',
  ENROLL_ELEMENT_ID: 'enrollForm',
  DEFINITION_CATEGORY_NAME: 'En-En Dictionary',
  TRANSLATOR_CATEGORY_NAME: 'Translation',
  TOOLBAR_BLOCK_CLASS_NAME: 'toolbar-block',
  SELECTION_LINK_CLASS: 'selection-link',
  BOOK_SCROLL_CLASS_NAME: 'list',
  ANNOTATION_WRAPPER_CLASS: 'annotation-wrapper',
  ANNOTATION_SIDEBAR: 'annotation-sidebar',
  ANNOTATION_INFO_POPUP: 'annotation-info-popup',
  ANNOTATION_POPUP_INFO: 'ann-popup-info',
  ITEM_SELECTOR: '.item',
  WORD_COUNT_ATTRIBUTE: 'data-words-count',
  ILM_ID_ATTRIBUTE: 'data-ilmid',
  META_BLOCK_CLASS: 'meta-info',
  META_BLOCK_WRAPPER_CLASS: 'meta-info-wrapper',
  SCROLL_ITEM_CLASS: 'item',
  SCROLL_BLOCK_CLASS: 'book-scroll-block',
  SCROLL_LIST_CLASS: 'book-scroll-class',
  EXTRAS_POPUP: 'extras-popup',
  EXTRAS_SCROLLABLE_CLASS: 'notes-wrapper',
  MANAGE_PUBLICATION_SCROLL_CLASS: 'managepublication-scroll',
  GUEST_AREA_CLASS: 'guest-area',
  DEVICE_BROWSER_CLASS: 'device-browser',
  IN_APP_MODE_CLASS: 'in-app-mode',
  PUBLICATION_SCROLL_CLASS: 'publication-scroll',
  PUBLICATION_VIRTUAL_LIST_SCROLL_CLASS: 'scroll',
  FOOTNOTE_CLASS: 'space',
  MANAGE_PUBLICATION_CONTROL_BLOCK_CLASS:
    'manage-publications-control-block-hide',
  SAFE_AREA_INSERT_CLASS_NAME: 'safe-area-inset',
  MANAGE_PUBLICATION_SCROLL_ELEMENT: 'html',
  PUBLICATION_SCROLL_ELEMENT: 'html',
  PUBLICATION_VIRTUAL_LIST_SCROLL_ELEMENT: '.list',
  PLAY_BUTTON_CLASS: 'play-btn',
  FAB_ID: 'fab',
  BOOK_PREVIEW_ID: 'bookView',
  RENDERED_PARA_NUM: 120,
  START_RENDERED_PARA_COUNT: 40,
  DEVICE_RENDERED_PARA_NUM: 120,
  DEVICE_START_RENDERED_PARA_COUNT: 40,
  DEFAULT_USERS_COUNT: 30,
  MAX_ATTACHMENT_SIZE: 10000000,
  MS_IN_SEC: 1000,
  UPDATE_LIBRARY_INTERVAL: 5 * 60 * 1000,
  UPDATE_USER_INTERVAL: 5 * 60 * 1000,
  UPDATE_ASSESSMENT_HISTORY_INTERVAL: 5 * 60 * 1000,
  CHECK_SUBSCRIPTION_INTERVAL: 60 * 1000,
  DATE_TIME_FORMAT: 'D MMMM YYYY HH:mm',
  MAX_CAMPAIGNS_DATE_RANGE: 90,
  MIDDLE_CAMPAIGNS_DATE_RANGE: 30,
  MIN_CAMPAIGNS_DATE_RANGE: 7,
  TYPO_MAX_SELECTED_SYMBOLS: 200,
  TYPO_NEARBY_SYMBOLS: 60,
  TYPO_NEARBY_WORDS: 5,
  TYPO_MAX_SYMBOLS_FOR_NEARBY: 120,
  MAX_BOOKS_IN_LIBRARY_REDUCED_CATEGORY: 15,
  MAX_BOOKS_IN_LIBRARY_REDUCED_CATEGORY_MOBILE: 10,
  BOOKS_IN_ROW_TWO: 4,
  BOOKS_IN_ROW_NARROW: 5,
  BOOKS_IN_ROW_FOUR: 6,
  BOOKS_IN_ROW_NORMAL: 8,
  BOOKS_IN_ROW_WIDE: 10,
  DEFAULT_ENABLED_PARAGRAPHS: 10,
  DEFAULT_ENABLED_PARAGRAPHS_STEP: 1,
  SELECTION_RANGE_SEPARATOR: '~',
  BODY_OVERFLOW_CLASS: 'body-overflow',
  BODY_OVERFLOW_CLASS_PADDING: 'body-overflow-padding',
  SUGGESTED_BLOCK_CLASS: 'suggested-assessment-link',
  AVERAGE_PAGE_WORDS_COUNT: 300,
  HTML_IS_MOBILE_CLASS: 'is-mobile',
  VUETIFY_DISABLE_SCROLL_CLASS: 'overflow-y-hidden',
  START_POSITION_TRACKER_DELAY: 5000,
  START_POSITION_TRACKER_SHORT_DELAY: 500,
  DROP_CAP_SELECTOR: '[dropcap-dummy]',
  MAX_CATEGORY_NAME_LENGTH: 20,
  USER_ACTIVITY_DATE_FORMAT: 'YYYY-MM-DD',
  DISABLED_MARK_COLOR: 'grey'
};
