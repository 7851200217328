<template>
  <div class="annotation-buttons-block">
    <span v-if="editable" class="btn-delete" @click="deleteAnnotation">
      <BaseSpriteIcon icon-name="ico-delete" />
    </span>
    <span v-if="editable" class="btn-edit" @click="openEditAnnotationPopup">
      <BaseSpriteIcon icon-name="ico-edit" />
    </span>
    <span v-if="isAnnPlayable" class="btn-play" @click.stop="playAnnotation">
      <BaseSpriteIcon icon-name="ico-sound" />
    </span>
    <span v-if="isAnnPlaying" class="btn-stop" @click.stop="stopPlayingAnn">
      <BaseSpriteIcon icon-name="ico-pause" />
    </span>
  </div>
</template>

<script>
import Locator from '@shared/publication/locator.mjs';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import LoggerFactory from '@/services/utils/LoggerFactory';

import PopupNamesEnum from '@/enums/PopupNamesEnum';

import ContextMenuParamsFactory from '@/classes/factories/Materials/ContextMenuParamsFactory';
const logger = LoggerFactory.getLogger('PositionTracker.vue');

export default {
  name: 'AnnotationActionButtons',
  components: { BaseSpriteIcon },
  props: {
    annotation: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    quote: {
      type: String,
      required: false,
      default: ''
    },
    autoPlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lang() {
      const lang = this.$store.getters['BookStore/getParaLanguage'](
        this.annotation.paragraphId
      );
      return lang;
    },
    isTtsCase() {
      const isTtsCase = this.$store.getters['PlaybackStore/getIsTtsCase'](
        this.annotation?.paragraphId,
        this.quote
      );
      return isTtsCase;
    },
    isAnnPlayable() {
      const publication = this.$store.getters[
        'LibraryStore/getPublicationById'
      ](this.bookId);
      const isAudioDownloaded = publication?.isAudioDownloaded;
      const isOnline = this.$store.getters['ContextStore/isOnline'];

      const canPlayBookAudio = this.isVoiced && (isOnline || isAudioDownloaded);
      const canPlayTts = this.isTtsCase && isOnline;
      return (canPlayBookAudio || canPlayTts) && !this.isAnnPlaying;
    },
    isAnnPlaying() {
      return this.annotation.id === this.getPlayingAnnId;
    },
    getPlayingAnnId() {
      return this.$store.getters['AnnotationsStore/getPlayingAnnId']();
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    currentMode() {
      return this.$store.getters['ContextStore/appModeGetter'];
    },
    annLocator() {
      return new Locator.InTextRangeLocator(
        this.annotation.start,
        this.annotation.end
      );
    },
    isVoiced() {
      const paraId =
        this.annotation.start?.prefixedParagraphId || this.annotation.blockId;
      return this.$store.getters['PublicationStore/isElementVoiced'](
        this.bookId,
        paraId
      );
    }
  },
  destroyed() {
    if (this.isAnnPlaying) {
      this.stopPlayingAnn();
    }
  },
  mounted() {
    if (this.autoPlay && this.isAnnPlayable) {
      setTimeout(() => {
        this.playAnnotation();
      }, 0);
    }
  },
  methods: {
    deleteAnnotation() {
      if (!this.annotation) {
        return;
      }
      this.$store.dispatch('AnnotationsStore/deleteAnnotation', {
        annotation: this.annotation
      });
    },
    playAnnotation() {
      const isPollyEnabled = this.$store.getters['ContextStore/isPollyEnabled'];
      if (this.isTtsCase && isPollyEnabled) {
        this.$_playTtsAnnotation();
      } else {
        this.$_playBookAnnotation();
      }
    },
    $_playTtsAnnotation() {
      this.$store.dispatch('AnnotationsStore/playTtsAnnotation', {
        text: this.quote,
        lang: this.lang,
        locator: this.annLocator,
        playingAnnId: this.annotation.id
      });
    },
    $_playBookAnnotation() {
      try {
        this.$store.dispatch('AnnotationsStore/playAnnotation', {
          locator: this.annLocator,
          playingAnnId: this.annotation.id
        });
      } catch (error) {
        logger.warn(`Get error on playing annotation: ${error}`, {
          sendError: true
        });
      }
    },
    stopPlayingAnn() {
      this.$store.dispatch('AnnotationsStore/stopPlayingAnnotation');
    },
    openEditAnnotationPopup() {
      this.stopPlayingAnn();
      this.$store.dispatch('PlaybackStore/pause');
      const menuPosition = ContextMenuParamsFactory.createMenuPosition();

      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.CREATE_ANNOTATION_POPUP,
        popupContext: {
          annotation: this.annotation || null,
          menuPosition
        }
      });

      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.ANNOTATION_INFO_POPUP
      });
    }
  }
};
</script>

<style lang="less" src="./AnnotationActionButtons.less" />
