import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('ProgressStore.vue');

import Locator from '@shared/publication/locator.mjs';
import _range from 'lodash/range';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import RequestService from '@/services/RequestService.js';
import RestService from '@/services/RestService.js';
import UserProgressFactory from '@/classes/factories/UserProgressFactory';
import AppModeEnum from '@/enums/AppModeEnum';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';
import dayJS from '@/dayJS';
import Utils from '@/services/utils/Utils';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.mjs';
import searchHighlighter from '@shared/publication/search-highlighter';
import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';

const MethodsEnum = { GET: 'get', POST: 'post' };
const CONTROLLER_NAME = 'UserProgress';
const SUGGESTIONS_CONTROLLER_NAME = 'SuggestionsProgress';
const DATE_KEY_FORMAT = 'YY.MM.DD';
const LAST_POSITIONS_AMOUNT = 2;

class UserActivityTrack {
  constructor(buildData = {}) {
    this.studyItemWordsCountByDates =
      buildData.studyItemWordsCountByDates || {};
    this.ranges = buildData.ranges || [];
    this.userMovingHistory = buildData.userMovingHistory || [];
    this.readingCurrentLocator = buildData.readingCurrentLocator || '';
    this.audioCurrentLocator = buildData.audioCurrentLocator || '';
    this.readingArea = buildData.readingArea || null;
  }
}

class UserActivityTrackBuilder {
  setStudyItemWordsCountByDates(val) {
    this.studyItemWordsCountByDates = val;
    return this;
  }
  setRanges(val) {
    this.ranges = val;
    return this;
  }
  setUserMovingHistory(val) {
    this.userMovingHistory = val;
    return this;
  }
  setReadingCurrentLocator(val) {
    this.readingCurrentLocator = val;
    return this;
  }
  setAudioCurrentLocator(val) {
    this.audioCurrentLocator = val;
    return this;
  }
  setReadingArea(val) {
    this.readingArea = val;
    return this;
  }

  build() {
    return new UserActivityTrack(this);
  }
}

class ProgressSummaryItem {
  constructor(
    readPercent = 0,
    completed = false,
    totalWords = 0,
    readWords = 0,
    startAt,
    endAt,
    readingDaysAmount = 0
  ) {
    this.readPercent = Math.min(readPercent, 100);
    this.completed = completed;
    this.totalWords = totalWords;
    this.readWords = readWords;
    this.startAt = startAt || Date.now();
    this.endAt = completed ? endAt : null;
    this.readingDaysAmount = completed ? readingDaysAmount : null;
  }
}

const READING_PROGRESS = 'ReadingProgress';

function saveReadingPositionsById(publicationId, activityTracker) {
  UnifiedSettingsService.setSetting(READING_PROGRESS, publicationId, {
    readingArea: activityTracker.readingArea,
    audioCurrentLocator: activityTracker.audioCurrentLocator,
    readingCurrentLocator: activityTracker.readingCurrentLocator
  });
}

function getLocalActivityTrackById(publicationId) {
  const localData = UnifiedSettingsService.getSetting(
    READING_PROGRESS,
    publicationId
  );
  if (!localData) {
    return null;
  }
  const builder = new UserActivityTrackBuilder();
  return builder
    .setReadingCurrentLocator(localData.readingCurrentLocator)
    .setAudioCurrentLocator(localData.audioCurrentLocator)
    .setReadingArea(localData.readingArea)
    .build();
}

async function retrieveAllBooksProgress() {
  const allBooksProgress = await RequestService.request(
    MethodsEnum.GET,
    CONTROLLER_NAME,
    'allBooksProgress',
    {},
    {}
  );
  return allBooksProgress || {};
}

function loadLibraryProgress(userId) {
  return RequestService.request(
    MethodsEnum.GET,
    CONTROLLER_NAME,
    'libraryProgress',
    { userId },
    {}
  );
}

function retrievePublicationProgress(publicationId, type) {
  return RequestService.request(
    MethodsEnum.GET,
    CONTROLLER_NAME,
    'getProgress',
    { publicationId, type },
    {}
  );
}

function savePublicationProgress(
  publicationId,
  type,
  progressData,
  summaryData
) {
  return RequestService.request(
    MethodsEnum.POST,
    CONTROLLER_NAME,
    'saveProgress',
    { publicationId, type, progressData, summaryData },
    {}
  );
}

function resetBookProgress(bookId, userId) {
  return RequestService.request(
    MethodsEnum.POST,
    CONTROLLER_NAME,
    'resetBookProgress',
    { publicationId: bookId, userId },
    {}
  );
}

async function retrieveSuggestionsProgress(suggestionIds, userId, dateFrom) {
  const response = await RequestService.request(
    MethodsEnum.GET,
    SUGGESTIONS_CONTROLLER_NAME,
    'suggestionsProgress',
    { suggestionIds, userId, dateFrom },
    {}
  );
  return response.data || {};
}

function getProgressDataFromGroup(state, publicationId, type) {
  const progressGroup = state.progressData[type];
  return get(progressGroup, `[${publicationId}]`, {});
}

const initState = () => {
  return {
    progressData: {
      [PublicationsTypesEnum.BOOK]: {},
      [PublicationsTypesEnum.STUDY_GUIDE]: {},
      [PublicationsTypesEnum.COMPILATION]: {}
    },
    readParagraphRanges: {},
    trackingTasks: [],
    progressSummary: null,
    allProgressData: {
      [PublicationsTypesEnum.BOOK]: {},
      [PublicationsTypesEnum.STUDY_GUIDE]: {},
      [PublicationsTypesEnum.COMPILATION]: {}
    },
    extraContentFields: {},
    previousPositions: {},
    readingArea: null,
    returnReadingArea: null,
    saveActivityInProgress: false,
    suggestionsProgressData: {},
    metaBlockInViewPort: true
  };
};
function isValidSerializedLocator(locator) {
  return typeof locator === 'string' && locator.length !== 0;
}

function _isEqualStartLocators(firstSerializeLocator, secondSerializeLocator) {
  if (!firstSerializeLocator || !secondSerializeLocator) {
    return false;
  }
  const firstLocator = Locator.deserialize(firstSerializeLocator);
  const secondLocator = Locator.deserialize(secondSerializeLocator);
  return firstLocator.startLocator.equals(secondLocator.startLocator);
}

let readingPositionTimeout = null;
let isActualLoadReadingPosition = false;
const MAX_NUMBER_ATTEMPTS = 3;
const MAX_WAIT_RESPONSE_TIME = 5 * 1000;
async function tryLoadRemoteReadingPosition(publicationId, numberAttempts = 0) {
  const defaultResp = { data: {} };
  if (!isActualLoadReadingPosition) {
    return defaultResp;
  }
  if (numberAttempts === MAX_NUMBER_ATTEMPTS) {
    return defaultResp;
  }
  numberAttempts += 1;
  try {
    clearTimeout(readingPositionTimeout);
    const config = await RestService.createRequestParams(
      MethodsEnum.GET,
      'UserStudy',
      'userReadingPosition',
      { publicationId },
      {}
    );
    const promise = RestService.sendReq(config);
    readingPositionTimeout = setTimeout(() => {
      promise.cancel(
        `Wait response more then ${MAX_WAIT_RESPONSE_TIME} cancel request`
      );
    }, MAX_WAIT_RESPONSE_TIME);
    const remoteResp = await promise;
    clearTimeout(readingPositionTimeout);
    return remoteResp;
  } catch (error) {
    logger.error(`Get error on load remote reading position ${error}`);
    return tryLoadRemoteReadingPosition(publicationId, numberAttempts);
  }
}

function _getRequiredLocator(readingArea, rawAudioLocator) {
  const audioLocator =
    rawAudioLocator && rawAudioLocator.startLocator
      ? rawAudioLocator.startLocator
      : rawAudioLocator;

  const isScrubberInsideReadingArea =
    readingArea &&
    !audioLocator.precedes(readingArea.startLocator) &&
    audioLocator.precedes(readingArea.endLocator);
  if (isScrubberInsideReadingArea) {
    const endLocator = new Locator.InTextLocator(
      audioLocator.prefixedParagraphId,
      audioLocator.logicalCharOffset + 1
    );
    return new Locator.InTextRangeLocator(audioLocator, endLocator).toJSON();
  }
  return '';
}
function _getOpenLocator(readingArea, audioLocator, readingLocator) {
  let openLocator = '';
  if (readingArea) {
    openLocator = readingArea.startLocator;
  } else {
    openLocator =
      audioLocator && audioLocator.compareTo(readingLocator) > 0
        ? audioLocator
        : readingLocator;
  }

  if (openLocator instanceof Locator.InTextLocator) {
    const endLocator = new Locator.InTextLocator(
      openLocator.prefixedParagraphId,
      openLocator.logicalCharOffset + 1
    );
    return new Locator.InTextRangeLocator(openLocator, endLocator).toJSON();
  }
  return get(openLocator, 'prefixedParagraphId', null);
}

const actions = {
  applyParaHighlightIfNeed({ getters, rootGetters }, { paraId, isRead }) {
    if (!paraId) {
      return;
    }
    const para = document.querySelector(`#${paraId}`);
    const needHighlight =
      rootGetters['ReadingSettingsStore/getReadingPositionTracking'];
    if (needHighlight && isRead && para) {
      const itemWrap = para.closest(
        `.${SelectionConstantUtils.SCROLL_ITEM_WRAPPER_CLASS}`
      );
      if (itemWrap) {
        const highlightClass = getters.getDefaultHighLightClass;
        itemWrap.classList.add(highlightClass);
      }
    }
  },
  highlightColorWordsIfNeed({ getters, rootGetters }, { paraId, wordOffset }) {
    try {
      const needHighLightPosition =
        rootGetters['ReadingSettingsStore/getReadingPositionTracking'];
      const insideReadRanges = getters.isInsideReadRanges(paraId, wordOffset);
      if (!needHighLightPosition || insideReadRanges) {
        return;
      }
      const element = document.querySelector(`#${paraId}`);
      const stableOffsets = [wordOffset];
      const highlightClass = getters.getDefaultHighLightClass;
      searchHighlighter.decorateSearchQuotesByOffsets(
        stableOffsets,
        element,
        highlightClass
      );
    } catch (error) {
      logger.warn(`Get error on highLight word:${error}`);
    }
  },
  async loadPreviousReadingPosition(
    { rootGetters, dispatch },
    { publicationId, publicationType }
  ) {
    const isCompilation = rootGetters['PublicationStore/isCompilationOpen'];
    const appMode = rootGetters['ContextStore/appModeGetter'];
    if (isCompilation || appMode === AppModeEnum.EDITOR) {
      return;
    }

    await dispatch('fillPublicationProgress', {
      publicationId,
      publicationType
    });
    return dispatch('resolveOpenParaId', {
      publicationId,
      type: publicationType
    });
  },
  saveGuestReadingDataIfNeeded({ rootGetters }, summary) {
    const isGuest = rootGetters['UserStore/isGuestUser'];
    if (!isGuest) {
      return;
    }

    const userId = rootGetters['UserStore/getUserId'];
    const guestReadingData = new GuestReadingData(summary, userId);
    guestReadingData.save();
  },
  fillPublicationProgressIfNeeded(
    { getters, dispatch },
    { publicationId, publicationType }
  ) {
    const progressParams = {
      publicationId: publicationId,
      type: publicationType
    };
    const currentProgress = getters.getFilteredProgressById(progressParams);
    if (!isEmpty(currentProgress)) {
      return;
    }
    return dispatch('fillPublicationProgressById', progressParams);
  },
  fillPublicationProgress({ dispatch }, { publicationId, publicationType }) {
    const progressParams = {
      publicationId: publicationId,
      type: publicationType
    };

    return dispatch('fillPublicationProgressById', progressParams);
  },
  resolveOpenParaId({ getters }, { type, publicationId }) {
    const {
      audioLocator,
      readingLocator,
      readingArea
    } = getters.getCurrentReadingPosition({
      type,
      publicationId
    });

    const requiredLocator = _getRequiredLocator(readingArea, audioLocator);
    const openLocator = _getOpenLocator(
      readingArea,
      audioLocator,
      readingLocator
    );

    return { openLocator, requiredLocator, readingArea };
  },
  stopLoadReadingPosition() {
    isActualLoadReadingPosition = false;
    clearTimeout(readingPositionTimeout);
  },
  async loadReadingPosition({ rootGetters, commit, dispatch }) {
    const publicationId = rootGetters['OpenParameterStore/getPublicationId'];
    const type = rootGetters['OpenParameterStore/getPublicationType'];
    const defaultResp = {
      hasRemoteUpdate: false,
      paraId: ''
    };
    if (!publicationId) {
      logger.error(
        `Try to load user reading position without publicationId return default response ${defaultResp}`
      );
      return defaultResp;
    }
    if (!type) {
      logger.error(
        `Try to load user reading position without type return default response ${defaultResp}`
      );
      return defaultResp;
    }
    isActualLoadReadingPosition = true;
    const remoteResp = await tryLoadRemoteReadingPosition(publicationId);
    const localResp = await retrievePublicationProgress(publicationId, type);
    const remoteData = remoteResp.data;
    const localData = localResp.data;
    if (!isActualLoadReadingPosition) {
      return defaultResp;
    }

    if (!remoteData.readingArea) {
      logger.warn(
        `Does not get reading area from remote return default response ${defaultResp}`
      );
      return defaultResp;
    }

    const remoteRev = remoteData?._rev || '';
    const localRev = localData?._rev || '';
    const isSameRevs = localRev === remoteRev;

    const remoteLastModified = remoteData?.modifiedAt;
    const localLastModified = localData?.modifiedAt;
    const isRemoteNewest =
      remoteLastModified &&
      localLastModified &&
      remoteLastModified > localLastModified;
    const isEqualReadingArea = _isEqualStartLocators(
      remoteData.readingArea,
      localData?.readingArea
    );

    const hasRemoteUpdate =
      !isEqualReadingArea && !isSameRevs && isRemoteNewest;
    if (hasRemoteUpdate) {
      commit('setReadingCurrentLocator', {
        bookId: publicationId,
        type,
        currentLocator: remoteData.readingCurrentLocator
      });
      commit('setAudioCurrentLocator', {
        bookId: publicationId,
        type,
        currentLocator: remoteData.audioCurrentLocator
      });
      commit('setReadingAreaLocator', {
        bookId: publicationId,
        type,
        readingArea: remoteData.readingArea
      });
      await dispatch('saveUserActivity', { publicationId, type });
    }

    let requiredLocator = '';
    if (remoteData.readingArea && remoteData.audioCurrentLocator) {
      const parseReadingArea = Locator.deserialize(remoteData.readingArea);
      const parsedAudioLocator = Locator.deserialize(
        remoteData.audioCurrentLocator
      );
      requiredLocator = _getRequiredLocator(
        parseReadingArea,
        parsedAudioLocator
      );
    }
    return {
      hasRemoteUpdate,
      paraId: remoteData.readingArea,
      requiredLocator
    };
  },
  async fillLibraryProgress({ rootGetters, commit }, libraryProgress) {
    const userId = rootGetters['UserStore/getUserId'];
    if (libraryProgress?.progressSummary) {
      commit('setLibraryProgress', libraryProgress.progressSummary);
      return;
    }
    const response = await loadLibraryProgress(userId);
    const progressSummary = get(response, 'data.progressSummary', {});

    const earliestDate = dayJS
      .get(0)
      .format(AppConstantsUtil.USER_ACTIVITY_DATE_FORMAT);
    const userActivity = rootGetters['ActivityStore/getUserActivity'](
      earliestDate
    );
    const updatedProgressSummary = await updateReadingDaysAmount(
      progressSummary,
      userActivity
    );
    commit('setLibraryProgress', updatedProgressSummary);
  },
  fillAllBooksProgress: async function({ commit }) {
    const progressData = (await retrieveAllBooksProgress()) || {};
    commit('setAllBooksProgress', progressData.data);
  },
  fillPublicationProgressById: async function(
    { commit },
    { publicationId, type }
  ) {
    if (type === PublicationsTypesEnum.COMPILATION) {
      commit('setPublicationProgress', {
        type,
        publicationId,
        publicationProgress: {}
      });
      return;
    }
    let publicationProgress = {};
    try {
      publicationProgress = await retrievePublicationProgress(
        publicationId,
        type
      );
      const localActivityTrack = getLocalActivityTrackById(publicationId);
      publicationProgress =
        publicationProgress.data || localActivityTrack || {};
    } catch (e) {
      logger.error(e);
    }
    commit('setPublicationProgress', {
      type,
      publicationId,
      publicationProgress
    });
  },
  initExtraContentFields(
    { commit, getters, rootGetters },
    { publicationId, type }
  ) {
    const content = rootGetters['PublicationStore/getParagraphsSummary'](
      publicationId
    );
    const meta = rootGetters['PublicationStore/getMeta'](publicationId);
    let previousChapterName = meta.toc.length === 1 ? meta.toc[0].text : '';
    let wordsBefore = 0;
    const paragraphIds = Object.keys(content);
    let extraContentFields = {};
    paragraphIds.forEach(paraId => {
      const para = Object.assign({}, content[paraId]);
      let chapterName, chapter, isChapter, position;
      chapter = meta.toc.find(tocItem => {
        return tocItem.id === paraId;
      });
      isChapter = meta.toc.length === 1 ? false : Boolean(chapter);
      chapterName = isChapter ? chapter.text : previousChapterName;
      const isParagraphLast = wordsBefore + para.words === meta.wordsCount;
      position = isParagraphLast
        ? 100
        : Math.round(((wordsBefore * 100) / meta.wordsCount) * 100) / 100;
      Object.assign(para, {
        chapterName,
        isChapter,
        position
      });
      previousChapterName = chapterName;
      wordsBefore += para.words;
      extraContentFields[paraId] = para;
    });
    commit('setExtraContentFields', { publicationId, extraContentFields });
    const ranges = getters.getCurrentProgressRanges({ publicationId, type });
    commit('initPreviousPositions', {
      publicationId,
      ranges,
      extraContentFields
    });
  },
  callReadingAreaUpdate: function() {
    // used to trigger readingArea update in <ReadingArea> component
  },
  clearAllProgress({ commit }) {
    commit('clearAllProgress');
  },
  clearPublicationProgress: function(
    { commit, rootGetters },
    { bookId, type }
  ) {
    commit('clearPublicationProgress', { bookId, type });
    const userId = rootGetters['UserStore/getUserId'];
    return resetBookProgress(bookId, userId);
  },
  clearReadingArea: function({ commit }) {
    commit('clearReadingArea');
    commit('clearReturnReadingArea');
  },
  updateReadingArea: async function(
    { commit, rootGetters, dispatch },
    { readingArea, publicationId, type }
  ) {
    commit('setReadingArea', readingArea);
    const shouldNotTrackProgress =
      rootGetters['ContextStore/shouldNotTrackProgress'];

    if (type === PublicationsTypesEnum.BOOK && !shouldNotTrackProgress) {
      commit('setReadingAreaLocator', {
        bookId: publicationId,
        type,
        readingArea: serializeReadingArea(readingArea)
      });
      await dispatch('saveUserActivity', { publicationId, type });
    }
  },
  updateUserActivityTracker: async function(
    { commit, dispatch, rootGetters },
    { publicationId, type, tracker, newRange, wordsCount }
  ) {
    const shouldNotTrackProgress =
      rootGetters['ContextStore/shouldNotTrackProgress'];
    if (shouldNotTrackProgress) {
      return;
    }
    commit('updateUserActivityTracker', {
      publicationId,
      type,
      tracker,
      newRange,
      wordsCount
    });
    await dispatch('saveUserActivity', { publicationId, type });
  },
  saveUserActivity: async function(
    { commit, rootGetters, state, dispatch },
    { publicationId, type }
  ) {
    const shouldNotTrackProgress =
      rootGetters['ContextStore/shouldNotTrackProgress'] ||
      state.saveActivityInProgress;

    if (shouldNotTrackProgress) {
      return;
    }

    commit('setSaveActivityInProgress', true);
    try {
      const progressGroup = state.progressData[type];
      const meta = rootGetters['PublicationStore/getMeta'](publicationId);
      const userId = rootGetters['UserStore/getUserId'];
      if (!meta) {
        return;
      }
      const summaryData = {
        userId,
        wordsCount: meta.wordsCount
      };
      const activityTracker = progressGroup[publicationId];
      saveReadingPositionsById(publicationId, activityTracker);
      const response = await savePublicationProgress(
        publicationId,
        type,
        activityTracker,
        summaryData
      );
      const data = get(response, 'data', null);
      if (!data) {
        logger.warn(
          `Get empty response after update user progress userId:${userId}`
        );
        commit('setSaveActivityInProgress', false);
        return;
      }
      const [publicationProgress, libraryProgress, userActivity] = data;
      commit('setPublicationProgress', {
        type,
        publicationId,
        publicationProgress
      });
      const updatedSummary = updateReadingDaysAmount(
        libraryProgress.progressSummary || {},
        userActivity?.activity || []
      );
      commit('setLibraryProgress', updatedSummary);

      dispatch('saveGuestReadingDataIfNeeded', updatedSummary);
      dispatch('ActivityStore/setUserActivity', userActivity || {}, {
        root: true
      });
    } catch (error) {
      logger.error(`Get error on update user activity error:${error}`);
    }
    commit('setSaveActivityInProgress', false);
  },
  retrieveSuggestionsProgress: async ({ commit, rootGetters, dispatch }) => {
    let suggestedBooks = rootGetters['LibraryStore/getSuggestedBooks'];
    const suggestedIds = suggestedBooks.map(book => book.id);
    const userId = rootGetters['UserStore/getUserId'];

    let latestCompletedResult =
      rootGetters['VocabularyAssessmentStore/getLatestCompletedResult'];
    if (!latestCompletedResult) {
      await dispatch('VocabularyAssessmentStore/fetchAllUserResults', null, {
        root: true
      });
      latestCompletedResult =
        rootGetters['VocabularyAssessmentStore/getLatestCompletedResult'];
    }
    const dateFrom = latestCompletedResult
      ? latestCompletedResult.passedAt
      : Date.now();
    const suggestionsProgressData = await retrieveSuggestionsProgress(
      suggestedIds,
      userId,
      dateFrom
    );
    commit('setSuggestionsProgressData', suggestionsProgressData);
  },
  fetchProgressSummaryByPublicationId: async (
    { rootGetters },
    { publicationId }
  ) => {
    try {
      const userId = rootGetters['UserStore/getUserId'];
      const response = await loadLibraryProgress(userId);
      const progressSummary = get(response, 'data.progressSummary', {});
      return progressSummary[publicationId] || new ProgressSummaryItem();
    } catch (error) {
      logger.error(
        `Get error on fetch progress summary by publicationId ${error}`
      );
      return new ProgressSummaryItem();
    }
  }
};

const storeGetters = {
  getCurrentTrackingTask(state) {
    return state.trackingTasks[0];
  },
  isInsideReadRanges: state => (paraId, wordOffset) => {
    const paraReadRanges = state.readParagraphRanges[paraId];
    const startLogicalCharOffset = wordOffset[0];
    const endLogicalCharOffset = wordOffset[1];
    if (!paraReadRanges) {
      return false;
    }
    for (const range of paraReadRanges) {
      const isInsideRanges =
        startLogicalCharOffset >= range.startLocator.logicalCharOffset &&
        endLogicalCharOffset <= range.endLocator.logicalCharOffset;
      if (isInsideRanges) {
        return isInsideRanges;
      }
    }
    return false;
  },
  isLastReadingPositionInsideReadingArea: (state, getters) => (
    publicationId,
    type
  ) => {
    const {
      audioLocator,
      readingLocator,
      readingArea
    } = getters.getCurrentReadingPosition({
      type,
      publicationId
    });

    let openLocator = _getOpenLocator(
      readingArea,
      audioLocator,
      readingLocator
    );
    if (!openLocator) {
      return false;
    }

    openLocator = Locator.deserialize(openLocator);
    return getters.isInsideReadingArea(openLocator);
  },
  getDefaultHighLightClass() {
    return 'position-tracker-fast';
  },
  getReadParagraphRanges(state) {
    return state.readParagraphRanges;
  },
  isReadParagraph: state => (type, bookId, paraId) => {
    const activityTracker = getCurrentActiveTracker(state, type, bookId);
    if (isEmpty(activityTracker)) {
      return false;
    }
    const foundRange = activityTracker.ranges.find(range => {
      const locator = Locator.deserialize(range.range);
      return (
        locator.startLocator.prefixedParagraphId === paraId && !range.ignore
      );
    });
    return Boolean(foundRange);
  },
  isReadChapter: (state, getters, rootState, rootGetters) => payload => {
    const { bookId, paraId } = payload;
    const toc = rootGetters['PublicationStore/getMeta'](bookId)?.toc;
    if (!toc) {
      return false;
    }

    let startChapterIndex = -1;
    let startChapter = toc.find((item, index) => {
      startChapterIndex = index;
      return item.id === paraId;
    });

    let endChapter = toc.find(
      (item, index) =>
        index > startChapterIndex &&
        (startChapter.indentation ?? 0) <= (item.indentation ?? 0)
    );

    let endParaId =
      endChapter?.id || rootGetters['BookStore/getLastContentParagraph']();
    let paragraphs = rootGetters['BookStore/getParagraphsRange'](
      startChapter.id,
      endParaId
    );

    if (endChapter?.id) {
      paragraphs = paragraphs.splice(0, paragraphs.length - 1);
    }
    return !paragraphs.some(
      para =>
        para.words > 0 &&
        !getters.isReadParagraph(PublicationsTypesEnum.BOOK, bookId, para.id)
    );
  },
  getPublicationProgress: state => publicationId => {
    const publicationProgress = get(
      state,
      `progressSummary[${publicationId}]`,
      null
    );
    return publicationProgress || new ProgressSummaryItem();
  },
  getReadingArea: state => state.readingArea,
  getReturnReadingArea: state => state.returnReadingArea,
  isEmptyReadingPosition: state => ({ type, publicationId }) => {
    const activityTracker = getCurrentActiveTracker(state, type, publicationId);

    return (
      isEmpty(activityTracker) ||
      Boolean(
        activityTracker.ranges.length === 0 &&
          !activityTracker.readingCurrentLocator &&
          !activityTracker.audioCurrentLocator &&
          !activityTracker.readingArea
      )
    );
  },
  getFilteredProgressById: state => ({ type, publicationId }) => {
    const typeGroup = state.progressData[type];
    const clearedPublicationProgress = {
      ...get(typeGroup, `[${publicationId}]`, {})
    };

    if (clearedPublicationProgress.ranges) {
      clearedPublicationProgress.ranges = clearedPublicationProgress.ranges.filter(
        range => !range.ignore
      );
    }
    return clearedPublicationProgress;
  },
  getAllProgressByType: state => ({ type }) => {
    return state.allProgressData[type];
  },
  getCurrentProgressRanges: state => ({ publicationId, type }) => {
    const progressData = getProgressDataFromGroup(state, publicationId, type);
    return progressData.ranges || [];
  },
  getExtraContentFields: state => ({ publicationId }) => {
    return state.extraContentFields[publicationId];
  },
  getMergedCurrentRanges: (state, getters) => ({ publicationId, type }) => {
    const progressData = getProgressDataFromGroup(state, publicationId, type);
    let currentRanges = cloneDeep(progressData.ranges) || [];
    currentRanges = currentRanges.map(rangeObj => {
      rangeObj.range = Locator.deserialize(rangeObj.range);
      return rangeObj;
    });
    let sortedRanges = _sortByParagraphNumber(currentRanges);
    const extraContentFields = getters.getExtraContentFields({ publicationId });
    sortedRanges = _fillNonTextRanges(sortedRanges, extraContentFields);
    const mergedRanges = _mergeAllRanges(sortedRanges);

    return mergedRanges;
  },
  getPreviousPositions: state => ({ publicationId }) => {
    return state.previousPositions[publicationId].map((position, index) => {
      const paraId = position.range.endLocator.prefixedParagraphId;
      const leftOffset =
        state.extraContentFields[publicationId][paraId].position;
      return {
        key: position.date + '_' + index,
        range: position.range,
        left: `${leftOffset}%`
      };
    });
  },
  // getCurrentLocator: state => ({ publicationId, type }) => {
  //   const progressData = getProgressDataFromGroup(state, publicationId, type);
  //   return progressData.currentLocator;
  // },
  getCurrentReadingPosition: state => ({ publicationId, type }) => {
    const progressData = getProgressDataFromGroup(state, publicationId, type);
    const startLocator = Locator.deserialize('');

    const audioLocator = serializeLocatorWithFallback(
      progressData.audioCurrentLocator,
      startLocator
    );
    const readingLocator = serializeLocatorWithFallback(
      progressData.readingCurrentLocator,
      startLocator
    );
    const readingArea = serializeLocatorWithFallback(
      progressData.readingArea,
      null
    );

    return {
      audioLocator,
      readingLocator,
      readingArea
    };
  },
  getCurrentAudioPosition: state => ({ publicationId, type }) => {
    const progressData = getProgressDataFromGroup(state, publicationId, type);
    const startLocator = Locator.deserialize('');
    return serializeLocatorWithFallback(
      progressData.audioCurrentLocator,
      startLocator
    );
  },
  getTodayReadWords: state => ({ type, publicationId }) => {
    const dateKey = dayJS
      .get()
      // Reading per day period - from 4 a.m. till  4 a.m. of next day
      .subtract(4, 'h')
      .format(DATE_KEY_FORMAT);
    const wordsCount = get(
      state,
      `progressData['${type}']['${publicationId}'].studyItemWordsCountByDates['${dateKey}'].wordsCount`,
      0
    );
    return wordsCount;
  },
  isParaInReadingArea: state => paraId => {
    const readingArea = state.readingArea;
    if (!readingArea) {
      return false;
    }
    const startParaId = get(
      readingArea,
      'startLocator.prefixedParagraphId',
      ''
    );
    const endParaId = get(readingArea, 'endLocator.prefixedParagraphId', '');

    const paraNum = MarkerUtils.getParaNum(paraId);
    const startParaNum = MarkerUtils.getParaNum(startParaId);
    const endParaNum = MarkerUtils.getParaNum(endParaId);
    if (isNaN(startParaNum) || isNaN(endParaNum) || isNaN(paraNum)) {
      return false;
    }
    return paraNum >= startParaNum && paraNum <= endParaNum;
  },
  isInsideReadingArea: state => locator => {
    //TODO: Remove this part when we get ability to read paraID by blockID
    //from Book store and apply this ability mGetLocatorFromHighlightSelection,
    //and replace this function to PresentPublicationPage
    const isLocator =
      !locator.hasOwnProperty('prefixedParagraphId') &&
      !locator.hasOwnProperty('startLocator');
    if (isLocator) {
      logger.warn(`Wrong locator format: ${JSON.stringify(locator)}`);
      return false;
    }
    const readingArea = state.readingArea;
    if (!readingArea) {
      return false;
    }
    return (
      !locator.precedes(readingArea.startLocator) &&
      locator.precedes(readingArea.endLocator)
    );
  },
  getIsAssessmentAvailable: (state, getters, rootState, rootGetters) => {
    let isAssessmentAvailable = false;
    let suggestedBooks = rootGetters['LibraryStore/getSuggestedBooks'];

    if (!suggestedBooks || suggestedBooks.length === 0) {
      return true;
    }
    const MIN_HOURS_PASSED = 5;
    const hoursPassed = getters.getHoursPassedInSuggestionSet;
    const hoursRemaining = getters.getHoursRemainingInSuggestionsSet;

    if (hoursRemaining === 0 || hoursPassed >= MIN_HOURS_PASSED) {
      isAssessmentAvailable = true;
    }
    return isAssessmentAvailable;
  },
  // TODO optimize
  getMinutesPassedInSuggestionSet: (state, getters, rootState, rootGetters) => {
    let hoursPassed = null;
    let wordsPassed = 0;
    const wordInSet = _calculateWordsInSuggestionSet(
      state,
      getters,
      rootState,
      rootGetters
    );

    if (wordInSet !== null) {
      wordsPassed = wordInSet.readWordsInSuggestionSet;
      hoursPassed = Utils.estimateMinutesReadingTime(wordsPassed);
    }
    return hoursPassed;
  },
  getHoursPassedInSuggestionSet: (state, getters, rootState, rootGetters) => {
    let hoursPassed = null;
    let wordsPassed = 0;
    const wordInSet = _calculateWordsInSuggestionSet(
      state,
      getters,
      rootState,
      rootGetters
    );

    if (wordInSet !== null) {
      wordsPassed = wordInSet.readWordsInSuggestionSet;
      hoursPassed = Utils.estimateHoursReadingTime(wordsPassed);
    }
    return hoursPassed;
  },
  getHoursRemainingBeforeAssessment: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const MIN_HOURS_REMAINING = 5;
    let hoursPassed = 0;

    const wordInSet = _calculateWordsInSuggestionSet(
      state,
      getters,
      rootState,
      rootGetters
    );

    if (wordInSet !== null) {
      let wordsPassed = wordInSet.readWordsInSuggestionSet;
      hoursPassed = Utils.estimateNotRoundedHoursReadingTime(wordsPassed);
    }
    return Math.max(0, MIN_HOURS_REMAINING - hoursPassed);
  },
  getHoursRemainingInSuggestionsSet: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const wordInSet = _calculateWordsInSuggestionSet(
      state,
      getters,
      rootState,
      rootGetters
    );
    let hoursRemaining = null;

    if (wordInSet !== null && wordInSet.readWordsInSuggestionSet > 0) {
      const wordsInSetRemaining = Math.max(
        0,
        wordInSet.totalWordInSuggestionSet - wordInSet.readWordsInSuggestionSet
      );
      hoursRemaining = Utils.estimateHoursReadingTime(wordsInSetRemaining);
    }
    return hoursRemaining;
  },
  isMetaBlockInViewPort: state => state.metaBlockInViewPort
};

function updateReadingDaysAmount(summary, userActivity) {
  const completedBooksIds = Object.keys(summary).filter(
    bookId => summary[bookId].completed
  );

  if (!completedBooksIds.length) {
    return summary;
  }

  for (const activity of userActivity) {
    const activityBooksIds = Object.keys(activity.publications);

    for (const bookId of completedBooksIds) {
      if (!activityBooksIds.includes(bookId)) {
        continue;
      }
      const currentDaysAmount = summary[bookId].readingDaysAmount || 0;
      summary[bookId].readingDaysAmount = currentDaysAmount + 1;
    }
  }

  return summary;
}

function _mergeRanges(readParagraphRanges) {
  readParagraphRanges.sort((locatorA, locatorB) => {
    return (
      locatorA.startLocator.logicalCharOffset -
      locatorB.startLocator.logicalCharOffset
    );
  });
  const mergedParagraphRanges = readParagraphRanges.reduce(
    (mergedRanges, nextRange) => {
      const range = mergedRanges[mergedRanges.length - 1];
      if (!range) {
        mergedRanges.push(nextRange);
        return mergedRanges;
      }

      const isIndependentRanges =
        nextRange.startLocator.logicalCharOffset >
        range.endLocator.logicalCharOffset;

      if (isIndependentRanges) {
        mergedRanges.push(nextRange);
        return mergedRanges;
      }
      const endLocator =
        range.endLocator.logicalCharOffset >
        nextRange.endLocator.logicalCharOffset
          ? range.endLocator
          : nextRange.endLocator;

      mergedRanges.pop();
      mergedRanges.push(
        new Locator.InTextRangeLocator(range.startLocator, endLocator)
      );
      return mergedRanges;
    },
    []
  );
  return mergedParagraphRanges;
}

function _validateProgress(publicationProgress) {
  let validProgress = publicationProgress;
  if (process.server || isEmpty(publicationProgress)) {
    return validProgress;
  }
  try {
    validProgress = _validateRanges(validProgress);
  } catch (error) {
    logger.error(`Get error on validate progress error: ${error}`);
    validProgress = new UserActivityTrack();
  }
  return validProgress;
}

function _validateRanges(publicationProgress) {
  publicationProgress.ranges = publicationProgress.ranges.filter(rangeItem => {
    let valid = true;
    try {
      Locator.deserialize(rangeItem.range);
    } catch {
      valid = false;
    }
    return valid;
  });
  return publicationProgress;
}

const mutations = {
  setTrackingTask(state, { trackingTasks }) {
    state.trackingTasks = trackingTasks || [];
  },
  removeTrackingTask(state, taskForRemove) {
    const index = state.trackingTasks.findIndex(
      task => task.type === taskForRemove.type
    );
    if (index !== -1) {
      state.trackingTasks.splice(index, 1);
    }
  },
  saveReadParaRange(state, { startRangeLocator, endRangeLocator }) {
    const paraId = startRangeLocator.prefixedParagraphId;
    const readRange = new Locator.InTextRangeLocator(
      startRangeLocator,
      endRangeLocator
    );
    const readParagraphRanges = state.readParagraphRanges;
    if (!readParagraphRanges.hasOwnProperty(paraId)) {
      readParagraphRanges[paraId] = [];
    }
    readParagraphRanges[paraId].push(readRange);

    readParagraphRanges[paraId] = _mergeRanges(readParagraphRanges[paraId]);
    state.readParagraphRanges = { ...readParagraphRanges };
  },
  setSaveActivityInProgress(state, val) {
    state.saveActivityInProgress = val;
  },
  clearAllProgress(state) {
    state.progressData = {};
  },
  clearPublicationProgress(state, { bookId, type }) {
    if (!state.progressData.hasOwnProperty(type)) {
      state.progressData[type] = {};
    }
    const progressGroup = state.progressData[type];
    progressGroup[bookId] = new UserActivityTrack();
    state.previousPositions[bookId] = [];
  },
  setReadingArea: function(state, readingArea) {
    state.readingArea = readingArea;
  },
  setReturnReadingArea: function(state, { readingArea }) {
    state.returnReadingArea = readingArea;
  },
  clearReadingArea: function(state) {
    state.readingArea = null;
  },
  clearReturnReadingArea: function(state) {
    state.returnReadingArea = null;
  },
  setLibraryProgress(state, progressSummary) {
    state.progressSummary = Object.keys(progressSummary).reduce(
      (summary, bookId) => {
        const {
          readPercent,
          readingDaysAmount,
          completed,
          totalWordsPerBook,
          readWords,
          startAt,
          endAt
        } = progressSummary[bookId];
        summary[bookId] = new ProgressSummaryItem(
          readPercent,
          completed,
          totalWordsPerBook,
          readWords,
          startAt,
          endAt,
          readingDaysAmount
        );
        return summary;
      },
      {}
    );
  },
  setAllBooksProgress: function(state, data) {
    state.allProgressData[PublicationsTypesEnum.BOOK] = data;
  },
  setExtraContentFields(state, { publicationId, extraContentFields }) {
    state.extraContentFields[publicationId] = extraContentFields;
  },
  setReadingCurrentLocator: function(state, { bookId, type, currentLocator }) {
    const activityTracker = getActivityTracker(state, type, bookId);
    activityTracker.readingCurrentLocator = currentLocator;
  },
  setAudioCurrentLocator: function(state, { bookId, type, currentLocator }) {
    const activityTracker = getActivityTracker(state, type, bookId);
    activityTracker.audioCurrentLocator = currentLocator;
  },
  setReadingAreaLocator: function(state, { bookId, type, readingArea }) {
    const activityTracker = getActivityTracker(state, type, bookId);
    activityTracker.readingArea = readingArea;
  },
  initPreviousPositions(state, { publicationId, ranges, extraContentFields }) {
    const lastPositions = getLastReadingPositions(extraContentFields, ranges);
    state.previousPositions[publicationId] = lastPositions;
  },
  setPublicationProgress(state, { type, publicationId, publicationProgress }) {
    if (!state.progressData.hasOwnProperty(type)) {
      state.progressData[type] = {};
    }
    const validProgress = _validateProgress(publicationProgress);
    state.progressData[type][publicationId] = validProgress;
  },
  resetStore(state) {
    const newSate = initState();
    Object.keys(state).forEach(key => {
      state[key] = newSate[key];
    });
  },
  updateUserActivityTracker: function(
    state,
    { publicationId, type, tracker, newRange, wordsCount }
  ) {
    if (!state.readingArea) {
      return;
    }

    newRange.ignore = false;
    const activityTracker = getActivityTracker(state, type, publicationId);
    const deserializeActivityTracker = _deserializeLocatorsFromServerFormat(
      activityTracker
    );
    const existingIndex = _getLocatorIndexInExistingRange(
      deserializeActivityTracker.ranges,
      tracker.position,
      'reading'
    );

    newRange.wordsCount = wordsCount;
    let activityClone = cloneDeep(newRange);
    if (existingIndex === -1) {
      deserializeActivityTracker.ranges.push(activityClone);
    } else {
      deserializeActivityTracker.ranges.splice(existingIndex, 1, activityClone);
    }
    deserializeActivityTracker.studyItemWordsCountByDates = _groupReadWordsByDate(
      deserializeActivityTracker.studyItemWordsCountByDates,
      wordsCount
    );

    const extraContentFields = state.extraContentFields[publicationId];
    const sortedRanges = _sortRangesByDate(deserializeActivityTracker.ranges);
    const previousPosition = sortedRanges[0];
    if (
      _isFarFromLatestPositions(
        extraContentFields,
        newRange.range,
        previousPosition
      )
    ) {
      state.previousPositions[publicationId] = _getLastReadingPositions(
        extraContentFields,
        sortedRanges
      );
    }
    const progressGroup = state.progressData[type];
    progressGroup[publicationId] = _serializeProgressToServerFormat(
      deserializeActivityTracker,
      state.readingArea
    );
  },
  setSuggestionsProgressData(state, suggestionsProgressData) {
    state.suggestionsProgressData = suggestionsProgressData;
  },
  setMetaBlockInViewPort(state, value) {
    state.metaBlockInViewPort = value;
  }
};

function _serializeProgressToServerFormat(activityTrack, readingArea) {
  const sortedRanges = (activityTrack.ranges = _sortByParagraphNumber(
    activityTrack.ranges
  ));
  activityTrack.ranges = sortedRanges;
  const serializedReadingLocator = Locator.serialize(
    activityTrack.readingCurrentLocator
  );
  const serializedAudioLocator = Locator.serialize(
    activityTrack.audioCurrentLocator
  );
  const serializedReadingAreaLocator = serializeReadingArea(readingArea);
  const serializedRanges = map(activityTrack.ranges, function(rangeObj) {
    return assign({}, rangeObj, {
      range: Locator.serialize(rangeObj.range)
    });
  }).filter(Boolean);
  const serializedLocators = map(activityTrack.userMovingHistory, function(
    location
  ) {
    return Locator.serialize(location);
  });
  return assign({}, activityTrack, {
    userMovingHistory: serializedLocators,
    ranges: serializedRanges,
    readingCurrentLocator: serializedReadingLocator,
    audioCurrentLocator: serializedAudioLocator,
    readingArea: serializedReadingAreaLocator
  });
}

function getCurrentActiveTracker(state, type, bookId) {
  const progressGroup = state.progressData[type] || {};
  const activityTracker = progressGroup[bookId] || null;
  return activityTracker;
}

function getActivityTracker(state, type, bookId) {
  const currentActivityTracker = getCurrentActiveTracker(state, type, bookId);

  const progressGroup = state.progressData[type];
  if (isEmpty(currentActivityTracker)) {
    progressGroup[bookId] = new UserActivityTrack();
  }
  const activityTracker = progressGroup[bookId];
  return activityTracker;
}

function serializeLocatorWithFallback(locator, defaultVal) {
  const serializedLocator = isValidSerializedLocator(locator)
    ? Locator.deserialize(locator)
    : defaultVal;
  return serializedLocator;
}

function serializeReadingArea(readingArea) {
  if (!readingArea) {
    return '';
  }
  return Locator.serialize(readingArea);
}

function _sortByParagraphNumber(ranges) {
  return sortBy(ranges, range => {
    return (
      range.range.startLocator._paragraphNumber ||
      range.range.endLocator._paragraphNumber
    );
  });
}

function _groupReadWordsByDate(studyItemWordsCountByDates, wordsCount) {
  const wordsCountRange = { ...studyItemWordsCountByDates };
  const dateKey = dayJS
    .get()
    .subtract(4, 'h')
    .format(DATE_KEY_FORMAT);
  wordsCountRange[dateKey] = wordsCountRange[dateKey] || {};
  wordsCountRange[dateKey].wordsCount =
    (wordsCountRange[dateKey].wordsCount || 0) + wordsCount;
  return wordsCountRange;
}

function _getLocatorIndexInExistingRange(ranges, locator, type) {
  return findIndex(ranges, function(locatorRange) {
    return (
      !locator.precedes(locatorRange.range.startLocator) &&
      locator.compareBasisTo(locatorRange.range.endLocator) === 0 &&
      locatorRange.type === type
    );
  });
}

function getLastReadingPositions(extraContentFields, ranges) {
  ranges = _fillNonTextRanges(
    ranges.map(rangeObj => ({
      ...rangeObj,
      range: Locator.deserialize(rangeObj.range)
    })),
    extraContentFields
  );
  ranges = _sortRangesByDate(ranges);
  return _getLastReadingPositions(extraContentFields, ranges);
}

function _sortRangesByDate(ranges) {
  const rangesCopy = cloneDeep(ranges);
  return rangesCopy.sort((a, b) => new Date(a.date) - new Date(b.date));
}

function _fillNonTextRanges(ranges, extraContentFields) {
  const filledRanges = [];
  const rangesAmount = ranges.length;
  if (rangesAmount <= 1) {
    return ranges;
  }
  let currentRange, currentParaNum, followingRange, followingParaNum;
  for (let i = 0; i < rangesAmount; i++) {
    currentRange = ranges[i];
    currentParaNum = currentRange.range.endLocator._paragraphNumber;
    followingRange = ranges[i + 1];
    followingParaNum = followingRange?.range?.startLocator?._paragraphNumber;
    filledRanges.push(currentRange);
    const shouldAddRangesBetween =
      followingParaNum && currentParaNum + 1 !== followingParaNum;
    if (!shouldAddRangesBetween) {
      continue;
    }
    const rangeBetween = _range(currentParaNum + 1, followingParaNum);
    let paraId, wordsCount, startLocator, endLocator;
    rangeBetween.forEach(paraNum => {
      paraId = `para_${paraNum}`;
      wordsCount = get(extraContentFields, `[${paraId}].words`, null);
      if (wordsCount === null) {
        logger.warn(`words count empty for para ${paraId}`);
        return;
      }
      const isImageOrDecorateElement = wordsCount < 2;
      if (isImageOrDecorateElement) {
        startLocator = endLocator = new Locator.InTextLocator(paraId, 0);
        filledRanges.push(
          UserProgressFactory.createProgressRange(
            startLocator,
            endLocator,
            currentRange.type
          )
        );
      }
    });
  }
  return filledRanges;
}

function _getLastReadingPositions(extraContentFields, rangesSortedByDate) {
  const lastReadingPositions = [];
  const mergedPositions = _mergeAllRanges(rangesSortedByDate).reverse();
  const lastRange = rangesSortedByDate[rangesSortedByDate.length - 1];
  for (let rangeObj of mergedPositions) {
    if (lastReadingPositions.length === LAST_POSITIONS_AMOUNT) {
      break;
    }
    const latestRange = lastReadingPositions.length
      ? lastReadingPositions[0]
      : lastRange;
    if (
      _isFarFromLatestPositions(extraContentFields, rangeObj.range, latestRange)
    ) {
      lastReadingPositions.push(rangeObj);
    }
  }
  return lastReadingPositions;
}

function _mergeAllRanges(ranges) {
  const result = [];
  ranges.forEach(position => {
    const preceedingRangeIndex = result.findIndex(
      rangeObj =>
        position.range.startLocator._paragraphNumber - 1 ===
        rangeObj.range.endLocator._paragraphNumber
    );
    const followingRangeIndex = result.findIndex(
      rangeObj =>
        position.range.endLocator._paragraphNumber + 1 ===
        rangeObj.range.startLocator._paragraphNumber
    );
    if (preceedingRangeIndex !== -1 && followingRangeIndex !== -1) {
      const followingRange = result[followingRangeIndex];
      const preceedingRange = result[preceedingRangeIndex];
      let newRange = _mergeWithPreceedingRange(position, preceedingRange);
      newRange = _mergeWithFollowingRange(newRange, followingRange);
      newRange.range.startLocator = preceedingRange.range.startLocator;
      newRange.range.endLocator = followingRange.range.endLocator;
      result.splice(Math.max(preceedingRangeIndex, followingRangeIndex), 1);
      result.splice(Math.min(preceedingRangeIndex, followingRangeIndex), 1);
      result.push(newRange);
      return;
    }
    if (preceedingRangeIndex !== -1) {
      result[preceedingRangeIndex] = _mergeWithPreceedingRange(
        position,
        result[preceedingRangeIndex]
      );
      return;
    }
    if (followingRangeIndex !== -1) {
      result[followingRangeIndex] = _mergeWithFollowingRange(
        position,
        result[followingRangeIndex]
      );
      return;
    }
    result.push(position);
  });
  return result;
}

function _mergeWithPreceedingRange(curRange, preceedingRange) {
  const result = {
    type: curRange.type,
    date: curRange.date,
    range: preceedingRange.range,
    wordsCount: curRange.wordsCount + preceedingRange.wordsCount
  };
  result.range.endLocator = curRange.range.endLocator;
  return result;
}

function _mergeWithFollowingRange(curRange, followingRange) {
  const result = {
    type: curRange.type,
    date: curRange.date,
    range: followingRange.range,
    wordsCount: followingRange.wordsCount + curRange.wordsCount
  };
  result.range.startLocator = curRange.range.startLocator;
  return result;
}

function _isFarFromLatestPositions(extraContentFields, range, latestPosition) {
  if (!latestPosition) {
    return false;
  }
  const isNearIndicatorNum = 5;
  const rangeParaId = get(range, 'endLocator.prefixedParagraphId', '');
  const lastParaId = get(
    latestPosition,
    'range.endLocator.prefixedParagraphId',
    ''
  );

  const rangeOffset = get(
    extraContentFields,
    `[${rangeParaId}].position`,
    null
  );
  const latestPositionOffset = get(
    extraContentFields,
    `[${lastParaId}].position`,
    null
  );
  if (rangeOffset === null || latestPositionOffset === null) {
    return false;
  }

  return Math.abs(latestPositionOffset - rangeOffset) > isNearIndicatorNum;
}

function _deserializeLocatorsFromServerFormat(trackingItem) {
  if (!trackingItem) {
    return null;
  }
  const deserializedRanges = [];
  trackingItem.ranges.forEach(function(rangeObj) {
    try {
      const copyRangeObj = JSON.parse(JSON.stringify(rangeObj));
      copyRangeObj.range = Locator.deserialize(copyRangeObj.range);
      deserializedRanges.push(copyRangeObj);
    } catch (error) {
      logger.error(
        `Find invalid progress range ${rangeObj.range} error: ${error}`
      );
    }
  });
  const deserializedLocators = map(trackingItem.userMovingHistory, function(
    location
  ) {
    return Locator.deserialize(location);
  });
  const readingCurrentLocator = Locator.deserialize(
    trackingItem.readingCurrentLocator || ''
  );
  const audioCurrentLocator = Locator.deserialize(
    trackingItem.audioCurrentLocator || ''
  );
  const readingAreaLocator = Locator.deserialize(
    trackingItem.readingArea || ''
  );
  return assign({}, trackingItem, {
    userMovingHistory: deserializedLocators,
    ranges: deserializedRanges,
    readingCurrentLocator: readingCurrentLocator,
    audioCurrentLocator: audioCurrentLocator,
    readingArea: readingAreaLocator
  });
}

function _calculateWordsInSuggestionSet(
  state,
  getters,
  rootState,
  rootGetters
) {
  let words = {
    totalWordInSuggestionSet: 0,
    readWordsInSuggestionSet: 0
  };
  let totalWordInSuggestionSetFromProgress = 0;
  let totalWordInSuggestionSet = 0;
  let suggestedBooks = rootGetters['LibraryStore/getSuggestedBooks'];
  const suggestionsProgress = state.suggestionsProgressData;
  let bookProgress = state.progressSummary;

  const isInitedState = suggestedBooks && bookProgress !== null;
  if (!isInitedState) {
    return null;
  }
  if (!bookProgress || Object.keys(bookProgress).length === 0) {
    return words;
  }
  suggestedBooks.forEach(book => {
    totalWordInSuggestionSet += +book.wordsCount;
    if (bookProgress.hasOwnProperty(book.id)) {
      totalWordInSuggestionSetFromProgress += get(
        bookProgress,
        `[${book.id}].totalWords`,
        0
      );
      words.readWordsInSuggestionSet += get(
        suggestionsProgress,
        `[${book.id}].readWords`,
        0
      );
    }
  });

  if (totalWordInSuggestionSet > totalWordInSuggestionSetFromProgress) {
    words.totalWordInSuggestionSet = totalWordInSuggestionSet;
  } else {
    words.totalWordInSuggestionSet = totalWordInSuggestionSetFromProgress;
  }
  return words;
}

class GuestReadingData {
  constructor(summary, userId) {
    this.userId = userId;
    this.booksCompleted = this._getCompletedBooks(summary);
    this.readWords = this._getReadWords(summary);
  }

  _getCompletedBooks(summary) {
    let completedBooks = 0;
    for (const pubId in summary) {
      if (summary[pubId].completed) {
        completedBooks++;
      }
    }
    return completedBooks;
  }

  _getReadWords(summary) {
    let wordsRead = 0;
    for (const pubId in summary) {
      wordsRead += summary[pubId].readWords;
    }
    return wordsRead;
  }

  async save() {
    try {
      await RestService.restRequest(
        MethodsEnum.POST,
        'GuestReading',
        'saveGuestReadingData',
        this
      );
    } catch (error) {
      logger.error(`Get error on save guest reading data error: ${error}`);
    }
  }
}

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
