import { render, staticRenderFns } from "./ReadingSettingsComponent.vue?vue&type=template&id=43e085c1"
import script from "./ReadingSettingsComponent.vue?vue&type=script&lang=js"
export * from "./ReadingSettingsComponent.vue?vue&type=script&lang=js"
import style0 from "./ReadingSettingsComponent.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VRadio,VRadioGroup,VSlider,VSwitch})
